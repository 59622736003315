import * as Sentry from '@sentry/browser'
import SHA256 from 'crypto-js/sha256'
import _ from 'lodash'
import { call, put } from 'redux-saga/effects'
import api from '../../lib/api/api'
import FusionReactor from '../../lib/FusionReactor'
import * as requestStates from '../../lib/requestStates'


export const REINITIALIZE_STATE = 'screens/login/REINITIALIZE_STATE'

export const SHOW_YES_NO_SCREEN = 'screens/login/SHOW_YES_NO_SCREEN'
export const SHOW_SSN_SCREEN = 'screens/login/SHOW_SSN_SCREEN'
export const SHOW_BANKID_SCREEN = 'screens/login/SHOW_BANKID_SCREEN'
export const CHANGE_SAME_DEVICE_FIELD = 'screens/login/CHANGE_SAME_DEVICE_FIELD'
export const CHANGE_SSN_FIELD = 'screens/login/CHANGE_SSN_FIELD'

export const LOGIN = 'screens/login/LOGIN'
export const LOGIN_REQUEST = 'screens/login/LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'screens/login/LOGIN_SUCCESS'
export const LOGIN_SUCCESS_STUB = 'screens/login/LOGIN_SUCCESS_STUB'
export const LOGIN_FAILURE = 'screens/login/LOGIN_FAILURE'
export const LOGIN_REINITIALIZE = 'screens/login/LOGIN_REINITIALIZE'

export const SET_LOGIN_STATUS_INTERVAL = 'screens/login/SET_LOGIN_STATUS_INTERVAL'
export const CLEAR_LOGIN_STATUS_INTERVAL = 'screens/login/CLEAR_LOGIN_STATUS_INTERVAL'
export const LOGIN_STATUS = 'screens/login/LOGIN_STATUS'
export const LOGIN_STATUS_REQUEST = 'screens/login/LOGIN_STATUS_REQUEST'
export const LOGIN_STATUS_SUCCESS = 'screens/login/LOGIN_STATUS_SUCCESS'
export const LOGIN_STATUS_FAILURE = 'screens/login/LOGIN_STATUS_FAILURE'

export const LOGIN_FINALIZE = 'screens/login/LOGIN_FINALIZE'
export const LOGIN_FINALIZE_REQUEST = 'screens/login/LOGIN_FINALIZE_REQUEST'
export const LOGIN_FINALIZE_SUCCESS = 'screens/login/LOGIN_FINALIZE_SUCCESS'
export const LOGIN_FINALIZE_FAILURE = 'screens/login/LOGIN_FINALIZE_FAILURE'

const initialState = {
  showYesNoScreen: true,
  showSSNScreen: false,
  showBankIDScreen: false,
  sameDeviceField: null,
  ssnField: '',

  loginStatusInterval: null,

  loginRequest: { ...requestStates.initialState },
  loginStatusRequest: { ...requestStates.initialState },
  loginFinalizeRequest: { ...requestStates.initialState },
  e: '',
  c: '',
}

export default FusionReactor.react( {
  initialState,
  reactions: {
    [ REINITIALIZE_STATE ]: {
      reducer: ( state, action ) => {
        if ( state.loginStatusInterval ) {
          clearInterval( state.loginStatusInterval )
        }

        return {
          ...initialState,
        }
      },
    },

    //
    // Screens

    [ SHOW_YES_NO_SCREEN ]: {
      reducer: ( state, action ) => ( {
        ...state,
        showYesNoScreen: true,
        showSSNScreen: false,
        showBankIDScreen: false,
      } ),
    },

    [ SHOW_SSN_SCREEN ]: {
      reducer: ( state, action ) => ( {
        ...state,
        showYesNoScreen: false,
        showSSNScreen: true,
        showBankIDScreen: false,
      } ),
    },

    [ SHOW_BANKID_SCREEN ]: {
      reducer: ( state, action ) => ( {
        ...state,
        showYesNoScreen: false,
        showSSNScreen: false,
        showBankIDScreen: true,
      } ),
    },

    //
    // Controlled fields

    [ CHANGE_SSN_FIELD ]: {
      reducer: ( state, action ) => ( {
        ...state,
        ssnField: action.value.replace( /[^0-9]+/, '' ).substr( 0, 12 ),
      } ),
    },

    [ CHANGE_SAME_DEVICE_FIELD ]: {
      reducer: ( state, action ) => ( { ...state, sameDeviceField: action.value } ),
    },

    //
    // Login

    [ LOGIN ]: {
      saga: function* ( action ) {
        yield put( FusionReactor.reactWith( LOGIN_REQUEST, action ) )
      },
    },

    [ LOGIN_REQUEST ]: {
      saga: function* ( action ) {
        try {
          const result = yield call( api.brandsign.authenticate, action.nationalId, action.sameDevice ),
            status = _.get( result, 'OperationResult.Status', '' )

          if ( 'PENDING' !== status ) {
            throw new Error( _.get( result, 'OperationResult.CustomerMessage', '' ) )
          }

          yield put( FusionReactor.reactWith( LOGIN_SUCCESS, { ...result.OperationResult } ) )
        } catch ( e ) {
          Sentry.captureException( e )
          yield put( FusionReactor.reactWith( LOGIN_FAILURE, {
            e: _.get( e, 'message', '' ),
            c: _.get( e, 'code', '' ),
          } ) )
        }
      },

      reducer: ( state, action ) => ( {
        ...state,
        loginRequest: { ...requestStates.requestState },
      } ),
    },

    [ LOGIN_SUCCESS ]: {
      reducer: ( state, action ) => ( {
        ...state,
        loginRequest: {
          ...requestStates.successState,
          authenticationId: action.AuthenticationId,
          autoStartToken: action.AutoStartToken,
          status: action.Status,
        },
      } ),
    },

    [ LOGIN_SUCCESS_STUB ]: {
      reducer: ( state, action ) => ( {
        ...state,
        loginRequest: {
          ...requestStates.successState,
          authenticationId: action.authenticationId,
          autoStartToken: null,
          status: 'PENDING',
        },
      } ),
    },

    [ LOGIN_FAILURE ]: {
      reducer: ( state, action ) => ( {
        ...state,
        loginRequest: { ...requestStates.failureState },
        e: action.e,
        c: action.c,
      } ),
    },

    [ LOGIN_REINITIALIZE ]: {
      reducer: ( state, action ) => ( {
        ...state,
        loginRequest: { ...requestStates.initialState },
        loginStatusRequest: { ...requestStates.initialState },
        loginFinalizeRequest: { ...requestStates.initialState },
        e: '',
        c: '',
      } ),
    },

    //
    // Login status

    [ SET_LOGIN_STATUS_INTERVAL ]: {
      reducer: ( state, action ) => ( {
        ...state,
        loginStatusInterval: action.interval,
      } ),
    },

    [ CLEAR_LOGIN_STATUS_INTERVAL ]: {
      reducer: ( state, action ) => {
        if ( state.loginStatusInterval ) {
          clearInterval( state.loginStatusInterval )
        }

        return {
          ...state,
          loginStatusInterval: initialState.loginStatusInterval,
        }
      },
    },

    [ LOGIN_STATUS ]: {
      reducer: ( state, action ) => ( {
        ...state,
        loginRequest: {
          ...state.loginRequest,
          autoStartToken: null,
        },
      } ),

      saga: function* ( action ) {
        yield put( FusionReactor.reactWith( LOGIN_STATUS_REQUEST, action ) )
      },
    },

    [ LOGIN_STATUS_REQUEST ]: {
      reducer: ( state, action ) => ( {
        ...state,
        loginStatusRequest: { ...requestStates.requestState },
      } ),

      saga: function* ( action ) {
        try {
          const result = yield call( api.brandsign.authenticationStatus, action.authenticationId ),
            message = _.get( result, 'OperationResult.CustomerMessage', '' ),
            messageCode = _.get( result, 'OperationResult.CustomerMessageCode', '' )

          let status = _.get( result, 'OperationResult.Status', '' )
          if ( 'COMPLETE' === status && 'TIMEOUT' === messageCode ) {
            status = 'TIMEOUT'
          }

          if ( !_.includes( [ 'PENDING', 'COMPLETE' ], status ) ) {
            // noinspection ExceptionCaughtLocallyJS
            throw new Error( message )
          }

          yield put( FusionReactor.reactWith( LOGIN_STATUS_SUCCESS, { ...result.OperationResult } ) )
        } catch ( e ) {
          Sentry.captureException( e )
          yield put( FusionReactor.reactWith( LOGIN_STATUS_FAILURE, {
            e: _.get( e, 'message', '' ),
            c: _.get( e, 'code', '' ),
          } ) )
        }
      },
    },

    [ LOGIN_STATUS_SUCCESS ]: {
      reducer: ( state, action ) => {
        // Sometimes, LOGIN_STATUS responses don't have `nationalId` and `status` values
        // so we need to fall back on previously stored values, if they exist
        const nationalId = _.get( action, 'NationalId', state.loginStatusRequest.nationalId ),
          status = _.get( action, 'Status', state.loginStatusRequest.status )

        return {
          ...state,
          loginStatusRequest: {
            ...requestStates.successState,
            nationalId,
            status,
          },
        }
      },
    },

    [ LOGIN_STATUS_FAILURE ]: {
      reducer: ( state, action ) => ( {
        ...state,
        loginStatusRequest: { ...requestStates.failureState },
        e: action.e,
        c: action.c,
      } ),
    },

    //
    // Login finalize

    [ LOGIN_FINALIZE ]: {
      saga: function* ( action ) {
        yield put( FusionReactor.reactWith( LOGIN_FINALIZE_REQUEST, action ) )
      },
    },

    [ LOGIN_FINALIZE_REQUEST ]: {
      reducer: ( state, action ) => ( {
        ...state,
        loginFinalizeRequest: {
          ...requestStates.requestState,
        },
      } ),

      saga: function* ( action ) {
        try {
          const username = action.username.replace( /[^0-9]+/, '' )
          const password = `!aZ09${SHA256( SHA256( username ) )}`

          yield call(
            api.cognito.login,
            username,
            password,
            password,
          )

          yield put( FusionReactor.reactWith( LOGIN_FINALIZE_SUCCESS ) )
        } catch ( e ) {
          Sentry.captureException( e )
          yield put( FusionReactor.reactWith( LOGIN_FINALIZE_FAILURE, {
            e: _.get( e, 'message', '' ),
            c: _.get( e, 'code', '' ),
          } ) )
        }
      },
    },

    [ LOGIN_FINALIZE_SUCCESS ]: {
      reducer: ( state, action ) => ( {
        ...state,
        loginFinalizeRequest: {
          ...requestStates.successState,
        },
      } ),
    },

    [ LOGIN_FINALIZE_FAILURE ]: {
      reducer: ( state, action ) => ( {
        ...state,
        loginFinalizeRequest: { ...requestStates.failureState },
        e: action.e,
        c: action.c,
      } ),
    },
  },
} )
