import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import IconButton from '@material-ui/core/IconButton'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Select from '@material-ui/core/Select'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import HelpIcon from '@material-ui/icons/HelpOutline'
import React from 'react'
import FusionReactor from '../../../lib/FusionReactor'
import AlertDialog from '../../../things/alert_dialog/AlertDialog'


const styles = ( theme ) => ( {
  formRow: {
    display: 'flex',
    flex: '1',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginTop: `${theme.spacing.unit}px`,
    padding: `${theme.spacing.unit}px`,
    paddingRight: '0',
  },
  formControl: {
    display: 'flex',
    flex: '1',
    order: '1',
  },
  selectHelpButton: {
    order: '2',
    marginTop: '.8rem',
    marginLeft: '.8rem',
  },
  helpButton: {
    order: '2',
    marginTop: '-1rem',
    marginLeft: '.8rem',
  },
  radioGroup: {
    marginTop: `${theme.spacing.unit}px`,
  },
} )

class Component extends React.Component {
  componentDidMount() {
    window.intercomSettings = {
      custom_launcher_selector: '#intercom-launcher',
    }
  }

  closeAllAlerts = () => {
    const {
      onClosePaysForeignTaxAlert,
      onClosePaysUSTaxAlert,
      onCloseIsPEPAlert,
    } = this.props

    onClosePaysForeignTaxAlert()
    onClosePaysUSTaxAlert()
    onCloseIsPEPAlert()
  }

  render() {
    const {
      classes,
      t,

      // Form fields
      citizenshipField,
      paysForeignTaxField,
      paysUSTaxField,
      isPEPField,

      onChangeCitizenshipField,
      onChangePaysForeignTaxField,
      onChangePaysUSTaxField,
      onChangeIsPEPField,

      // Dropdowns
      citizenshipSelectOpen,

      onOpenCitizenshipSelect,
      onCloseCitizenshipSelect,

      // Popups
      citizenshipPopupOpen,
      paysForeignTaxPopupOpen,
      paysUSTaxPopupOpen,
      isPEPPopupOpen,

      onOpenCitizenshipPopup,
      onOpenPaysForeignTaxPopup,
      onOpenPaysUSTaxPopup,
      onOpenIsPEPPopup,

      onCloseCitizenshipPopup,
      onClosePaysForeignTaxPopup,
      onClosePaysUSTaxPopup,
      onCloseIsPEPPopup,

      // Alerts
      paysForeignTaxAlertOpen,
      paysUSTaxAlertOpen,
      isPEPAlertOpen,

      onClosePaysForeignTaxAlert,
      onClosePaysUSTaxAlert,
      onCloseIsPEPAlert,
    } = this.props

    const HelpPopups = [
      <AlertDialog
        key="citizenship-popup"
        title={ t( 'AgreementsScreen.AboutScreen.CitizenshipHelpPopup.Title' ) }
        text={ t( 'AgreementsScreen.AboutScreen.CitizenshipHelpPopup.Text' ) }
        labelOk={ t( 'AgreementsScreen.AboutScreen.HelpPopups.OkButton' ) }
        open={ citizenshipPopupOpen }
        onClose={ onCloseCitizenshipPopup }
        onOk={ onCloseCitizenshipPopup }
      />,

      <AlertDialog
        key="foreign-tax-popup"
        title={ t( 'AgreementsScreen.AboutScreen.PaysForeignTaxHelpPopup.Title' ) }
        text={ t( 'AgreementsScreen.AboutScreen.PaysForeignTaxHelpPopup.Text' ) }
        labelOk={ t( 'AgreementsScreen.AboutScreen.HelpPopups.OkButton' ) }
        open={ paysForeignTaxPopupOpen }
        onClose={ onClosePaysForeignTaxPopup }
        onOk={ onClosePaysForeignTaxPopup }
      />,

      <AlertDialog
        key="us-tax-popup"
        title={ t( 'AgreementsScreen.AboutScreen.PaysUSTaxHelpPopup.Title' ) }
        text={ t( 'AgreementsScreen.AboutScreen.PaysUSTaxHelpPopup.Text' ) }
        labelOk={ t( 'AgreementsScreen.AboutScreen.HelpPopups.OkButton' ) }
        open={ paysUSTaxPopupOpen }
        onClose={ onClosePaysUSTaxPopup }
        onOk={ onClosePaysUSTaxPopup }
      />,

      <AlertDialog
        key="is-pep-popup"
        title={ t( 'AgreementsScreen.AboutScreen.IsPEPHelpPopup.Title' ) }
        text={ t( 'AgreementsScreen.AboutScreen.IsPEPHelpPopup.Text' ) }
        labelOk={ t( 'AgreementsScreen.AboutScreen.HelpPopups.OkButton' ) }
        open={ isPEPPopupOpen }
        onClose={ onCloseIsPEPPopup }
        onOk={ onCloseIsPEPPopup }
      />,
    ]

    const AlertButtons = ( keyPrefix ) => [
      <Button key={ `${keyPrefix}-chat-button` } color="primary" id="intercom-launcher">
        { t( 'AgreementsScreen.AboutScreen.Alerts.ChatButton' ) }
      </Button>,
      <Button key={ `${keyPrefix}-call-button` } color="primary">
        { t( 'AgreementsScreen.AboutScreen.Alerts.CallButton' ) }
      </Button>,
      <Button key={ `${keyPrefix}-ok-button` } color="primary" onClick={ this.closeAllAlerts }>
        { t( 'AgreementsScreen.AboutScreen.Alerts.OkButton' ) }
      </Button>,
    ]

    const Alerts = [
      <AlertDialog
        key="foreign-tax-alert"
        title={ t( 'AgreementsScreen.AboutScreen.PaysForeignTaxAlert.Title' ) }
        text={ t( 'AgreementsScreen.AboutScreen.PaysForeignTaxAlert.Text' ) }
        open={ paysForeignTaxAlertOpen }
        onClose={ onClosePaysForeignTaxAlert }
        buttons={ AlertButtons( 'foreign-tax-alert' ) }
      />,

      <AlertDialog
        key="us-tax-alert"
        title={ t( 'AgreementsScreen.AboutScreen.PaysUSTaxAlert.Title' ) }
        text={ t( 'AgreementsScreen.AboutScreen.PaysUSTaxAlert.Text' ) }
        open={ paysUSTaxAlertOpen }
        onClose={ onClosePaysUSTaxAlert }
        buttons={ AlertButtons( 'us-tax-alert' ) }
      />,

      <AlertDialog
        key="is-pep-alert"
        title={ t( 'AgreementsScreen.AboutScreen.IsPEPAlert.Title' ) }
        text={ t( 'AgreementsScreen.AboutScreen.IsPEPAlert.Text' ) }
        open={ isPEPAlertOpen }
        onClose={ onCloseIsPEPAlert }
        buttons={ AlertButtons( 'is-pep-alert' ) }
      />,
    ]

    return (
      <div>
        { HelpPopups }

        { Alerts }

        <div className={ classes.formRow }>
          <IconButton onClick={ onOpenCitizenshipPopup } className={ classes.selectHelpButton }>
            <HelpIcon/>
          </IconButton>

          <FormControl className={ classes.formControl }>
            <InputLabel htmlFor="citizenship-field">
              { t( 'AgreementsScreen.AboutScreen.Form.CitizenshipField.Label' ) }
            </InputLabel>
            <Select
              open={ citizenshipSelectOpen }
              onOpen={ onOpenCitizenshipSelect }
              onClose={ onCloseCitizenshipSelect }
              onChange={ onChangeCitizenshipField }
              value={ citizenshipField }
              inputProps={ {
                id: 'citizenship-field',
              } }
            >
              <MenuItem value="">
                <em>{ t( 'AgreementsScreen.AboutScreen.Form.CitizenshipField.None' ) }</em>
              </MenuItem>
              <MenuItem value="Swedish">{ t( 'AgreementsScreen.AboutScreen.Form.CitizenshipField.Swedish' ) }</MenuItem>
            </Select>
          </FormControl>
        </div>

        <Divider/>

        <div className={ classes.formRow }>
          <Typography>{ t( 'AgreementsScreen.AboutScreen.Form.Questions' ) }</Typography>
        </div>

        <div className={ classes.formRow }>
          <IconButton onClick={ onOpenPaysForeignTaxPopup } className={ classes.helpButton }>
            <HelpIcon/>
          </IconButton>

          <FormControl component="fieldset" className={ classes.formControl }>
            <FormLabel
              component="legend">{ t( 'AgreementsScreen.AboutScreen.Form.PaysForeignTaxField.Legend' ) }</FormLabel>
            <RadioGroup
              aria-label={ t( 'AgreementsScreen.AboutScreen.Form.PaysForeignTaxField.Legend' ) }
              name="paysForeignTax"
              className={ classes.radioGroup }
              value={ paysForeignTaxField }
              onChange={ onChangePaysForeignTaxField }
            >
              <FormControlLabel value="Yes" control={ <Radio/> }
                                label={ t( 'AgreementsScreen.AboutScreen.Form.PaysForeignTaxField.LabelYes' ) }/>
              <FormControlLabel value="No" control={ <Radio/> }
                                label={ t( 'AgreementsScreen.AboutScreen.Form.PaysForeignTaxField.LabelNo' ) }/>
            </RadioGroup>
          </FormControl>
        </div>

        <Divider/>

        <div className={ classes.formRow }>
          <IconButton onClick={ onOpenPaysUSTaxPopup } className={ classes.helpButton }>
            <HelpIcon/>
          </IconButton>

          <FormControl component="fieldset" className={ classes.formControl }>
            <FormLabel component="legend">{ t( 'AgreementsScreen.AboutScreen.Form.PaysUSTaxField.Legend' ) }</FormLabel>
            <RadioGroup
              aria-label={ t( 'AgreementsScreen.AboutScreen.Form.PaysUSTaxField.Legend' ) }
              name="paysUSTax"
              className={ classes.radioGroup }
              value={ paysUSTaxField }
              onChange={ onChangePaysUSTaxField }
            >
              <FormControlLabel value="Yes" control={ <Radio/> }
                                label={ t( 'AgreementsScreen.AboutScreen.Form.PaysUSTaxField.LabelYes' ) }/>
              <FormControlLabel value="No" control={ <Radio/> }
                                label={ t( 'AgreementsScreen.AboutScreen.Form.PaysUSTaxField.LabelNo' ) }/>
            </RadioGroup>
          </FormControl>
        </div>

        <Divider/>

        <div className={ classes.formRow }>
          <IconButton onClick={ onOpenIsPEPPopup } className={ classes.helpButton }>
            <HelpIcon/>
          </IconButton>

          <FormControl component="fieldset" className={ classes.formControl }>
            <FormLabel component="legend">{ t( 'AgreementsScreen.AboutScreen.Form.IsPEPField.Legend' ) }</FormLabel>
            <RadioGroup
              aria-label={ t( 'AgreementsScreen.AboutScreen.Form.IsPEPField.Legend' ) }
              name="isPEP"
              className={ classes.radioGroup }
              value={ isPEPField }
              onChange={ onChangeIsPEPField }
            >
              <FormControlLabel value="Yes" control={ <Radio/> }
                                label={ t( 'AgreementsScreen.AboutScreen.Form.IsPEPField.LabelYes' ) }/>
              <FormControlLabel value="No" control={ <Radio/> }
                                label={ t( 'AgreementsScreen.AboutScreen.Form.IsPEPField.LabelNo' ) }/>
            </RadioGroup>
          </FormControl>
        </div>
      </div>
    )
  }
}

export default FusionReactor( withStyles( styles )( Component ) )