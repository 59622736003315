import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Radio from '@material-ui/core/Radio'
import { withStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'
import _ from 'lodash'
import React from 'react'
import FusionReactor from '../../lib/FusionReactor'
import * as dataReactions from '../../providers/data/reactions'
import * as redirectReactions from '../../providers/redirect/reactions'
import Dashboard from '../../rigging/dashboard/Dashboard'
import Hero from '../../rigging/hero/Hero'
import * as reactions from './reactions'
import styles from './styles'


class Component extends React.Component {
  componentDidMount() {
    const {
      reactWith,
      state: {
        dataProvider: {
          user,
        },
      },
    } = this.props

    reactWith( dataReactions.REINITIALIZE_UPDATE_USER_STATE, { key: 'preferences' } )
    reactWith( reactions.REINITIALIZE_STATE )
    reactWith( reactions.HYDRATE_FORM_FIELDS, {
      formFields: _.pick( user, [
        'wants_notifications',
        'notification_frequency',
        'wants_email_notifications',
        'wants_sms_notifications',
      ] ),
    } )

    window.analytics.page( 'Preferences' )
  }

  componentDidUpdate() {
    const {
      reactWith,
      state: {
        dataProvider: {
          updateUserRequests: {
            preferences: {
              hasSucceeded: updateUserHasSucceeded,
            },
          },
        },
      },
    } = this.props

    if ( updateUserHasSucceeded ) {
      reactWith( redirectReactions.REDIRECT_TO_HOME )
    }
  }

  dashboardInFlight = () => {
    const {
      state: {
        dataProvider: {
          updateUserRequests: {
            preferences: {
              inFlight: updateUserInFlight,
              hasSucceeded: updateUserHasSucceeded,
            },
          },
        },
      },
    } = this.props

    return updateUserInFlight || updateUserHasSucceeded
  }

  //
  // Controller fields

  changeWantsNotificationsField = ( e, checked ) => {
    const { reactWith } = this.props
    reactWith( reactions.CHANGE_FORM_FIELD, {
      field: 'wants_notifications',
      value: checked,
    } )
  }

  changeNotificationFrequencyField = ( e ) => {
    const { reactWith } = this.props
    reactWith( reactions.CHANGE_FORM_FIELD, {
      field: 'notification_frequency',
      value: e.target.value,
    } )
  }

  changeWantsEmailNotificationsField = ( e, checked ) => {
    const { reactWith } = this.props
    reactWith( reactions.CHANGE_FORM_FIELD, {
      field: 'wants_email_notifications',
      value: checked,
    } )
  }

  changeWantsSMSNotificationsField = ( e, checked ) => {
    const { reactWith } = this.props
    reactWith( reactions.CHANGE_FORM_FIELD, {
      field: 'wants_sms_notifications',
      value: checked,
    } )
  }

  //
  // Form

  updatePreferences = () => {
    const {
      reactWith,
      state: {
        dataProvider: {
          user,
        },
        preferencesScreen: {
          formFields,
        },
      },
    } = this.props

    reactWith( dataReactions.UPDATE_USER, {
      key: 'preferences',
      params: {
        id: user.id,
        ...formFields,
      },
    } )
  }

  render() {
    const {
      classes,
      t,
      state: {
        preferencesScreen: {
          formFields,
        },
      },
    } = this.props

    let SaveButton = (
      <Button
        className={ classes.nextButton }
        color="inherit"
        disabled={ this.dashboardInFlight() }
        onClick={ this.updatePreferences }
      >
        { t( 'PreferencesScreen.SaveButton' ) }
      </Button>
    )

    return (
      <Hero>
        <Dashboard
          title={ t( 'PreferencesScreen.Title' ) }
          left="drawer"
          right={ SaveButton }
          inFlight={ this.dashboardInFlight() }
        >
          <div className="Screen PreferencesScreen">
            <div className={ classes.formRow }>
              <Typography className={ classes.rowHeading }>{ t( 'PreferencesScreen.Channels' ) }</Typography>

              <List component="nav">
                <ListItem className={ classes.listItem }>
                  <ListItemText primary={ t( 'PreferencesScreen.Form.EmailField.Label' ) }/>
                  <ListItemSecondaryAction>
                    <Switch
                      checked={ formFields.wants_email_notifications }
                      onChange={ this.changeWantsEmailNotificationsField }
                      value="Yes"
                      color="primary"
                    />
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider/>

                <ListItem className={ classes.listItem }>
                  <ListItemText primary={ t( 'PreferencesScreen.Form.SmsField.Label' ) }/>
                  <ListItemSecondaryAction>
                    <Switch
                      checked={ formFields.wants_sms_notifications }
                      onChange={ this.changeWantsSMSNotificationsField }
                      value="Yes"
                      color="primary"
                    />
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider/>

                <ListItem className={ classes.listItem }>
                  <ListItemText primary={ t( 'PreferencesScreen.Form.NotificationsField.Label' ) }/>
                  <ListItemSecondaryAction>
                    <Switch
                      checked={ formFields.wants_notifications }
                      onChange={ this.changeWantsNotificationsField }
                      value="Yes"
                      color="primary"
                    />
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider/>
              </List>
            </div>

            <div className={ classes.formRow }>
              <Typography className={ classes.rowHeading }>{ t( 'PreferencesScreen.Frequency' ) }</Typography>

              <List component="nav">
                <ListItem className={ classes.listItem }>
                  <ListItemText primary={ t( 'PreferencesScreen.Form.FrequencyField.Label.Anytime' ) }/>
                  <ListItemSecondaryAction>
                    <Radio
                      checked={ 'anytime' === formFields.notification_frequency }
                      onChange={ this.changeNotificationFrequencyField }
                      value="anytime"
                      aria-label={ t( 'PreferencesScreen.Form.FrequencyField.Label.Anytime' ) }
                      color="primary"
                    />
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider/>

                <ListItem className={ classes.listItem }>
                  <ListItemText primary={ t( 'PreferencesScreen.Form.FrequencyField.Label.Monthly' ) }/>
                  <ListItemSecondaryAction>
                    <Radio
                      checked={ 'monthly' === formFields.notification_frequency }
                      onChange={ this.changeNotificationFrequencyField }
                      value="monthly"
                      aria-label={ t( 'PreferencesScreen.Form.FrequencyField.Label.Monthly' ) }
                      color="primary"
                    />
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider/>

                <ListItem className={ classes.listItem }>
                  <ListItemText primary={ t( 'PreferencesScreen.Form.FrequencyField.Label.Quarterly' ) }/>
                  <ListItemSecondaryAction>
                    <Radio
                      checked={ 'quarterly' === formFields.notification_frequency }
                      onChange={ this.changeNotificationFrequencyField }
                      value="quarterly"
                      aria-label={ t( 'PreferencesScreen.Form.FrequencyField.Label.Quarterly' ) }
                      color="primary"
                    />
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider/>

                <ListItem className={ classes.listItem }>
                  <ListItemText primary={ t( 'PreferencesScreen.Form.FrequencyField.Label.Yearly' ) }/>
                  <ListItemSecondaryAction>
                    <Radio
                      checked={ 'yearly' === formFields.notification_frequency }
                      onChange={ this.changeNotificationFrequencyField }
                      value="yearly"
                      aria-label={ t( 'PreferencesScreen.Form.FrequencyField.Label.Yearly' ) }
                      color="primary"
                    />
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider/>
              </List>
            </div>
          </div>
        </Dashboard>
      </Hero>
    )
  }
}

export default FusionReactor( withStyles( styles )( Component ) )
