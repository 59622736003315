import Avatar from '@material-ui/core/Avatar'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import React from 'react'
import FusionReactor from '../../../lib/FusionReactor'
import styles from '../styles'
import InsuranceDetails from './InsuranceDetails'


class Component extends React.Component {
  render() {
    const {
      classes,
      title,
    } = this.props

    const CardAvatar = (
      <Avatar>
        { title[ 0 ] }
      </Avatar>
    )

    const CardSubheader = (
      <InsuranceDetails { ..._.pick( this.props, [
        'pin',
        'premium_recurrence',
        'premium_status',
        'premium_last_payed_at',
        'monthly_premium',
        'holding',
        'status',
      ] ) } />
    )

    return (
      <Card className={ classes.insuranceCard }>
        <CardHeader
          avatar={ CardAvatar }
          title={ title }
          subheader={ CardSubheader }
          className={ classes.insuranceCardHeader }
        />
      </Card>
    )
  }
}

export default FusionReactor( withStyles( styles )( Component ) )
