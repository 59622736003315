export default ( theme ) => ( {
  nextButton: {
    marginRight: -12,
  },

  formRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginTop: `${theme.spacing.unit}px`,
    padding: `${theme.spacing.unit}px`,
    paddingRight: '0',
  },

  formControl: {
    display: 'flex',
    flex: '1',
    order: '1',
  },

  confirmBlurb: {
    margin: `${theme.spacing.unit * 3}px 0 0 0`,
    padding: `${theme.spacing.unit * 2}px`,
    textAlign: 'center',
  },
  confirmFormControl: {
    display: 'inline-block',
    width: '150px',
    margin: '0 auto',

    '& input': {
      textAlign: 'center !important',
    },
  },
  resendButton: {
    margin: `${theme.spacing.unit * 2}px`,
  },

  activeSubscription: {
    margin: `${theme.spacing.unit * 3}px 0`,
    padding: `${theme.spacing.unit * 3}px`,
    textAlign: 'center',
  },

  activeTrial: {
    margin: `${theme.spacing.unit * 3}px 0`,
    padding: `${theme.spacing.unit * 3}px`,
    textAlign: 'center',
  },

  cancelSubscriptionButton: {
    margin: `${theme.spacing.unit * 3}px 0`,
  },

  cancelSubscriptionInFlight: {
    marginLeft: `${theme.spacing.unit * 2}px`,
  },
} )
