import _ from 'lodash'

// Returns a new object with all of it's properties set/toggled to `false`
// with the exception of `except` which will be set to true
export default ( object, except ) => {
  const toggledObject = { ...object }
  for ( let t in toggledObject ) {
    toggledObject[ t ] = false
  }

  if ( !_.isUndefined( except ) && !_.isUndefined( toggledObject[ except ] ) ) {
    toggledObject[ except ] = true
  }

  return toggledObject
}