import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ErrorIcon from '@material-ui/icons/Error'
import * as Sentry from '@sentry/browser'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import FusionReactor from '../../lib/FusionReactor'
import styles from './styles'


class Component extends React.Component {
  constructor( props ) {
    super( props )
    this.state = { error: null }
  }

  componentDidCatch( error, errorInfo ) {
    this.setState( { error } )
    Sentry.withScope( scope => {
      Object.keys( errorInfo ).forEach( key => {
        scope.setExtra( key, errorInfo[ key ] )
      } )
      Sentry.captureException( error )
    } )
  }

  render() {
    const {
      classes,
      t,
    } = this.props

    if ( this.state.error ) {
      // Error fallback UI
      return (
        <div className={ classes.fullScreen }>
          <ErrorIcon className={ classes.failureIcon }/>

          <Typography className={ classes.fullScreenText }>
            <FormattedMessage
              id="ErrorBoundary.Text"
              values={ { br: <br/> } }
            />
          </Typography>

          <Button
            variant="raised"
            color="primary"
            onClick={ () => Sentry.showReportDialog() }>
            { t( 'ErrorBoundary.ReportIssueButton' ) }
          </Button>
        </div>
      )
    } else {
      // when there's no error, render children untouched
      return this.props.children
    }
  }
}

export default FusionReactor( withStyles( styles )( Component ) )