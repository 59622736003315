export default ( theme ) => ( {
  notificationsButton: {
    marginRight: -12,
  },

  insuranceGroupHeading: {
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit}px`,
    paddingTop: `${theme.spacing.unit}px`,
    color: '#979797',
  },

  insuranceCard: {
    marginBottom: `${theme.spacing.unit}px`,
  },

  insuranceCardHeader: {
    alignItems: 'flex-start',
  },

  insuranceCardContent: {
    paddingTop: '0',
    paddingLeft: '70px',
  },

  insuranceCardAvatar: {
    marginTop: '5px',
    background: '#FFCC6F',
  },
} )