import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'
import FusionReactor from '../../../lib/FusionReactor'
import styles from '../styles'
import InsuranceDetails from './InsuranceDetails'


class Component extends React.Component {
  render() {
    const {
      classes,
      t,
      title,
      to,
    } = this.props

    const CardAvatar = (
      <Avatar className={ classes.insuranceCardAvatar }>
        { title[ 0 ] }
      </Avatar>
    )

    const CardSubheader = (
      <InsuranceDetails { ..._.pick( this.props, [
        'pin',
        'premium_recurrence',
        'premium_status',
        'premium_last_payed_at',
        'monthly_premium',
        'holding',
        'status',
      ] ) } />
    )

    const OptimizeButton = (
      <Button
        variant="raised"
        color="primary"
        className={ classes.button }
        component={ Link }
        to={ to }
      >
        { t( 'HomeScreen.InsuranceCard.OptimizeButton' ) }
      </Button>
    )

    return (
      <Card className={ classes.insuranceCard }>
        <CardHeader
          avatar={ CardAvatar }
          title={ title }
          subheader={ CardSubheader }
          className={ classes.insuranceCardHeader }
        />
        <CardContent className={ classes.insuranceCardContent }>
          { OptimizeButton }
        </CardContent>
      </Card>
    )
  }
}

export default FusionReactor(withStyles(styles)(Component))
