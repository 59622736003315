import * as Sentry from '@sentry/browser'
import _ from 'lodash'
import { call, put } from 'redux-saga/effects'
import api from '../../lib/api/api'
import FusionReactor from '../../lib/FusionReactor'
import * as requestStates from '../../lib/requestStates'
import toggleObjectProperties from '../../lib/toggleObjectProperties'


export const REINITIALIZE_STATE = 'screens/account/REINITIALIZE_STATE'

export const HYDRATE_FORM_FIELDS = 'screens/account/HYDRATE_FORM_FIELDS'
export const CHANGE_FORM_FIELD = 'screens/account/CHANGE_FORM_FIELD'

export const TOGGLE_CONFIRM = 'screens/account/TOGGLE_CONFIRM'

export const CREATE_PHONE_NUMBER_CONFIRMATION = 'screens/account/CREATE_PHONE_NUMBER_CONFIRMATION'
export const CREATE_PHONE_NUMBER_CONFIRMATION_REQUEST = 'screens/account/CREATE_PHONE_NUMBER_CONFIRMATION_REQUEST'
export const CREATE_PHONE_NUMBER_CONFIRMATION_SUCCESS = 'screens/account/CREATE_PHONE_NUMBER_CONFIRMATION_SUCCESS'
export const CREATE_PHONE_NUMBER_CONFIRMATION_FAILURE = 'screens/account/CREATE_PHONE_NUMBER_CONFIRMATION_FAILURE'

export const SEND_CONFIRMATION_SMS = 'screens/account/SEND_CONFIRMATION_SMS'
export const SEND_CONFIRMATION_SMS_REQUEST = 'screens/account/SEND_CONFIRMATION_SMS_REQUEST'
export const SEND_CONFIRMATION_SMS_SUCCESS = 'screens/account/SEND_CONFIRMATION_SMS_SUCCESS'
export const SEND_CONFIRMATION_SMS_FAILURE = 'screens/account/SEND_CONFIRMATION_SMS_FAILURE'

export const CONFIRM_PHONE_NUMBER = 'screens/account/CONFIRM_PHONE_NUMBER'
export const CONFIRM_PHONE_NUMBER_REQUEST = 'screens/account/CONFIRM_PHONE_NUMBER_REQUEST'
export const CONFIRM_PHONE_NUMBER_SUCCESS = 'screens/account/CONFIRM_PHONE_NUMBER_SUCCESS'
export const CONFIRM_PHONE_NUMBER_FAILURE = 'screens/account/CONFIRM_PHONE_NUMBER_FAILURE'

export const UNSUBSCRIBE_FROM_STRIPE_PLAN = 'screens/account/UNSUBSCRIBE_FROM_STRIPE_PLAN'
export const UNSUBSCRIBE_FROM_STRIPE_PLAN_REQUEST = 'screens/account/UNSUBSCRIBE_FROM_STRIPE_PLAN_REQUEST'
export const UNSUBSCRIBE_FROM_STRIPE_PLAN_SUCCESS = 'screens/account/UNSUBSCRIBE_FROM_STRIPE_PLAN_SUCCESS'
export const UNSUBSCRIBE_FROM_STRIPE_PLAN_FAILURE = 'screens/account/UNSUBSCRIBE_FROM_STRIPE_PLAN_FAILURE'

const initialState = {
  formFields: {
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    confirmation_code: '',

    phoneNumberConfirmationId: '',
    phoneNumberConfirmationCode: '',
  },

  confirm: {
    unsubscribe: false,
  },

  createPhoneNumberConfirmationRequest: { ...requestStates.initialState },
  sendConfirmationSMSRequest: { ...requestStates.initialState },
  confirmPhoneNumberRequest: { ...requestStates.initialState },
  unsubscribeFromStripePlanRequest: { ...requestStates.initialState },
  e: '',
}

export default FusionReactor.react({
  initialState,
  reactions: {
    [REINITIALIZE_STATE]: {
      reducer: (state, action) => ({ ...initialState }),
    },

    //
    // Controlled fields

    [HYDRATE_FORM_FIELDS]: {
      reducer: (state, action) => {
        const { formFields } = action
        if (null === formFields.phone_number) {
          formFields.phone_number = ''
        }

        return {
          ...state,
          formFields: {
            ...state.formFields,
            ...formFields,
          },
        }
      },
    },

    [CHANGE_FORM_FIELD]: {
      reducer: (state, action) => ({
        ...state,
        formFields: {
          ...state.formFields,
          [action.field]: action.value,
        },
      }),
    },

    //
    // Confirm

    [TOGGLE_CONFIRM]: {
      reducer: (state, action) => ({
        ...state,
        confirm: toggleObjectProperties(state.confirm, action.confirm),
      }),
    },

    //
    // Create phone number confirmation

    [CREATE_PHONE_NUMBER_CONFIRMATION]: {
      saga: function*(action) {
        yield put(FusionReactor.reactWith(CREATE_PHONE_NUMBER_CONFIRMATION_REQUEST, action))
      },
    },

    [CREATE_PHONE_NUMBER_CONFIRMATION_REQUEST]: {
      reducer: (state, action) => ({
        ...state,
        createPhoneNumberConfirmationRequest: { ...requestStates.requestState },
      }),

      saga: function*(action) {
        try {
          const { params } = action,
          result = yield call(api.mutation, `
              mutation PhoneNumberConfirmationCreate {
                PhoneNumberConfirmationCreate(
                  user_id: "${params.user_id}",
                  phone_number: "${params.phone_number}",
                  confirmation_code: "${params.confirmation_code}",
                  confirmation_status: "PENDING"
                ) {
                  id,
                  user_id,
                  confirmation_code
                }
              }
            `)

          yield put(FusionReactor.reactWith(CREATE_PHONE_NUMBER_CONFIRMATION_SUCCESS, { result }))
        }
        catch (e) {
          Sentry.captureException(e)
          yield put(FusionReactor.reactWith(CREATE_PHONE_NUMBER_CONFIRMATION_FAILURE, { e: _.get(e, 'message', '') }))
        }
      },
    },

    [CREATE_PHONE_NUMBER_CONFIRMATION_SUCCESS]: {
      reducer: (state, action) => {
        const {
          result: {
            data: {
              PhoneNumberConfirmationCreate: {
                id: phoneNumberConfirmationId,
                confirmation_code: phoneNumberConfirmationCode,
              },
            },
          },
        } = action

        return {
          ...state,
          formFields: {
            ...state.formFields,
            phoneNumberConfirmationId,
            phoneNumberConfirmationCode,
          },
          createPhoneNumberConfirmationRequest: { ...requestStates.successState },
        }
      },
    },

    [CREATE_PHONE_NUMBER_CONFIRMATION_FAILURE]: {
      reducer: (state, action) => ({
        ...state,
        createPhoneNumberConfirmationRequest: { ...requestStates.failureState },
        e: action.e,
      }),
    },

    //
    // Send confirmation SMS

    [SEND_CONFIRMATION_SMS]: {
      saga: function*(action) {
        yield put(FusionReactor.reactWith(SEND_CONFIRMATION_SMS_REQUEST, action))
      },
    },

    [SEND_CONFIRMATION_SMS_REQUEST]: {
      reducer: (state, action) => ({
        ...state,
        sendConfirmationSMSRequest: { ...requestStates.requestState },
      }),

      saga: function*(action) {
        try {
          const { params } = action,
          result = yield call(api.sendConfirmationSMS, {
            ..._.pick(params, ['id', 'message']),
          })

          yield put(FusionReactor.reactWith(SEND_CONFIRMATION_SMS_SUCCESS, { result }))
        }
        catch (e) {
          Sentry.captureException(e)
          yield put(FusionReactor.reactWith(SEND_CONFIRMATION_SMS_FAILURE, { e: _.get(e, 'message', '') }))
        }
      },
    },

    [SEND_CONFIRMATION_SMS_SUCCESS]: {
      reducer: (state, action) => {
        return {
          ...state,
          sendConfirmationSMSRequest: { ...requestStates.successState },
        }
      },
    },

    [SEND_CONFIRMATION_SMS_FAILURE]: {
      reducer: (state, action) => ({
        ...state,
        sendConfirmationSMSRequest: { ...requestStates.failureState },
        e: action.e,
      }),
    },

    //
    // Confirm phone number

    [CONFIRM_PHONE_NUMBER]: {
      saga: function*(action) {
        yield put(FusionReactor.reactWith(CONFIRM_PHONE_NUMBER_REQUEST, action))
      },
    },

    [CONFIRM_PHONE_NUMBER_REQUEST]: {
      reducer: (state, action) => ({
        ...state,
        confirmPhoneNumberRequest: { ...requestStates.requestState },
      }),

      saga: function*(action) {
        try {
          const { params } = action,
          result = yield call(api.confirmPhoneNumber, params.id)

          yield put(FusionReactor.reactWith(CONFIRM_PHONE_NUMBER_SUCCESS, { result }))
        }
        catch (e) {
          Sentry.captureException(e)
          yield put(FusionReactor.reactWith(CONFIRM_PHONE_NUMBER_FAILURE, { e: _.get(e, 'message', '') }))
        }
      },
    },

    [CONFIRM_PHONE_NUMBER_SUCCESS]: {
      reducer: (state, action) => {
        return {
          ...state,
          confirmPhoneNumberRequest: { ...requestStates.successState },
        }
      },
    },

    [CONFIRM_PHONE_NUMBER_FAILURE]: {
      reducer: (state, action) => ({
        ...state,
        confirmPhoneNumberRequest: { ...requestStates.failureState },
        e: action.e,
      }),
    },

    //
    // Unsubscribe

    [UNSUBSCRIBE_FROM_STRIPE_PLAN]: {
      saga: function*(action) {
        yield put(FusionReactor.reactWith(UNSUBSCRIBE_FROM_STRIPE_PLAN_REQUEST, action))
      },
    },

    [UNSUBSCRIBE_FROM_STRIPE_PLAN_REQUEST]: {
      reducer: (state, action) => ({
        ...state,
        unsubscribeFromStripePlanRequest: { ...requestStates.requestState },
      }),

      saga: function*(action) {
        try {
          const result = yield call(api.unsubscribeFromStripePlan)

          yield put(FusionReactor.reactWith(UNSUBSCRIBE_FROM_STRIPE_PLAN_SUCCESS, { result }))
        }
        catch (e) {
          Sentry.captureException(e)
          yield put(FusionReactor.reactWith(UNSUBSCRIBE_FROM_STRIPE_PLAN_FAILURE, { e: _.get(e, 'message', '') }))
        }
      },
    },

    [UNSUBSCRIBE_FROM_STRIPE_PLAN_SUCCESS]: {
      reducer: (state, action) => {
        return {
          ...state,
          unsubscribeFromStripePlanRequest: { ...requestStates.successState },
        }
      },
    },

    [UNSUBSCRIBE_FROM_STRIPE_PLAN_FAILURE]: {
      reducer: (state, action) => ({
        ...state,
        unsubscribeFromStripePlanRequest: { ...requestStates.failureState },
        e: action.e,
      }),
    },
  },
})
