import FusionReactor from '../../lib/FusionReactor'
import toggleObjectProperties from '../../lib/toggleObjectProperties'


export const REINITIALIZE_STATE = 'screens/agreements/REINITIALIZE_STATE'

export const CHANGE_FORM_FIELD = 'screens/agreements/CHANGE_FORM_FIELD'
export const TOGGLE_DROPDOWN = 'screens/agreements/TOGGLE_DROPDOWN'
export const TOGGLE_SCREEN = 'screens/agreements/TOGGLE_SCREEN'
export const TOGGLE_POPUP = 'screens/agreements/TOGGLE_POPUP'
export const TOGGLE_ALERT = 'screens/agreements/TOGGLE_ALERT'

const initialState = {
  formFields: {
    // TODO: uncomment form fields when re-enabling the first Agreements screen
    // citizenship: '',
    // paysForeignTax: '',
    // paysUSTax: '',
    // isPEP: '',
    agrees_to_data_processing: false,
    agrees_to_contact: false,
    agrees_to_terms: false,
  },

  dropdowns: {
    citizenship: false,
  },

  screens: {
    about: false, // TODO: make this default to true when re-enabling the first Agreements screen
    conditions: true, // TODO: make this default to false when re-enabling the first Agreements screen
  },

  popups: {
    citizenship: false,
    paysForeignTax: false,
    paysUSTax: false,
    isPEP: false,
  },

  alerts: {
    paysForeignTax: false,
    paysUSTax: false,
    isPEP: false,
  },
}

export default FusionReactor.react( {
  initialState,
  reactions: {
    [ REINITIALIZE_STATE ]: {
      reducer: ( state, action ) => ( { ...initialState } ),
    },

    //
    // Controlled fields

    [ CHANGE_FORM_FIELD ]: {
      reducer: ( state, action ) => ( {
        ...state,
        formFields: {
          ...state.formFields,
          [ action.field ]: action.value,
        },
      } ),
    },

    //
    // Dropdowns, screens, popups and alerts

    [ TOGGLE_DROPDOWN ]: {
      reducer: ( state, action ) => ( {
        ...state,
        dropdowns: toggleObjectProperties( state.dropdowns, action.dropdown ),
      } ),
    },

    [ TOGGLE_SCREEN ]: {
      reducer: ( state, action ) => ( {
        ...state,
        screens: toggleObjectProperties( state.screens, action.screen ),
      } ),
    },

    [ TOGGLE_POPUP ]: {
      reducer: ( state, action ) => ( {
        ...state,
        popups: toggleObjectProperties( state.popups, action.popup ),
      } ),
    },

    [ TOGGLE_ALERT ]: {
      reducer: ( state, action ) => ( {
        ...state,
        alerts: toggleObjectProperties( state.alerts, action.alert ),
      } ),
    },
  },
} )
