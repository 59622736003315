import * as reactions from './reactions'
import _ from 'lodash'
import Dashboard from '../../rigging/dashboard/Dashboard'
import DocumentList from './DocumentList'
import FusionReactor from '../../lib/FusionReactor'
import Hero from '../../rigging/hero/Hero'
import moment from 'moment'
import React from 'react'
import styles from './styles'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'


class Component extends React.Component {
  componentDidMount() {
    const { reactWith } = this.props
    reactWith(reactions.REINITIALIZE_STATE)
    window.analytics.page('Documents')
  }

  render() {
    const {
      classes,
      t,
      state: {
        dataProvider: {
          user: {
            InsuranceItemList: insuranceItems,
            OrderList: documents,
          },
        },
      },
    } = this.props,
      timestamped = _.map(documents, (doc) => {
        // Convert created_at to timestamp
        return {
          ...doc,
          created_at: moment(doc.created_at).unix()
        }
      }),
      sorted = _.orderBy(timestamped, ['created_at'], ['desc']),
      orders = [],
      recommendations = []

    for (let s in sorted) {
      const doc = sorted[s],
        insuranceItem = _.find(insuranceItems, { id: doc.insurance_item_id }),
        docWithInsurance = {
          ...doc,
          InsuranceItemGet: insuranceItem
        }

      if (insuranceItem.InsuranceSourceGet.supports_digital_signatures) {
        orders.push({ ...docWithInsurance })
      }
      else {
        recommendations.push({ ...docWithInsurance })
      }
    }

    let Documents = (
      <div className={ classes.listGroup }>
        <Typography className={ classes.listGroupHeading }>{ t( 'DocumentsScreen.None', { subjects: t('Subject.orders') } ) }</Typography>
      </div>
    )

    //
    // Orders

    if (orders.length) {
      Documents = [
        <DocumentList key="orders-list" documents={orders} />
      ]
    }

    //
    // Recommendations

    if (recommendations.length) {
      if (!_.isArray(Documents)) {
        Documents = []
      }

      Documents.push(
        <DocumentList key="recommendations-list" documents={recommendations} />
      )
    }

    //
    // Return

    return (
      <Hero>
        <Dashboard
          title={ t( 'DocumentsScreen.Title' ) }
          left="drawer"
        >
          <div className="Screen DocumentsScreen">
            { Documents }
          </div>
        </Dashboard>
      </Hero>
    )
  }
}

export default FusionReactor(withStyles(styles)(Component))
