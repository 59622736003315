import * as dataReactions from '../../providers/data/reactions'
import * as detectEnv from '../../lib/detectEnv'
import * as requestStates from '../../lib/requestStates'
import * as Sentry from '@sentry/browser'
import _ from 'lodash'
import api from '../../lib/api/api'
import atob from 'atob'
import csv from 'parse-csv'
import FusionReactor from '../../lib/FusionReactor'
import HmacSHA256 from 'crypto-js/hmac-sha256'
import intercomConfig from '../../config/intercom'
import { call, put } from 'redux-saga/effects'


export const REINITIALIZE_STATE = 'providers/data/REINITIALIZE_STATE'
export const REINITIALIZE_UPDATE_USER_STATE = 'providers/data/REINITIALIZE_UPDATE_USER_STATE'

export const EVENT = 'providers/data/EVENT'
export const EVENT_REQUEST = 'providers/data/EVENT_REQUEST'
export const EVENT_SUCCESS = 'providers/data/EVENT_SUCCESS'
export const EVENT_FAILURE = 'providers/data/EVENT_FAILURE'

export const GET_USER = 'providers/data/GET_USER'
export const GET_USER_REQUEST = 'providers/data/GET_USER_REQUEST'
export const GET_USER_SUCCESS = 'providers/data/GET_USER_SUCCESS'
export const GET_USER_FAILURE = 'providers/data/GET_USER_FAILURE'

export const UPDATE_USER = 'providers/data/UPDATE_USER'
export const UPDATE_USER_REQUEST = 'providers/data/UPDATE_USER_REQUEST'
export const UPDATE_USER_SUCCESS = 'providers/data/UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAILURE = 'providers/data/UPDATE_USER_FAILURE'

export const GET_STRIPE_CUSTOMER_DATA = 'providers/data/GET_STRIPE_CUSTOMER_DATA'
export const GET_STRIPE_CUSTOMER_DATA_REQUEST = 'providers/data/GET_STRIPE_CUSTOMER_DATA_REQUEST'
export const GET_STRIPE_CUSTOMER_DATA_SUCCESS = 'providers/data/GET_STRIPE_CUSTOMER_DATA_SUCCESS'
export const GET_STRIPE_CUSTOMER_DATA_FAILURE = 'providers/data/GET_STRIPE_CUSTOMER_DATA_FAILURE'

export const GET_OPTIMIZATION_DATA = 'providers/data/GET_OPTIMIZATION_DATA'
export const GET_OPTIMIZATION_DATA_REQUEST = 'providers/data/GET_OPTIMIZATION_DATA_REQUEST'
export const GET_OPTIMIZATION_DATA_SUCCESS = 'providers/data/GET_OPTIMIZATION_DATA_SUCCESS'
export const GET_OPTIMIZATION_DATA_FAILURE = 'providers/data/GET_OPTIMIZATION_DATA_FAILURE'

const initialState = {
  user: { id: null },
  stripeCustomerData: { id: null },
  optimizationData: {},

  eventRequest: { ...requestStates.initialState },
  getUserRequest: { ...requestStates.initialState },
  updateUserRequests: {
    auth: { ...requestStates.initialState },
    agreements: { ...requestStates.initialState },
    account: { ...requestStates.initialState },
    preferences: { ...requestStates.initialState },
  },
  getStripeCustomerDataRequest: { ...requestStates.initialState },
  getOptimizationDataRequest: { ...requestStates.initialState },
  e: '',
}

export default FusionReactor.react({
  initialState: initialState,
  reactions: {
    [REINITIALIZE_STATE]: {
      reducer: (state, action) => ({ ...initialState }),
    },

    [REINITIALIZE_UPDATE_USER_STATE]: {
      reducer: (state, action) => {
        const { key } = action,
        updateUserRequests = { ...state.updateUserRequests }

        updateUserRequests[key] = { ...requestStates.initialState }

        return {
          ...state,
          updateUserRequests,
        }
      },
    },

    //
    // Event tracking

    [EVENT]: {
      saga: function*(action) {
        yield put(FusionReactor.reactWith(EVENT_REQUEST, action))
      },
    },

    [EVENT_REQUEST]: {
      reducer: (state, action) => ({
        ...state,
        eventRequest: { ...requestStates.requestState },
      }),

      saga: function*(action) {
        try {
          const { params } = action,
          result = yield call(api.event, params)

          yield put(FusionReactor.reactWith(EVENT_SUCCESS, { result }))
        }
        catch (e) {
          Sentry.captureException(e)
          yield put(FusionReactor.reactWith(EVENT_FAILURE, { e: _.get(e, 'message', '') }))
        }
      },
    },

    [EVENT_SUCCESS]: {
      reducer: (state, action) => {
        return {
          ...state,
          eventRequest: { ...requestStates.successState },
        }
      },
    },

    [EVENT_FAILURE]: {
      reducer: (state, action) => ({
        ...state,
        eventRequest: { ...requestStates.failureState },
        e: action.e,
      }),
    },

    //
    // Get user

    [GET_USER]: {
      saga: function*(action) {
        yield put(FusionReactor.reactWith(GET_USER_REQUEST, action))
      },
    },

    [GET_USER_REQUEST]: {
      reducer: (state, action) => ({
        ...state,
        getUserRequest: { ...requestStates.requestState },
      }),

      saga: function*(action) {
        try {
          const user = yield call(api.cognito.getCurrentUser),
            userSSN = _.get(user, 'username', null)

          if (!userSSN) {
            // noinspection ExceptionCaughtLocallyJS
            throw new Error('Can\'t get user\'s SSN')
          }

          const result = yield call(api.query, `
            {
              UserGetBy(attribute: "ssn", value: "${userSSN}") {
                id,
                ssn,
                country_id,
                birthday,
                age,
                email,
                phone_number,
                first_name,
                last_name,
                first_logged_in_at,
                collected_agreements_at,
                wants_notifications,
                notification_frequency,
                wants_email_notifications,
                wants_sms_notifications,
                requires_subscription,
                trial_period_starts_at,
                trial_period_length,
                days_since_trial_started,
                trial_expired,
                EmailConfirmationList {
                  id,
                  email,
                  created_at
                },
                PhoneNumberConfirmationList {
                  id,
                  phone_number,
                  confirmation_code,
                  confirmation_status,
                  created_at
                },
                CompanyGet {
                  id,
                  name,
                  min_retirement_age,
                  max_retirement_age
                },
                InsuranceItemList {
                  id,
                  classification,
                  pin,
                  holding,
                  premium_recurrence,
                  monthly_premium,
                  premium_status,
                  premium_last_payed_at,
                  status,
                  InsuranceSourceGet {
                    id,
                    name,
                    ssn,
                    supports_digital_signatures,
                    supports_sustainable_funds
                  },
                },
                OrderList {
                  id,
                  insurance_item_id,
                  signing_task_id,
                  signing_status,
                  signed_document_link,
                  when_to_retire,
                  risk_level,
                  fund_version,
                  holding,
                  save_time,
                  portfolio_fee,
                  fund_list,
                  min_return,
                  exp_return,
                  max_return,
                  created_at,
                  DeviationList {
                    id,
                    portfolio_fee,
                    fund_list,
                    max_return,
                    exp_return,
                    min_return
                  }
                },
              },
            }
          `)

          yield put(FusionReactor.reactWith(GET_USER_SUCCESS, { result, t: action.t }))
        }
        catch (e) {
          Sentry.captureException(e)
          yield put(FusionReactor.reactWith(GET_USER_FAILURE, { e: _.get(e, 'message', '') }))
        }
      },
    },

    [GET_USER_SUCCESS]: {
      reducer: (state, action) => {
        const { t } = action,
        users = _.get(action.result, 'data.UserGetBy', []),
          user = users[0],
          intercomId = !detectEnv.isProduction() ? 'test' : user.id,
          intercomProps = {
            company: !detectEnv.isProduction() ? 'test' : _.get(user, 'CompanyGet.name', null),
            email: !detectEnv.isProduction() ? 'test@brightsave.se' : user.email,
            name: !detectEnv.isProduction() ? 'test' : `${user.first_name} ${user.last_name}`,
          }

        Sentry.configureScope((scope) => {
          scope.setUser({
            id: intercomId,
            email: intercomProps.email,
          })
        })

        window.analytics.identify(intercomId, intercomProps, {
          integrations: {
            Intercom: {
              user_hash: HmacSHA256(intercomId, intercomConfig.secretKey, { asBytes: true }).toString(), // eslint-disable-line
              hideDefaultLauncher: true,
            },
          },
        })

        //
        // Add i18n order/recommendation subject

        const i18nSubject = {
          order: {
            subject: t('Subject.order'),
            subjects: t('Subject.orders'),
            Subject: t('Subject.Order'),
            Subjects: t('Subject.Orders'),
          },
          recommendation: {
            subject: t('Subject.recommendation'),
            subjects: t('Subject.recommendations'),
            Subject: t('Subject.Recommendation'),
            Subjects: t('Subject.Recommendations'),
          }
        }

        // to InsuranceItemList
        for (let i in user.InsuranceItemList) {
          const insuranceItem = user.InsuranceItemList[i]

          user.InsuranceItemList[i].i18n = i18nSubject.order
          if (!insuranceItem.InsuranceSourceGet.supports_digital_signatures) {
            user.InsuranceItemList[i].i18n = i18nSubject.recommendation
          }
        }

        //
        //  Return

        return {
          ...state,
          user: {
            ...user,
          },
          getUserRequest: { ...requestStates.successState },
        }
      },

      saga: function*(action) {
        const users = _.get(action.result, 'data.UserGetBy', []),
          user = users[0]

        if (!user.first_logged_in_at) {
          yield put(FusionReactor.reactWith(dataReactions.EVENT, {
            params: {
              event: 'User first login',
            },
          }))
        }
      },
    },

    [GET_USER_FAILURE]: {
      reducer: (state, action) => ({
        ...state,
        getUserRequest: { ...requestStates.failureState },
        e: action.e,
      }),
    },

    //
    // Update user

    [UPDATE_USER]: {
      saga: function*(action) {
        yield put(FusionReactor.reactWith(UPDATE_USER_REQUEST, action))
      },
    },

    [UPDATE_USER_REQUEST]: {
      reducer: (state, action) => {
        const { key, params } = action,
        updateUserRequests = { ...state.updateUserRequests }

        updateUserRequests[key] = { ...requestStates.requestState }

        return {
          ...state,
          user: {
            ...state.user,
            ...params, // Optimistic user update
          },
          updateUserRequests,
        }
      },

      saga: function*(action) {
        try {
          const { key, params } = action,
          paramKeys = _.keys(params),
            mutationParams = _.join(_.map(paramKeys, (p, index) => {
              let value = params[p]
              if (_.isString(value)) {
                value = `"${value}"`
              }

              return `${p}: ${value}${index + 1 < paramKeys.length ? ',' : ''}`
            }), `\n`),
            queryParams = _.join(_.map(paramKeys, (p, index) => `${p}${index + 1 < paramKeys.length ? ',' : ''}`), `\n`),
            result = yield call(api.mutation, `
              mutation UserUpdate {
                UserUpdate(
                  ${mutationParams}
                ) {
                  ${queryParams}
                }
              }
            `)

          yield put(FusionReactor.reactWith(UPDATE_USER_SUCCESS, { key, result }))
        }
        catch (e) {
          Sentry.captureException(e)
          yield put(FusionReactor.reactWith(UPDATE_USER_FAILURE, { key: action.key, e: _.get(e, 'message', '') }))
        }
      },
    },

    [UPDATE_USER_SUCCESS]: {
      reducer: (state, action) => {
        const { key, result } = action,
        userUpdate = _.get(result, 'data.UserUpdate', {}),
          updateUserRequests = { ...state.updateUserRequests }

        updateUserRequests[key] = { ...requestStates.successState }

        return {
          ...state,
          user: {
            ...state.user,
            ...userUpdate,
          },
          updateUserRequests,
        }
      },
    },

    [UPDATE_USER_FAILURE]: {
      reducer: (state, action) => {
        const { key } = action,
        updateUserRequests = { ...state.updateUserRequests }

        updateUserRequests[key] = { ...requestStates.failureState }

        return {
          ...state,
          updateUserRequests,
          e: action.e,
        }
      },
    },

    //
    // Get Stripe customer data

    [GET_STRIPE_CUSTOMER_DATA]: {
      saga: function*(action) {
        yield put(FusionReactor.reactWith(GET_STRIPE_CUSTOMER_DATA_REQUEST, action))
      },
    },

    [GET_STRIPE_CUSTOMER_DATA_REQUEST]: {
      reducer: (state, action) => ({
        ...state,
        getStripeCustomerDataRequest: { ...requestStates.requestState },
      }),

      saga: function*(action) {
        try {
          const result = yield call(api.getStripeCustomerData)

          yield put(FusionReactor.reactWith(GET_STRIPE_CUSTOMER_DATA_SUCCESS, { result }))
        }
        catch (e) {
          Sentry.captureException(e)
          yield put(FusionReactor.reactWith(GET_STRIPE_CUSTOMER_DATA_FAILURE, { e: _.get(e, 'message', '') }))
        }
      },
    },

    [GET_STRIPE_CUSTOMER_DATA_SUCCESS]: {
      reducer: (state, action) => {
        const {
          result: {
            data,
          },
        } = action,
        subscriptions = _.get(data, 'subscriptions.data', []),
          activeSubscription = _.find(subscriptions, { status: 'active' })

        return {
          ...state,
          stripeCustomerData: {
            ...data,
            activeSubscription,
          },
          getStripeCustomerDataRequest: { ...requestStates.successState },
        }
      },
    },

    [GET_STRIPE_CUSTOMER_DATA_FAILURE]: {
      reducer: (state, action) => ({
        ...state,
        getStripeCustomerDataRequest: { ...requestStates.failureState },
        e: action.e,
      }),
    },

    //
    // Get optimization data

    [GET_OPTIMIZATION_DATA]: {
      saga: function*(action) {
        yield put(FusionReactor.reactWith(GET_OPTIMIZATION_DATA_REQUEST, action))
      },
    },

    [GET_OPTIMIZATION_DATA_REQUEST]: {
      reducer: (state, action) => ({
        ...state,
        getOptimizationDataRequest: { ...requestStates.requestState },
      }),

      saga: function*(action) {
        try {
          const { params } = action,
          result = yield call(api.query, `
            {
              FrontierGetByList(attribute: "insurance_source_id", values: [${params.ids}]) {
                id,
                insurance_source_id,
                classification,
                data
              }
            }
          `)

          yield put(FusionReactor.reactWith(GET_OPTIMIZATION_DATA_SUCCESS, { result }))
        }
        catch (e) {
          Sentry.captureException(e)
          yield put(FusionReactor.reactWith(GET_OPTIMIZATION_DATA_FAILURE, { e: _.get(e, 'message', '') }))
        }
      },
    },

    [GET_OPTIMIZATION_DATA_SUCCESS]: {
      reducer: (state, action) => {
        const {
          result: {
            data: {
              FrontierGetByList: frontiers
            }
          },
        } = action,
        optimizationData = _.reduce(frontiers, (acc, frontier, index) => {
          const {
            insurance_source_id,
            classification,
            data
          } = frontier,
          decodedData = atob(data),
            csvParams = {
              headers: {
                included: true,
                downcase: true,
              },
            }

          if (_.isUndefined(acc[insurance_source_id])) {
            acc[insurance_source_id] = { insurance_source_id }
          }

          acc[insurance_source_id][classification] = csv.toJSON(decodedData.replace(/;/g, ','), csvParams)

          return acc
        }, [])

        return {
          ...state,
          optimizationData,
          getOptimizationDataRequest: { ...requestStates.successState },
        }
      },
    },

    [GET_OPTIMIZATION_DATA_FAILURE]: {
      reducer: (state, action) => ({
        ...state,
        getOptimizationDataRequest: { ...requestStates.failureState },
        e: action.e,
      }),
    },
  },
})
