import React from 'react'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import FusionReactor from '../../lib/FusionReactor'


class Component extends React.Component {
  render() {
    const {
      value,
      minimumDigits,
      maximumDigits,
    } = this.props

    return (
      <FormattedMessage
        id="CurrencyFormat"
        values={ {
          value: <FormattedNumber
            value={ value }
            minimumFractionDigits={ minimumDigits || 0 }
            maximumFractionDigits={ maximumDigits || 0 }
          />,
        } }
      />

    )
  }
}

export default FusionReactor( Component )
