import * as Sentry from '@sentry/browser'
import _ from 'lodash'
import { call, put } from 'redux-saga/effects'
import api from '../../lib/api/api'
import FusionReactor from '../../lib/FusionReactor'
import * as requestStates from '../../lib/requestStates'
import toggleObjectProperties from '../../lib/toggleObjectProperties'


export const REINITIALIZE_STATE = 'screens/email_required/REINITIALIZE_STATE'

export const REINITIALIZE_EMAIL_CONFIRMATIONS = 'screens/email_required/REINITIALIZE_EMAIL_CONFIRMATIONS'

export const TOGGLE_ALERT = 'screens/email_required/TOGGLE_ALERT'

export const TOGGLE_SCREEN = 'screens/email_required/TOGGLE_SCREEN'

export const CHANGE_FORM_FIELD = 'screens/email_required/CHANGE_FORM_FIELD'

export const CREATE_EMAIL_CONFIRMATION = 'screens/email_required/CREATE_EMAIL_CONFIRMATION'
export const CREATE_EMAIL_CONFIRMATION_REQUEST = 'screens/email_required/CREATE_EMAIL_CONFIRMATION_REQUEST'
export const CREATE_EMAIL_CONFIRMATION_SUCCESS = 'screens/email_required/CREATE_EMAIL_CONFIRMATION_SUCCESS'
export const CREATE_EMAIL_CONFIRMATION_FAILURE = 'screens/email_required/CREATE_EMAIL_CONFIRMATION_FAILURE'

export const SEND_CONFIRMATION_EMAIL = 'screens/email_required/SEND_CONFIRMATION_EMAIL'
export const SEND_CONFIRMATION_EMAIL_REQUEST = 'screens/email_required/SEND_CONFIRMATION_EMAIL_REQUEST'
export const SEND_CONFIRMATION_EMAIL_SUCCESS = 'screens/email_required/SEND_CONFIRMATION_EMAIL_SUCCESS'
export const SEND_CONFIRMATION_EMAIL_FAILURE = 'screens/email_required/SEND_CONFIRMATION_EMAIL_FAILURE'

const initialState = {
  alerts: {
    failure: false,
  },

  screens: {
    email: false,
    confirmation: false,
  },

  formFields: {
    emailConfirmationId: '',
    email: '',
  },

  createEmailConfirmationRequest: { ...requestStates.initialState },
  sendConfirmationEmailRequest: { ...requestStates.initialState },
  e: '',
}

export default FusionReactor.react({
  initialState,
  reactions: {
    [REINITIALIZE_STATE]: {
      reducer: (state, action) => ({ ...initialState }),
    },

    [REINITIALIZE_EMAIL_CONFIRMATIONS]: {
      reducer: (state, action) => ({
        ...state,
        alerts: {
          ...state.alerts,
          failure: false,
        },
        createEmailConfirmationRequest: { ...requestStates.initialState },
        sendConfirmationEmailRequest: { ...requestStates.initialState },
        e: '',
      }),
    },

    //
    // Alerts

    [TOGGLE_ALERT]: {
      reducer: (state, action) => ({
        ...state,
        alerts: toggleObjectProperties(state.alerts, action.alert),
      }),
    },

    //
    // Screens

    [TOGGLE_SCREEN]: {
      reducer: (state, action) => ({
        ...state,
        screens: toggleObjectProperties(state.screens, action.screen),
      }),
    },

    //
    // Controlled fields

    [CHANGE_FORM_FIELD]: {
      reducer: (state, action) => ({
        ...state,
        formFields: {
          ...state.formFields,
          [action.field]: action.value,
        },
      }),
    },

    //
    // Create email confirmation

    [CREATE_EMAIL_CONFIRMATION]: {
      saga: function*(action) {
        yield put(FusionReactor.reactWith(CREATE_EMAIL_CONFIRMATION_REQUEST, action))
      },
    },

    [CREATE_EMAIL_CONFIRMATION_REQUEST]: {
      reducer: (state, action) => ({
        ...state,
        createEmailConfirmationRequest: { ...requestStates.requestState },
      }),

      saga: function*(action) {
        try {
          const { params } = action,
          result = yield call(api.mutation, `
              mutation EmailConfirmationCreate {
                EmailConfirmationCreate(
                  user_id: "${params.user_id}",
                  email: "${params.email}",
                ) {
                  id,
                  user_id,
                  email,
                }
              }
            `)

          yield put(FusionReactor.reactWith(CREATE_EMAIL_CONFIRMATION_SUCCESS, { result }))
        }
        catch (e) {
          Sentry.captureException(e)
          yield put(FusionReactor.reactWith(CREATE_EMAIL_CONFIRMATION_FAILURE, { e: _.get(e, 'message', '') }))
        }
      },
    },

    [CREATE_EMAIL_CONFIRMATION_SUCCESS]: {
      reducer: (state, action) => {
        const {
          result: {
            data: {
              EmailConfirmationCreate: {
                id: emailConfirmationId,
              },
            },
          },
        } = action

        return {
          ...state,
          formFields: {
            ...state.formFields,
            emailConfirmationId,
          },
          createEmailConfirmationRequest: { ...requestStates.successState },
        }
      },
    },

    [CREATE_EMAIL_CONFIRMATION_FAILURE]: {
      reducer: (state, action) => ({
        ...state,
        createEmailConfirmationRequest: { ...requestStates.failureState },
        e: action.e,
      }),
    },

    //
    // Send confirmation email

    [SEND_CONFIRMATION_EMAIL]: {
      saga: function*(action) {
        yield put(FusionReactor.reactWith(SEND_CONFIRMATION_EMAIL_REQUEST, action))
      },
    },

    [SEND_CONFIRMATION_EMAIL_REQUEST]: {
      reducer: (state, action) => ({
        ...state,
        sendConfirmationEmailRequest: { ...requestStates.requestState },
      }),

      saga: function*(action) {
        try {
          const { params } = action,
          result = yield call(api.sendConfirmationEmail, {
            ..._.pick(params, ['id', 'subject', 'htmlBody', 'textBody']),
          })

          yield put(FusionReactor.reactWith(SEND_CONFIRMATION_EMAIL_SUCCESS, { result }))
        }
        catch (e) {
          Sentry.captureException(e)
          yield put(FusionReactor.reactWith(SEND_CONFIRMATION_EMAIL_FAILURE, { e: _.get(e, 'message', '') }))
        }
      },
    },

    [SEND_CONFIRMATION_EMAIL_SUCCESS]: {
      reducer: (state, action) => {
        return {
          ...state,
          sendConfirmationEmailRequest: { ...requestStates.successState },
        }
      },
    },

    [SEND_CONFIRMATION_EMAIL_FAILURE]: {
      reducer: (state, action) => ({
        ...state,
        sendConfirmationEmailRequest: { ...requestStates.failureState },
        e: action.e,
      }),
    },
  },
})
