import FusionReactor from '../../lib/FusionReactor'


export const REINITIALIZE_STATE = 'screens/intro/REINITIALIZE_STATE'
export const NEXT_STEP = 'screens/intro/NEXT_STEP'
export const PREV_STEP = 'screens/intro/PREV_STEP'

const initialState = {
  step: 0,
}

export default FusionReactor.react( {
  initialState,
  reactions: {
    [ REINITIALIZE_STATE ]: {
      reducer: ( state, action ) => ( { ...initialState } ),
    },

    [ NEXT_STEP ]: {
      reducer: ( state, action ) => ( { ...state, step: Math.min( state.step + 1, 3 ) } ),
    },

    [ PREV_STEP ]: {
      reducer: ( state, action ) => ( { ...state, step: Math.max( 0, state.step - 1 ) } ),
    },
  },
} )
