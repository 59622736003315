import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import React from 'react'
import FusionReactor from '../../lib/FusionReactor'


class Component extends React.Component {
  render() {
    const {
      button,
      buttons,
      labelOk,
      onClose,
      onOk,
      open,
      text,
      title,
    } = this.props

    let Buttons = (
      <Button onClick={ onOk } color="primary">
        { labelOk }
      </Button>
    )

    if ( button ) {
      Buttons = button
    } else if ( buttons ) {
      Buttons = buttons
    }

    return (
      <div className="Thing ConfirmThing">
        <Dialog
          open={ open }
          onClose={ onClose }
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={ { zIndex: 10000 } }
        >
          <DialogTitle id="alert-dialog-title">{ title }</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {/* Display straight up HTML */}
              <span dangerouslySetInnerHTML={ { __html: text } }/>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            { Buttons }
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default FusionReactor( Component )
