export default [
  '197102211898', // lucian.vacaroiu@toptal.com
  '196904174957', // niklas@advisave.se
  '197707100330', // joakim@brightsave.se
  '195612210186', // a 61 year old with a small pension fund and no premium
  '194706108679', // a 71 year old with a large pension fund and no premium
  '194706108679', //tested
  '196701083617', //tested
  '198704148934', //tested
  '197810237847', //tested
  '197402010529',
  '196607188221',
  '196408101704',
  '194612160111',
  '194711136418',
  '196809161901',
  '196508100085',
  '197511090347',
  '196801070332',
  '196001210068',
  '196304121087',
  '196910077996',
  '195008218512',
  '195105209596',
  '196409030035',
  '197702270310',
  '196807179301',
  '196602045699',
  '196403062711',
  '197211018226',
  '197106180313',
  '196507128954',
  '196612122991',
  '195106307555',
  '196312092957', //tested
  '196808280116',
  '196410221938',
  '195011231973',
  '195412114372',
  '196605170445',
  '196410091166',
  '196601031609',
  '197507150048',
  '196911249354',
  '196812200308',
  '196306090017',
  '196811290136',
  '196706155071',
  '196706130272',
  '195709239379',
  '196805176952',
  '196408267232',
  '196808060070',
  '196707103450',
  '196705031091',
  '195304093544',
  '197010207152',
  '197510084820',
  '196912250435',
  '195510144610',
  '197004020637',
  '195709061096', //tested
  '195506210391',
  '196502060822',
  '196904176630',
]