export default ( theme ) => ( {
  listGroup: {
    marginTop: `${theme.spacing.unit}px`,
    padding: `${theme.spacing.unit}px`,
  },

  listGroupHeading: {
    opacity: '.6',
  },

  listItem: {
    paddingLeft: '0',
    paddingRight: '0',
  },

  avatarComplete: {
    background: theme.palette.primary.main,
  },

  avatarPending: {
    background: theme.palette.primary.secondary,
  },

  avatarRecommendation: {
    background: 'transparent',
    color: '#333',
  },
} )