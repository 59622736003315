import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js'
import awsSDK from 'aws-sdk'
import _ from 'lodash'
import * as detectEnv from '../../lib/detectEnv'
import sigV4Client from './sigV4Client'


export default class {
  awsConfig = {}

  constructor(awsConfig) {
    this.awsConfig = awsConfig
  }

  //
  // Cognito utils

  getUserPool = () => {
    return new CognitoUserPool({
      UserPoolId: this.awsConfig.cognito.userPoolId,
      ClientId: this.awsConfig.cognito.clientId,
    })
  }

  getCurrentUser = () => {
    return this.getUserPool().getCurrentUser()
  }

  getUserToken = (currentUser) => {
    return new Promise((resolve, reject) => {
      currentUser.getSession(function(err, session) {
        if (err) {
          reject(err)
          return
        }
        resolve(session.getIdToken().getJwtToken())
      })
    })
  }

  getAwsCredentials = (userToken) => {
    const authenticator = `cognito-idp.${this.awsConfig.cognito.region}.amazonaws.com/${this.awsConfig.cognito.userPoolId}`
    awsSDK.config.update({ region: this.awsConfig.cognito.region })
    awsSDK.config.credentials = new awsSDK.CognitoIdentityCredentials({
      IdentityPoolId: this.awsConfig.cognito.identityPoolId,
      Logins: {
        [authenticator]: userToken,
      },
    })

    return awsSDK.config.credentials.getPromise()
  }

  authUser = async() => {
    if (
      awsSDK.config.credentials &&
      Date.now() < awsSDK.config.credentials.expireTime - 60000
    ) {
      return true
    }

    const currentUser = this.getCurrentUser()
    if (null === currentUser) {
      return false
    }

    const userToken = await this.getUserToken(currentUser)
    await this.getAwsCredentials(userToken)

    return true
  }

  //
  // Login and Set a New Password for a user create with the AdminCreateUser API
  // https://docs.this.amazon.com/cognito/latest/developerguide/using-amazon-cognito-identity-user-pools-javascript-example-authenticating-admin-created-user.html

  loginUser = (username, password, newPassword) => {
    const user = new CognitoUser({ Username: username, Pool: this.getUserPool() }),
      authenticationData = { Username: username, Password: password },
      authenticationDetails = new AuthenticationDetails(authenticationData)

    return new Promise((resolve, reject) =>
      user.authenticateUser(authenticationDetails, {
        onSuccess: (result) => resolve(result),
        onFailure: (err) => reject(err),

        // User was signed up by an admin and must provide new
        // password and required attributes, if any, to complete
        // authentication.

        newPasswordRequired: (userAttributes, requiredAttributes) => {
          if (_.isString(newPassword) && newPassword.length) {
            user.completeNewPasswordChallenge(
              newPassword, {}, {
                onSuccess: (result) => resolve(result),
                onFailure: (err) => reject(err),
              },
            )
          }
          else {
            reject({
              code: 'NewPasswordRequired',
              userAttributes,
              requiredAttributes,
              username,
              password,
            })
          }
        },
      }),
    )
  }

  logoutUser = () => {
    const currentUser = this.getCurrentUser()
    if (null !== currentUser) {
      currentUser.signOut()
    }

    if (awsSDK.config.credentials) {
      awsSDK.config.credentials.clearCachedId()
      awsSDK.config.credentials = new awsSDK.CognitoIdentityCredentials({})
    }
  }

  //
  // API utils

  // There's a bug in sigV4Client that cause the signature to be miscalculated when including opening parentheses
  // in the query string (such as when doing a GraphQL query that requires parameters). Until this is resolved,
  // run all queries through POST
  // graphqlQuery = ( query ) =>
  //   this.apiCall( {
  //     path: '/v1/graphql',
  //     queryParams: {
  //       query,
  //     },
  //   } )

  graphqlQuery = (query) =>
    this.graphqlMutation(query)

  graphqlMutation = (query) =>
    this.apiCall({
      region: this.awsConfig.graphqlAPI.region,
      endpoint: this.awsConfig.graphqlAPI.endpoint,
      path: '/v1/graphql',
      method: 'POST',
      body: {
        query,
      },
    })

  sendConfirmationEmail = (params) => {
    let path = '/v1/sendConfirmationEmail'

    return this.apiCall({
      region: this.awsConfig.graphqlAPI.region,
      endpoint: this.awsConfig.graphqlAPI.endpoint,
      path,
      method: 'POST',
      body: {
        ...params,
      },
    })
  }

  sendConfirmationSMS = (params) => {
    let path = '/v1/sendConfirmationSMS'

    return this.apiCall({
      region: this.awsConfig.graphqlAPI.region,
      endpoint: this.awsConfig.graphqlAPI.endpoint,
      path,
      method: 'POST',
      body: {
        ...params,
      },
    })
  }

  confirmEmailAddress = (emailConfirmationId) => {
    let path = '/v1/confirmEmailAddress'

    return this.apiCall({
      region: this.awsConfig.graphqlAPI.region,
      endpoint: this.awsConfig.graphqlAPI.endpoint,
      path,
      method: 'GET',
      queryParams: {
        id: emailConfirmationId,
      },
    })
  }

  confirmPhoneNumber = (phoneNumberConfirmationId) => {
    let path = '/v1/confirmPhoneNumber'

    return this.apiCall({
      region: this.awsConfig.graphqlAPI.region,
      endpoint: this.awsConfig.graphqlAPI.endpoint,
      path,
      method: 'GET',
      queryParams: {
        id: phoneNumberConfirmationId,
      },
    })
  }

  getStripeCustomerData = () => {
    let path = '/v1/getStripeCustomerData',
      queryParams = {}

    if (!detectEnv.isProduction()) {
      queryParams = {
        testData: true,
      }
    }

    return this.apiCall({
      region: this.awsConfig.graphqlAPI.region,
      endpoint: this.awsConfig.graphqlAPI.endpoint,
      path,
      method: 'GET',
      queryParams,
    })
  }

  getStripeProductsList = () => {
    let path = '/v1/getStripeProductsList',
      queryParams = {}

    if (!detectEnv.isProduction()) {
      queryParams = {
        testData: true,
      }
    }

    return this.apiCall({
      region: this.awsConfig.graphqlAPI.region,
      endpoint: this.awsConfig.graphqlAPI.endpoint,
      path,
      method: 'GET',
      queryParams,
    })
  }

  subscribeToStripePlan = (params) => {
    let path = '/v1/subscribeToStripePlan',
      queryParams = {}

    if (!detectEnv.isProduction()) {
      queryParams = {
        testData: true,
      }
    }

    return this.apiCall({
      region: this.awsConfig.graphqlAPI.region,
      endpoint: this.awsConfig.graphqlAPI.endpoint,
      path,
      method: 'POST',
      queryParams,
      body: {
        ...params,
      },
    })
  }

  unsubscribeFromStripePlan = () => {
    let path = '/v1/unsubscribeFromStripePlan',
      queryParams = {}

    if (!detectEnv.isProduction()) {
      queryParams = {
        testData: true,
      }
    }

    return this.apiCall({
      region: this.awsConfig.graphqlAPI.region,
      endpoint: this.awsConfig.graphqlAPI.endpoint,
      path,
      method: 'POST',
      queryParams,
    })
  }

  prepareOrderDocument = (params) =>
    this.apiCall({
      region: this.awsConfig.graphqlAPI.region,
      endpoint: this.awsConfig.graphqlAPI.endpoint,
      path: '/v1/prepareOrderDocument',
      method: 'POST',
      body: {
        ...params,
      },
    })

  event = (params) => {
    let path = '/v1/go',
      queryParams = {}

    if (!detectEnv.isProduction()) {
      queryParams = {
        testData: true,
      }
    }

    return this.apiCall({
      region: this.awsConfig.eventAPI.region,
      endpoint: this.awsConfig.eventAPI.endpoint,
      path,
      method: 'POST',
      queryParams,
      body: {
        ...params,
      },
    })
  }

  apiCall = async({
    region,
    endpoint,
    path = '/',
    method = 'GET',
    headers = {},
    queryParams = {},
    body,
  }) => {
    const authUser = await this.authUser()
    if (!authUser) {
      throw new Error('User is not authenticated')
    }

    const signedRequest = sigV4Client.newClient({
      accessKey: awsSDK.config.credentials.accessKeyId,
      secretKey: awsSDK.config.credentials.secretAccessKey,
      sessionToken: awsSDK.config.credentials.sessionToken,
      region,
      endpoint,
    }).signRequest({
      method,
      path,
      headers,
      queryParams,
      body,
    })

    body = body ? JSON.stringify(body) : body
    headers = signedRequest.headers

    const result = await fetch(signedRequest.url, {
      method,
      headers,
      body,
    })

    if (result.status !== 200) {
      throw new Error(await result.text())
    }

    const jsonResult = await result.json(),
      errors = _.get(jsonResult, 'errors', [])

    if (errors.length) {
      throw new Error(_.get(errors, '0.message', errors.toString()))
    }

    return jsonResult
  }
}
