export default ( aws ) => ( {
  cognito: {
    auth: aws.authUser,

    getCurrentUser: aws.getCurrentUser,

    login: ( email, password, newPassword = null ) =>
      aws.loginUser( email, password, newPassword ),

    logout: () => aws.logoutUser(),
  },
} )
