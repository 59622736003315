import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import FusionReactor from '../../../lib/FusionReactor'


const styles = ( theme ) => ( {
  typography: {
    textAlign: 'center',
    '& h2, & h3': {
      marginBottom: '1rem',
    },
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '1rem',
  },
  button: {
    margin: `${theme.spacing.unit}px`,
  },
} )

class Component extends React.Component {
  render() {
    const {
      classes,
      t,
      onNo,
      onYes,
    } = this.props

    return [
      <div key="typography" className={ classes.typography }>
        <Typography variant="title" gutterBottom>
          { t( 'LoginScreen.YesNoScreen.Title' ) }
        </Typography>

        <Typography variant="subheading" gutterBottom>
          { t( 'LoginScreen.YesNoScreen.FirstParagraph' ) }
        </Typography>

        <Typography variant="subheading" gutterBottom>
          { t( 'LoginScreen.YesNoScreen.SecondParagraph' ) }
        </Typography>
      </div>,

      <div key="buttons" className={ classes.buttons }>
        <Button variant="raised" color="primary" className={ classes.button } onClick={ onNo }>
          { t( 'LoginScreen.YesNoScreen.NoButton' ) }
        </Button>

        <Button variant="raised" color="primary" className={ classes.button } onClick={ onYes }>
          { t( 'LoginScreen.YesNoScreen.YesButton' ) }
        </Button>
      </div>,
    ]
  }
}

export default FusionReactor( withStyles( styles )( Component ) )
