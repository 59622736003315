import React from 'react'
import { Redirect } from 'react-router-dom'
import FusionReactor from '../../lib/FusionReactor'
import * as redirectReactions from '../../providers/redirect/reactions'


class Component extends React.Component {
  componentDidUpdate() {
    const {
      reactWith,
      state: {
        redirectProvider: {
          redirectTo,
        },
      },
    } = this.props

    if ( '' !== redirectTo ) {
      reactWith( redirectReactions.REINITIALIZE_STATE )
    }
  }

  render() {
    const {
      children,
      state: {
        redirectProvider: {
          redirectTo,
        },
      },
    } = this.props

    if ( '' !== redirectTo ) {
      return <Redirect to={ redirectTo }/>
    }

    return children
  }
}

export default FusionReactor( Component, { withIntl: false } )
