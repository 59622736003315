import accountScreen from '../screens/account/reactions'
import agreementsScreen from '../screens/agreements/reactions'
import authRigging from '../rigging/auth/reactions'
import dashboardRigging from '../rigging/dashboard/reactions'
import dataProvider from '../providers/data/reactions'
import documentsScreen from '../screens/documents/reactions'
import emailConfirmScreen from '../screens/email_confirm/reactions'
import emailRequiredScreen from '../screens/email_required/reactions'
import introScreen from '../screens/intro/reactions'
import localesRigging from '../rigging/locales/reactions'
import loginScreen from '../screens/login/reactions'
import notificationsScreen from '../screens/notifications/reactions'
import optimizeScreen from '../screens/optimize/reactions'
import orderInstructionsScreen from '../screens/order_instructions/reactions'
import orderRecommendationsScreen from '../screens/order_recommendations/reactions'
import orderScreen from '../screens/order/reactions'
import preferencesScreen from '../screens/preferences/reactions'
import redirectProvider from '../providers/redirect/reactions'
import subscriptionScreen from '../screens/subscription/reactions'


export default {
  dataProvider,
  redirectProvider,

  localesRigging,
  authRigging,
  dashboardRigging,

  loginScreen,
  emailRequiredScreen,
  emailConfirmScreen,
  agreementsScreen,
  introScreen,
  subscriptionScreen,
  accountScreen,
  preferencesScreen,
  documentsScreen,
  notificationsScreen,
  optimizeScreen,
  orderScreen,
  orderInstructionsScreen,
  orderRecommendationsScreen,
}
