export default ( theme ) => ( {
  prevButton: {
    marginLeft: -12,
  },
  nextButton: {
    marginRight: -12,
  },

  emailBlurb: {
    padding: `${theme.spacing.unit}px`,
  },

  confirmBlurb: {
    padding: `${theme.spacing.unit * 2}px`,
    textAlign: 'center',
  },

  formRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginTop: `${theme.spacing.unit}px`,
    padding: `${theme.spacing.unit}px`,
    paddingRight: '0',
  },

  formControl: {
    display: 'flex',
    flex: '1',
    order: '1',
  },

  resendButton: {
    margin: `${theme.spacing.unit * 2}px`,
  },
} )
