import CssBaseline from '@material-ui/core/CssBaseline'
import { createGenerateClassName, createMuiTheme, jssPreset, MuiThemeProvider } from '@material-ui/core/styles'
import * as Sentry from '@sentry/browser'
import { create } from 'jss'
import _ from 'lodash'
import React from 'react'
import { addLocaleData } from 'react-intl'
import en from 'react-intl/locale-data/en'
import se from 'react-intl/locale-data/se'
import JssProvider from 'react-jss/lib/JssProvider'
import 'react-rangeslider/lib/index.css'
import { Provider as Redux } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { StripeProvider } from 'react-stripe-elements'
import 'react-vis/dist/style.css'
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import locales from './config/locales'
import middleware, { sagaMiddleware } from './config/middleware'
import reducers from './config/reducers'
import routes from './config/routes'
import sagas from './config/sagas'
import stripePublicKey from './config/stripe'
import * as detectEnv from './lib/detectEnv'
import FusionReactor from './lib/FusionReactor'
import ErrorBoundaryRigging from './rigging/error_boundary/ErrorBoundary'
import LocalesRigging from './rigging/locales/Locales'
import RedirectRigging from './rigging/redirect/Redirect'


//
// Canonical HTTPs redirect

if ( detectEnv.isProduction() || detectEnv.isStaging() ) {
  // Canonical HTTPs redirect
  if ( 'https:' !== window.location.protocol ) {
    window.location.href = window.location.href.replace( 'http://', 'https://' )
  }
}

//
// Sentry.io error logging

if ( detectEnv.isProduction() ) {
  Sentry.init( {
    dsn: 'https://33293bec4902432f93e01542a2c247c0@sentry.io/1202264',
    debug: false,
  } )
} else if ( detectEnv.isStaging() ) {
  Sentry.init( {
    dsn: 'https://d8df1e45cafb457a8756a2e840f9025a@sentry.io/1318560',
    debug: true,
  } )
}

Sentry.init( {
  dsn: 'https://d8df1e45cafb457a8756a2e840f9025a@sentry.io/1318560',
  debug: true,
} )

//
// Theme

const muiTheme = createMuiTheme( {
    palette: {
      primary: {
        main: '#2e5aff',
        light: '#7987ff',
        dark: '#0031cb',
        contrastText: '#ffffff',
      },
      secondary: {
        main: '#2e5aff',
        light: '#7987ff',
        dark: '#0031cb',
        contrastText: '#ffffff',
      },
    },
  } ),

  // Bootstrap with plugins and Material UI
  jss = create( {
    plugins: [
      ...jssPreset().plugins,
    ],
  } ),

  generateClassName = createGenerateClassName(),

  // Global styles
  style = {
    '@global': {
      'html, body': {
        margin: '0',
        padding: '0',
      },
      'html': {
        height: '100%',
      },
      'body': {
        display: 'flex',
        flex: '1',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        minHeight: '100%',
      },
      '#root, .App, .Rigging, .Screen': {
        display: 'flex',
        flex: '1',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: '100%',
        height: '100%',
      },
      '.App': {
        background: '#fff',
      },
      'a': {
        color: '#2E5AFF',
        fontWeight: 'bold',
        textDecoration: 'none',
      },
    },
  }

// Compile global styles and attach sheet to DOM
jss.createStyleSheet( style ).attach()

//
// Setup the store

const store = createStore(
  FusionReactor.combineReducers( reducers ),
  composeWithDevTools( applyMiddleware( ...middleware ) ),
)

FusionReactor.initializeSagas( sagaMiddleware, sagas )

//
// Setup locales

addLocaleData( en )
addLocaleData( se )

const flattenNestedLocale = ( locale ) => {
    return _.transform( locale, ( accumulator, value, key ) => {
      if ( _.isObject( value ) ) {
        _.each(
          flattenNestedLocale( value ),
          ( v, k ) => ( accumulator[ `${key}.${k}` ] = v ),
        )
      } else {
        accumulator[ key ] = value
      }
    } )
  },
  messages = _.transform(
    locales,
    ( acc, locale, index ) => acc[ index ] = flattenNestedLocale( locale ),
  )

//
// Bootstrap the app

class App extends React.Component {
  render() {
    return (
      <div className="App">
        <CssBaseline/>{ ' ' }{ /* Similar to normalize.css but for the material-ui package */ }
        <Redux store={ store }>
          <Router>
            <RedirectRigging>{ ' ' }{ /* Provides redirect functionality through Redux */ }
              <LocalesRigging
                messages={ messages }>{ ' ' }{ /* It's a FusionReactor HOC so it needs to be under both Redux and Router */ }
                <MuiThemeProvider theme={ muiTheme }>
                  <JssProvider jss={ jss } generateClassName={ generateClassName }>
                    <StripeProvider apiKey={ stripePublicKey }>
                      <ErrorBoundaryRigging>
                        <Switch>{ ' ' }{ /* Switch has to be the immediate parent for Route, for it to work */ }
                          { routes.map( ( route, index ) => (
                            <Route key={ `route${index}` } exact={ true } { ...route } />
                          ) ) }
                        </Switch>
                      </ErrorBoundaryRigging>
                    </StripeProvider>
                  </JssProvider>
                </MuiThemeProvider>
              </LocalesRigging>
            </RedirectRigging>
          </Router>
        </Redux>
      </div>
    )
  }
}

export default App
