import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'
import config from '../../../config/brandsign'

const url = config.url
const urlParams = `?domain=${encodeURIComponent( config.sign.domain )}&accountId=${encodeURIComponent( config.sign.accountId )}`
const urlAuthParams = `?domain=${encodeURIComponent( config.auth.domain )}&accountId=${encodeURIComponent( config.auth.accountId )}`

export default () => ( {
  brandsign: {
    authenticate: ( nationalId = '', sameDevice = false ) => {
      const requestParams = {
        NationalIdCountry: 'SE',
        LanguageCode: 'sv-SE',
        // LanguageCode: 'en-GB',
        RoleCodes: [ 'NaturalPerson' ],
      }

      if ( sameDevice ) {
        requestParams.SameDevice = true
      } else {
        requestParams.NationalId = nationalId
      }

      return new Promise( ( resolve, reject ) =>
        axios.post(
          `${url}/Authentication/Authenticate${urlAuthParams}`, requestParams )
          .then( ( response ) => {
            resolve( response.data )
          } )
          .catch( ( error ) => {
            reject( _.get( error, 'response.data', error ) )
          } ),
      )
    },

    authenticationStatus: ( authenticationid ) =>
      new Promise( ( resolve, reject ) =>
        axios.get(
          `${url}/Authentication/AuthenticationStatus${urlAuthParams}&authenticationid=${authenticationid}`,
        )
          .then( ( response ) => {
            resolve( response.data )
          } )
          .catch( ( error ) => {
            reject( _.get( error, 'response.data', error ) )
          } ),
      ),

    signDocument: ( document, insuranceSourceSSN, userSSN ) => {
      const requestParams = {
        LoginRequired: false,
        DocumentsToSign: [
          {
            FileName: `Fondorder_${insuranceSourceSSN}_${moment().format( 'YYYY-MM-DD_HH-mm-ss' )}.pdf`,
            AttachReceiverSeal: true,
            RequestedSignatories: [
              {
                RequestedRoles: [
                  'NaturalPerson',
                ],
                RequestedNationalId: userSSN,
              },
            ],
            FileContent: document,
          },
        ],
      }

      return new Promise( ( resolve, reject ) =>
        axios.post(
          `${url}/SigningTask${urlParams}`,
          requestParams,
        )
          .then( ( response ) => {
            resolve( response.data )
          } )
          .catch( ( error ) => {
            reject( _.get( error, 'response.data', error ) )
          } ),
      )
    },

    signing_status: ( signingtaskGuid ) =>
      new Promise( ( resolve, reject ) =>
        axios.get(
          `${url}/SigningTaskStatus${urlParams}&signingtaskGuid=${signingtaskGuid}`,
        )
          .then( ( response ) => {
            resolve( response.data )
          } )
          .catch( ( error ) => {
            reject( _.get( error, 'response.data', error ) )
          } ),
      ),
  },
} )
