import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import FusionReactor from '../../../lib/FusionReactor'


const styles = ( theme ) => ( {
  typography: {
    textAlign: 'center',
    '& h2, & h3': {
      marginBottom: '1rem',
    },
  },
  textField: {
    '& input': {
      textAlign: 'center',
      '&::placeholder': {
        textAlign: 'center',
      },
    },
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '1rem',
  },
  button: {
    margin: `${theme.spacing.unit}px`,
  },
} )

class Component extends React.Component {
  render() {
    const {
      classes,
      t,
      onChangeSSNField,
      ssnField,
      onCancel,
      onYes,
      disableYes,
    } = this.props

    return [
      <div key="typography" className={ classes.typography }>
        <Typography variant="title" gutterBottom>
          { t( 'LoginScreen.SSNScreen.Title' ) }
        </Typography>

        <Typography variant="subheading" gutterBottom>
          { t( 'LoginScreen.SSNScreen.Paragraph' ) }
        </Typography>
      </div>,

      <TextField
        key="textfield"
        id="userSSN"
        type="number"
        placeholder={ t( 'LoginScreen.SSNScreen.Form.SSNFieldPlaceholder' ) }
        margin="normal"
        fullWidth
        required
        value={ ssnField }
        onChange={ onChangeSSNField }
        className={ classes.textField }
      />,

      <div key="buttons" className={ classes.buttons }>
        <Button
          variant="raised"
          color="primary"
          onClick={ onCancel }
          className={ classes.button }
        >
          { t( 'LoginScreen.SSNScreen.Form.CancelButton' ) }
        </Button>

        <Button
          variant="raised"
          color="primary"
          disabled={ disableYes }
          onClick={ onYes }
          className={ classes.button }
        >
          { t( 'LoginScreen.SSNScreen.Form.YesButton' ) }
        </Button>
      </div>,
    ]
  }
}

export default FusionReactor( withStyles( styles )( Component ) )
