export default (theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    alignItems: 'center',
    marginBottom: '1rem',
    padding: `${theme.spacing.unit * 3}px ${theme.spacing.unit}px`,
  },

  avatar: {
    marginRight: `${theme.spacing.unit * 2}px`,
    marginLeft: `${theme.spacing.unit}px`,
    background: '#FFCC6F',
  },

  heading: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    color: '#979797',
  },

  text: {
    marginBottom: '1rem',
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    color: '#979797',
    wordBreak: 'break-all',
  },

  button: {
    margin: '1rem',
  },

  table: {
    marginBottom: '3rem',
  },

  tableHead: {
    background: '#F2F2F2',
    '& tr': {
      height: 'auto',
    },
    '& th': {
      borderBottom: '0',
      padding: `${theme.spacing.unit * 2}px`,
      fontSize: '12px',
    },
  },

  tableBody: {
    '& tr': {
      height: 'auto',
    },
    '& td': {
      borderBottomColor: '#EAEAEA',
      padding: `${theme.spacing.unit * 2}px`,
    },
  },

  fundChanged: {
    '& td': {
      textDecoration: 'line-through',
    },
  },
})
