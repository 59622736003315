export default {
  cognito: {
    userPool: {
      region: 'eu-west-1',
      userPoolId: 'eu-west-1_tYgmImFFf',
      clientId: '53l16a1h0vbtdmr4h40jdkobeb',
      identityPoolId: 'eu-west-1:1c9b6434-3f78-48db-94a0-3e938aad1c74',
    },
  },
  clientAPI: {
    endpoint: process.env.REACT_APP_CLIENT_API_ENDPOINT,
    region: process.env.REACT_APP_CLIENT_API_REGION,
  },
  eventAPI: {
    endpoint: process.env.REACT_APP_EVENT_API_ENDPOINT,
    region: process.env.REACT_APP_EVENT_API_REGION,
  },
}
