export default ( theme ) => ( {
  hero: {
    // border: '5px solid red',
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',

    [ theme.breakpoints.up( 600 ) ]: {
      background: '#f6f6f6',
      backgroundImage: 'url(\'/login/hero_header.png\')',
      backgroundSize: 'auto auto',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'top center',
    },

    [ theme.breakpoints.up( 'lg' ) ]: {
      backgroundSize: '100% auto',
    },
  },

  heroContent: {
    // border: '5px solid blue',
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignSelf: 'center',
    width: '100%',
    height: '100%',

    [ theme.breakpoints.up( 600 ) ]: {
      margin: '250px auto 0 auto',
      width: '414px',
    },
  },

  card: {
    // border: '5px solid green',
    height: '736px',

    [ theme.breakpoints.down( 600 ) ]: {
      display: 'flex',
      flex: '1',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      width: '100%',
      height: '100%',

      boxShadow: 'none',
      borderRadius: '0',
    },
  },

  cardContent: {
    // border: '5px solid yellow',
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',

    padding: '0 !important',
  },
} )