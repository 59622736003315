import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CheckIcon from '@material-ui/icons/Check'
import NotificationsIcon from '@material-ui/icons/NotificationsActive'
import React from 'react'
import { Link } from 'react-router-dom'
import FusionReactor from '../../lib/FusionReactor'
import Dashboard from '../../rigging/dashboard/Dashboard'
import styles from './styles'


class Component extends React.Component {
  componentDidMount() {
    window.analytics.page( 'Notifications' )
  }

  render() {
    const {
      classes,
      t,
    } = this.props

    let Notifications = (
      <div className={ classes.noNotifications }>
        <NotificationsIcon className={ classes.noNotificationsIcon }/>

        <Typography className={ classes.noNotificationsText }>
          { t( 'NotificationsScreen.NoNotifications' ) }
        </Typography>
      </div>
    )

    if ( true ) {
      Notifications = (
        <div className={ classes.notificationsGroup }>
          <Typography className={ classes.notificationsGroupHeading }>{ t( 'NotificationsScreen.Today' ) }</Typography>

          <Card className={ classes.notification }>
            <CardHeader
              avatar={
                <Avatar className={ classes.avatar }>
                  <CheckIcon/>
                </Avatar>
              }
              title={ t( 'NotificationsScreen.Order', { number: 12345 } ) }
              subheader={ t( 'NotificationsScreen.OrderSuccess' ) }
              className={ classes.notificationHeader }
            />
            <CardContent className={ classes.notificationContent }>
              <Button color="primary" component={ Link } to="/order">
                { t( 'NotificationsScreen.SeeOrderButton' ) }
              </Button>
            </CardContent>
          </Card>
        </div>
      )
    }

    return (
      <Dashboard
        title={ t( 'NotificationsScreen.Title' ) }
        left="drawer"
        mainGrayUnpadded
      >
        <div className="Screen NotificationsScreen">
          { Notifications }
        </div>
      </Dashboard>
    )
  }
}

export default FusionReactor( withStyles( styles )( Component ) )
