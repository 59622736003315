export default ( theme ) => ( {
  nextButton: {
    marginRight: -12,
  },

  formRow: {
    marginTop: `${theme.spacing.unit}px`,
    padding: `${theme.spacing.unit}px`,
  },

  rowHeading: {
    opacity: '.6',
  },

  listItem: {
    paddingLeft: '0',
    paddingRight: '0',
  },
} )