import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import FusionReactor from '../../../lib/FusionReactor'
import styles from '../styles'


class Component extends React.Component {
  render() {
    const {
      classes,
    } = this.props

    return (
      <div className={ classes.fullScreen }>
        <CircularProgress className={ classes.preparingIndicator } size={ 50 }/>

        <Typography className={ classes.fullScreenText }>
          <FormattedMessage
            id="OrderScreen.PreparingScreen.Text"
            values={ { br: <br/> } }
          />
        </Typography>
      </div>
    )
  }
}

export default FusionReactor( withStyles( styles )( Component ) )
