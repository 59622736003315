import * as dataReactions from '../../providers/data/reactions'
import * as reactions from './reactions'
import * as redirectReactions from '../../providers/redirect/reactions'
import _ from 'lodash'
import AboutScreen from './screens/About'
import Button from '@material-ui/core/Button'
import ConditionsScreen from './screens/Conditions'
import Dashboard from '../../rigging/dashboard/Dashboard'
import FusionReactor from '../../lib/FusionReactor'
import Hero from '../../rigging/hero/Hero'
import moment from 'moment'
import React from 'react'
import styles from './styles'
import { withStyles } from '@material-ui/core/styles'


// TODO: uncomment imports when re-enabling the first Agreements screen
// import IconButton from '@material-ui/core/IconButton'
// import ArrowBackIcon from '@material-ui/icons/ArrowBack'


class Component extends React.Component {

  //
  // Lifecycle

  componentDidMount() {
    const { reactWith } = this.props
    reactWith(dataReactions.REINITIALIZE_UPDATE_USER_STATE, { key: 'agreements' })
    reactWith(reactions.REINITIALIZE_STATE)
    window.analytics.page('Agreements')
  }

  componentDidUpdate() {
    const {
      reactWith,
      state: {
        dataProvider: {
          updateUserRequests: {
            agreements: {
              hasSucceeded: updateUserHasSucceeded,
            },
          },
        },
      },
    } = this.props

    if (updateUserHasSucceeded) {
      reactWith(redirectReactions.REDIRECT_TO_INTRO)
    }
  }

  //
  // Dashboard

  dashboardInFlight = () => {
    const {
      state: {
        dataProvider: {
          updateUserRequests: {
            agreements: {
              inFlight: updateUserInFlight,
              hasSucceeded: updateUserHasSucceeded,
            },
          },
        },
      },
    } = this.props

    return updateUserInFlight || updateUserHasSucceeded
  }


  //
  // Controlled fields

  changeCitizenshipField = (e) => {
    const { reactWith } = this.props
    reactWith(reactions.CHANGE_FORM_FIELD, {
      field: 'citizenship',
      value: e.target.value,
    })
  }

  changePaysForeignTaxField = (e) => {
    const { reactWith } = this.props
    reactWith(reactions.CHANGE_FORM_FIELD, {
      field: 'paysForeignTax',
      value: e.target.value,
    })

    if ('Yes' === e.target.value) {
      this.openPaysForeignTaxAlert()
    }
  }

  changePaysUSTaxField = (e) => {
    const { reactWith } = this.props
    reactWith(reactions.CHANGE_FORM_FIELD, {
      field: 'paysUSTax',
      value: e.target.value,
    })

    if ('Yes' === e.target.value) {
      this.openPaysUSTaxAlert()
    }
  }

  changeIsPEPField = (e) => {
    const { reactWith } = this.props
    reactWith(reactions.CHANGE_FORM_FIELD, {
      field: 'isPEP',
      value: e.target.value,
    })

    if ('Yes' === e.target.value) {
      this.openIsPEPAlert()
    }
  }

  changeAgreesToDataProcessingField = (e) => {
    const { reactWith } = this.props
    reactWith(reactions.CHANGE_FORM_FIELD, {
      field: 'agrees_to_data_processing',
      value: e.target.checked,
    })
  }

  changeAgreesToContactField = (e) => {
    const { reactWith } = this.props
    reactWith(reactions.CHANGE_FORM_FIELD, {
      field: 'agrees_to_contact',
      value: e.target.checked,
    })
  }

  changeAgreesToTermsField = (e) => {
    const { reactWith } = this.props
    reactWith(reactions.CHANGE_FORM_FIELD, {
      field: 'agrees_to_terms',
      value: e.target.checked,
    })
  }


  //
  // Dropdowns, screens, popups and alerts

  openCitizenshipSelect = () => {
    const { reactWith } = this.props
    reactWith(reactions.TOGGLE_DROPDOWN, {
      dropdown: 'citizenship',
    })
  }

  closeCitizenshipSelect = () => {
    const { reactWith } = this.props
    reactWith(reactions.TOGGLE_DROPDOWN)
  }

  showAboutScreen = () => {
    const { reactWith } = this.props
    reactWith(reactions.TOGGLE_SCREEN, {
      screen: 'about',
    })
  }

  showConditionsScreen = () => {
    const { reactWith } = this.props
    reactWith(reactions.TOGGLE_SCREEN, {
      screen: 'conditions',
    })
  }

  openCitizenshipPopup = () => {
    const { reactWith } = this.props
    reactWith(reactions.TOGGLE_POPUP, {
      popup: 'citizenship',
    })
  }

  closeCitizenshipPopup = () => {
    const { reactWith } = this.props
    reactWith(reactions.TOGGLE_POPUP)
  }

  openPaysForeignTaxPopup = () => {
    const { reactWith } = this.props
    reactWith(reactions.TOGGLE_POPUP, {
      popup: 'paysForeignTax',
    })
  }

  closePaysForeignTaxPopup = () => {
    const { reactWith } = this.props
    reactWith(reactions.TOGGLE_POPUP)
  }

  openPaysUSTaxPopup = () => {
    const { reactWith } = this.props
    reactWith(reactions.TOGGLE_POPUP, {
      popup: 'paysUSTax',
    })
  }

  closePaysUSTaxPopup = () => {
    const { reactWith } = this.props
    reactWith(reactions.TOGGLE_POPUP)
  }

  openIsPEPPopup = () => {
    const { reactWith } = this.props
    reactWith(reactions.TOGGLE_POPUP, {
      popup: 'isPEP',
    })
  }

  closeIsPEPPopup = () => {
    const { reactWith } = this.props
    reactWith(reactions.TOGGLE_POPUP)
  }

  openPaysForeignTaxAlert = () => {
    const { reactWith } = this.props
    reactWith(reactions.TOGGLE_ALERT, {
      alert: 'paysForeignTax',
    })
  }

  closePaysForeignTaxAlert = () => {
    const { reactWith } = this.props
    reactWith(reactions.TOGGLE_ALERT)
  }

  openPaysUSTaxAlert = () => {
    const { reactWith } = this.props
    reactWith(reactions.TOGGLE_ALERT, {
      alert: 'paysUSTax',
    })
  }

  closePaysUSTaxAlert = () => {
    const { reactWith } = this.props
    reactWith(reactions.TOGGLE_ALERT)
  }

  openIsPEPAlert = () => {
    const { reactWith } = this.props
    reactWith(reactions.TOGGLE_ALERT, {
      alert: 'isPEP',
    })
  }

  closeIsPEPAlert = () => {
    const { reactWith } = this.props
    reactWith(reactions.TOGGLE_ALERT)
  }

  //
  // Form

  firstFormIsValid = () => {
    // TODO: uncomment validation checks when re-enabling the first Agreements screen
    // const {
    //   state: {
    //     agreementsScreen: {
    //       formFields: {
    //         citizenship,
    //         paysForeignTax,
    //         paysUSTax,
    //         isPEP,
    //       },
    //     },
    //   },
    // } = this.props
    //
    // return citizenship.length &&
    //   paysForeignTax.length &&
    //   paysUSTax.length &&
    //   isPEP.length &&
    //   'Yes' !== paysForeignTax &&
    //   'Yes' !== paysUSTax &&
    //   'Yes' !== isPEP

    return true
  }

  secondFormIsValid = () => {
    const {
      state: {
        agreementsScreen: {
          formFields: {
            agrees_to_data_processing,
            agrees_to_contact,
            agrees_to_terms,
          },
        },
      },
    } = this.props

    return agrees_to_data_processing &&
      agrees_to_contact &&
      agrees_to_terms
  }

  updateUserAgreements = () => {
    // noinspection UnnecessaryLocalVariableJS
    const {
      reactWith,
      state: {
        dataProvider: {
          user,
        },
        agreementsScreen: {
          formFields,
        },
      },
    } = this.props,
      updateParams = formFields

    // TODO: uncomment bool conversion when re-enabling the first Agreements screen
    // const convertToBool = [ 'paysForeignTax', 'paysUSTax', 'isPEP' ]
    // for ( let c in convertToBool ) {
    //   const p = convertToBool[ c ]
    //   updateParams[ p ] = 'Yes' === updateParams[ p ]
    // }

    reactWith(dataReactions.UPDATE_USER, {
      key: 'agreements',
      params: {
        id: user.id,
        ...updateParams,
        collected_agreements_at: moment().format(),
      },
    })
  }

  //
  // Render

  render() {
    const {
      classes,
      t,
      state: {
        dataProvider: {
          user,
        },
        agreementsScreen: {
          formFields,
          dropdowns: {
            citizenship: citizenshipSelectOpen,
          },
          screens: {
            about: showAboutScreen,
            conditions: showConditionsScreen,
          },
          popups: {
            citizenship: citizenshipPopupOpen,
            paysForeignTax: paysForeignTaxPopupOpen,
            paysUSTax: paysUSTaxPopupOpen,
            isPEP: isPEPPopupOpen,
          },
          alerts: {
            paysForeignTax: paysForeignTaxAlertOpen,
            paysUSTax: paysUSTaxAlertOpen,
            isPEP: isPEPAlertOpen,
          },
        },
      },
    } = this.props

    let dashboardTitle, screenProps, PrevButton, NextButton, Screen

    if (showAboutScreen) {
      dashboardTitle = t('AgreementsScreen.AboutScreen.Title')

      NextButton = (
        <Button
          className={ classes.nextButton }
          color="inherit"
          disabled={ !this.firstFormIsValid() }
          onClick={ this.showConditionsScreen }
        >
          { t( 'AgreementsScreen.AboutScreen.Next' ) }
        </Button>
      )

      screenProps = {
        // Form fields
        citizenshipField: formFields.citizenship,
        paysForeignTaxField: formFields.paysForeignTax,
        paysUSTaxField: formFields.paysUSTax,
        isPEPField: formFields.isPEP,

        onChangeCitizenshipField: this.changeCitizenshipField,
        onChangePaysForeignTaxField: this.changePaysForeignTaxField,
        onChangePaysUSTaxField: this.changePaysUSTaxField,
        onChangeIsPEPField: this.changeIsPEPField,

        // Dropdowns
        citizenshipSelectOpen: citizenshipSelectOpen,

        onOpenCitizenshipSelect: this.openCitizenshipSelect,
        onCloseCitizenshipSelect: this.closeCitizenshipSelect,

        // Popups
        citizenshipPopupOpen: citizenshipPopupOpen,
        paysForeignTaxPopupOpen: paysForeignTaxPopupOpen,
        paysUSTaxPopupOpen: paysUSTaxPopupOpen,
        isPEPPopupOpen: isPEPPopupOpen,

        onOpenCitizenshipPopup: this.openCitizenshipPopup,
        onOpenPaysForeignTaxPopup: this.openPaysForeignTaxPopup,
        onOpenPaysUSTaxPopup: this.openPaysUSTaxPopup,
        onOpenIsPEPPopup: this.openIsPEPPopup,

        onCloseCitizenshipPopup: this.closeCitizenshipPopup,
        onClosePaysForeignTaxPopup: this.closePaysForeignTaxPopup,
        onClosePaysUSTaxPopup: this.closePaysUSTaxPopup,
        onCloseIsPEPPopup: this.closeIsPEPPopup,

        // Alerts
        paysForeignTaxAlertOpen: paysForeignTaxAlertOpen,
        paysUSTaxAlertOpen: paysUSTaxAlertOpen,
        isPEPAlertOpen: isPEPAlertOpen,

        onClosePaysForeignTaxAlert: this.closePaysForeignTaxAlert,
        onClosePaysUSTaxAlert: this.closePaysUSTaxAlert,
        onCloseIsPEPAlert: this.closeIsPEPAlert,
      }

      Screen = <AboutScreen { ...screenProps } />
    }
    else if (showConditionsScreen) {
      dashboardTitle = t('AgreementsScreen.ConditionsScreen.Title')

      // TODO: uncomment PrevButton when re-enabling the first Agreements screen
      // PrevButton = (
      //   <IconButton
      //     className={ classes.prevButton }
      //     color="inherit"
      //     disabled={ this.dashboardInFlight() }
      //     onClick={ this.showAboutScreen }
      //   >
      //     <ArrowBackIcon/>
      //   </IconButton>
      // )

      NextButton = (
        <Button
          className={ classes.nextButton }
          color="inherit"
          disabled={ !this.firstFormIsValid() || !this.secondFormIsValid() || this.dashboardInFlight() }
          onClick={ this.updateUserAgreements }
        >
          { t( 'AgreementsScreen.ConditionsScreen.Next' ) }
        </Button>
      )

      screenProps = {
        companyName: _.get(user, 'CompanyGet.name', null),

        // Form fields
        agrees_to_data_processingField: formFields.agrees_to_data_processing,
        agrees_to_contactField: formFields.agrees_to_contact,
        agrees_to_termsField: formFields.agrees_to_terms,

        onChangeAgreesToDataProcessingField: this.changeAgreesToDataProcessingField,
        onChangeAgreesToContactField: this.changeAgreesToContactField,
        onChangeAgreesToTermsField: this.changeAgreesToTermsField,
      }

      Screen = <ConditionsScreen { ...screenProps } />
    }

    return (
      <Hero>
        <Dashboard
          title={ dashboardTitle }
          left={ PrevButton }
          right={ NextButton }
          inFlight={ this.dashboardInFlight() }
        >
          <div className="Screen AgreementsScreen">
            { Screen }
          </div>
        </Dashboard>
      </Hero>
    )
  }
}

export default FusionReactor(withStyles(styles)(Component))
