import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import { Elements } from 'react-stripe-elements'
import FusionReactor from '../../../lib/FusionReactor'
import CheckoutForm from './CheckoutForm'


const styles = ( theme ) => ( {} )

class Component extends React.Component {
  render() {
    return (
      <Elements>
        <CheckoutForm { ...this.props }/>
      </Elements>
    )
  }
}

export default FusionReactor( withStyles( styles )( Component ) )
