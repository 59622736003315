import { withStyles } from '@material-ui/core/styles'
import * as Sentry from '@sentry/browser'
import HmacSHA256 from 'crypto-js/hmac-sha256'
import _ from 'lodash'
import React from 'react'
import intercomConfig from '../../config/intercom'
import api from '../../lib/api/api'
import FusionReactor from '../../lib/FusionReactor'
import isTestSSN from '../../lib/isTestSSN'
import * as dataReactions from '../../providers/data/reactions'
import * as redirectReactions from '../../providers/redirect/reactions'
import * as authReactions from '../../rigging/auth/reactions'
import Hero from '../../rigging/hero/Hero'
import AlertDialog from '../../things/alert_dialog/AlertDialog'
import * as reactions from './reactions'
import BankIDScreen from './screens/BankID'
import SSNScreen from './screens/SSN'
import YesNoScreen from './screens/YesNo'
import styles from './styles'


class Component extends React.Component {
  componentDidMount() {
    const {
      reactWith,
      params: {
        authenticationId,
      },
    } = this.props

    api.cognito.logout()

    Sentry.configureScope( ( scope ) => {
      scope.setUser( { email: '' } )
    } )

    //
    // Error alerts might have a 'contact support' link which
    // should open an Intercom chat window

    window.intercomSettings = {
      custom_launcher_selector: '#intercom-launcher',
    }

    window.analytics.reset()
    window.analytics.identify( 'unknown', { email: '' }, {
      integrations: {
        Intercom: {
          user_hash: HmacSHA256( 'unknown', intercomConfig.secretKey, { asBytes: true } ).toString(), // eslint-disable-line
          hideDefaultLauncher: true,
        },
      },
    } )

    // Uncomment the bellow and comment the above if we don't want
    // Intercom support links, on this screen, in the future
    // if ( window.Intercom ) {
    //   window.Intercom( 'shutdown' )
    // }

    reactWith( dataReactions.REINITIALIZE_STATE )
    reactWith( authReactions.REINITIALIZE_STATE )
    reactWith( reactions.REINITIALIZE_STATE )

    if ( _.get( authenticationId, 'length', 0 ) ) {
      // BankID redirected with an authenticationId we can use to get the status,
      // so we fake a login success so componentWillUpdate can start polling for
      // the status
      reactWith( reactions.LOGIN_SUCCESS_STUB, { authenticationId } )
      reactWith( reactions.SHOW_BANKID_SCREEN )
    }
  }

  componentDidUpdate() {
    const {
      reactWith,
      params,
      state: {
        authRigging: {
          authRequest: {
            hasSucceeded: authHasSucceeded,
          },
        },
        loginScreen: {
          sameDeviceField,
          loginStatusInterval,
          loginRequest: {
            hasSucceeded: loginHasSucceeded,
            status: loginStatus,
            authenticationId,
            autoStartToken,
          },
          loginStatusRequest: {
            ranBefore: loginStatusRanBefore,
            hasSucceeded: loginStatusHasSucceeded,
            hasFailed: loginStatusHasFailed,
            status: loginStatusStatus,
            nationalId,
          },
          loginFinalizeRequest: {
            inFlight: loginFinalizeInFlight,
            ranBefore: loginFinalizeRanBefore,
            hasSucceeded: loginFinalizeHasSucceeded,
          },
        },
      },
    } = this.props

    if ( authHasSucceeded || loginFinalizeHasSucceeded ) {
      // User is logged in
      reactWith( redirectReactions.REDIRECT_TO_HOME )
    } else {
      // Login is ongoing
      if ( loginHasSucceeded && 'PENDING' === loginStatus && !loginStatusInterval && !loginStatusRanBefore ) {
        // Open BankID locally
        if ( true === sameDeviceField && _.get( autoStartToken, 'length', 0 ) ) {
          const returnTo = `${window.location.href}/${encodeURIComponent( authenticationId )}`.replace( 'login//', 'login/' )
          window.location = `bankid:///?autostarttoken=${encodeURIComponent( autoStartToken )}&redirect=${encodeURIComponent( returnTo )}`
        }

        reactWith( reactions.SET_LOGIN_STATUS_INTERVAL, {
          interval: setInterval(
            () =>
              reactWith( reactions.LOGIN_STATUS, {
                authenticationId: _.get( params, 'authenticationId.length', 0 ) ? params.authenticationId : authenticationId,
              } ),
            3000,
          ),
        } )
      }

      if ( loginStatusInterval && loginStatusHasSucceeded && 'COMPLETE' === loginStatusStatus && !loginFinalizeInFlight && !loginFinalizeRanBefore ) {
        // BankID is done, finalize by authenticating in Cognito
        reactWith( reactions.CLEAR_LOGIN_STATUS_INTERVAL )
        reactWith( reactions.LOGIN_FINALIZE, { username: nationalId } )
      } else if ( loginStatusInterval && loginStatusHasFailed ) {
        // Login status failure / login timeout
        reactWith( reactions.CLEAR_LOGIN_STATUS_INTERVAL )
      }
    }
  }

  showYesNoScreen = () => {
    const { reactWith } = this.props
    reactWith( reactions.SHOW_YES_NO_SCREEN )
  }

  showSSNScreen = () => {
    const { reactWith } = this.props
    reactWith( reactions.SHOW_SSN_SCREEN )
  }

  showBankIDScreen = ( params = { sameDevice: false } ) => {
    const { reactWith } = this.props
    reactWith( reactions.CHANGE_SAME_DEVICE_FIELD, { value: params.sameDevice } )
    reactWith( reactions.SHOW_BANKID_SCREEN )
  }

  changeSSNField = ( e ) => {
    const { reactWith } = this.props
    reactWith( reactions.CHANGE_SSN_FIELD, { value: e.target.value } )
  }

  formIsValid = () => {
    const { state: { loginScreen: { ssnField } } } = this.props

    if ( 12 !== ssnField.length ) {
      return false
    }

    return null !== ssnField.match( /^[0-9]+$/ )
  }

  loginInFlight = () => {
    const {
      state: {
        loginScreen: {
          loginRequest: {
            inFlight: loginRequestInFlight,
          },
          loginFinalizeRequest: {
            inFlight: loginFinalizeInFlight,
          },
        },
      },
    } = this.props

    return loginRequestInFlight || loginFinalizeInFlight
  }

  loginPending = () => {
    const {
      state: {
        loginScreen: {
          loginRequest: {
            status: loginRequestStatus,
          },
        },
      },
    } = this.props

    return 'PENDING' === loginRequestStatus
  }

  login = () => {
    const {
      reactWith,
      state: {
        loginScreen: {
          sameDeviceField,
          ssnField,
        },
      },
    } = this.props

    if ( isTestSSN( ssnField ) ) {
      reactWith( reactions.LOGIN_FINALIZE, { username: ssnField } )
    } else {
      reactWith( reactions.LOGIN, {
        nationalId: ssnField,
        sameDevice: sameDeviceField,
      } )
    }
  }

  reinitializeLogin = () => {
    const { reactWith } = this.props
    reactWith( reactions.LOGIN_REINITIALIZE )
    reactWith( reactions.SHOW_YES_NO_SCREEN )
  }

  render() {
    const {
      classes,
      state: {
        loginScreen: {
          showYesNoScreen,
          showSSNScreen,
          showBankIDScreen,
          ssnField,
          e,
          c,
        },
      },
      t,
    } = this.props

    let Alert, alertText = ''

    if ( e.length ) {
      alertText = e
    }

    if ( c.length ) {
      alertText = t( `LoginScreen.Alert.Text.${c}` )
    }

    if ( alertText.length ) {
      Alert = (
        <AlertDialog
          open={ true }
          title={ t( 'LoginScreen.Alert.Title' ) }
          text={ alertText }
          labelOk={ t( 'LoginScreen.Alert.OkButton' ) }
          onClose={ this.reinitializeLogin }
          onOk={ this.reinitializeLogin }
        />
      )
    }

    let Screen
    if ( showYesNoScreen ) {
      Screen = <YesNoScreen
        onNo={ this.showSSNScreen }
        onYes={ () => this.showBankIDScreen( { sameDevice: true } ) }
      />
    } else if ( showSSNScreen ) {
      Screen = <SSNScreen
        ssnField={ ssnField }
        onChangeSSNField={ this.changeSSNField }
        onCancel={ this.showYesNoScreen }
        onYes={ () => this.showBankIDScreen( { sameDevice: false } ) }
        disableYes={ !this.formIsValid() }
      />
    } else if ( showBankIDScreen ) {
      Screen = <BankIDScreen
        loginPending={ this.loginPending() }
        loginInFlight={ this.loginInFlight() || this.loginPending() }
        disableAuthenticate={ this.loginInFlight() || this.loginPending() }
        onAuthenticate={ this.login }
      />
    }

    return (
      <Hero height="386px">
        <div className="Screen LoginScreen">
          { Alert }

          <div className={ classes.header }/>

          <div className={ classes.main }>
            { Screen }
          </div>
        </div>
      </Hero>
    )
  }
}

export default FusionReactor( withStyles( styles )( Component ) )
