const mainStyle = ( theme ) => ( {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',

    // border: '1px solid red',
    [ theme.breakpoints.up( 600 ) ]: {
      overflowY: 'scroll',
    },

    [ theme.breakpoints.down( 600 ) ]: {
      paddingTop: `${theme.spacing.unit * 8}px`,
    },
  } ),
  appBarStyle = ( theme ) => ( {
    [ theme.breakpoints.down( 600 ) ]: {
      zIndex: 1, // Enough to float above anything positioned absolutely
      top: 0,
      left: 'auto',
      right: 0,
      position: 'fixed',
    },

    '& button[disabled]': {
      color: 'rgba(255, 255, 255, 0.4)',
    },
  } )

export default ( theme ) => ( {

  //
  // Misc styles

  progress: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  fab: {
    zIndex: 9999,
    position: 'absolute',
    right: `${theme.spacing.unit * 2}px`,
    bottom: `${theme.spacing.unit * 2}px`,

    [ theme.breakpoints.down( 600 ) ]: {
      position: 'fixed',
    },
  },

  //
  // AppBar styles

  appBar: {
    ...appBarStyle( theme ),
  },
  appBarDark: {
    ...appBarStyle( theme ),
    '&>div': {
      background: '#151847',
    },
    '& h2': {
      fontSize: '1rem',
    },
  },
  appBarTitle: {
    flex: '1',
  },

  //
  // Drawer styles

  drawerButton: {
    marginLeft: -12,
  },
  drawer: {
    zIndex: 10000,
  },
  drawerPaper: {
    background: '#2E3138',
  },
  drawerList: {
    width: '250px',
  },
  drawerLogo: {
    marginBottom: `${theme.spacing.unit}px`,

    '& span': {
      color: 'white',
      fontSize: '1.1rem',
      fontWeight: 'bold',
    },
  },
  drawerItem: {
    opacity: '.6',
    '& svg': {
      color: 'white',
    },
    '& span': {
      color: 'white',
      fontSize: '.9rem',
      fontWeight: 'normal',
    },
  },
  drawerActiveItem: {
    opacity: '1',
    '& span': {
      color: 'white',
      fontWeight: 'bold',
    },
  },

  //
  // Main styles

  main: {
    ...mainStyle( theme ),
    padding: `${theme.spacing.unit}px`,
  },
  mainGray: {
    ...mainStyle( theme ),
    background: '#F8F8F8',
    padding: `${theme.spacing.unit}px`,
  },
  mainDark: {
    ...mainStyle( theme ),
    background: '#151847',
    padding: `0 ${theme.spacing.unit}px`,
  },
  mainUnpadded: {
    ...mainStyle( theme ),
    padding: `${theme.spacing.unit}px 0`,
  },
  mainGrayUnpadded: {
    ...mainStyle( theme ),
    background: '#F8F8F8',
    padding: `${theme.spacing.unit}px 0`,
  },
} )