export default ( theme ) => ( {
  slider: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  slide: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  slideImage: {
    padding: '3rem',
  },
  slideTitle: {
    padding: '1rem',
    textAlign: 'center',
  },
  slideText: {
    padding: '1rem 2rem 2rem',
    textAlign: 'center',
  },
  stepper: {
    background: '#fff',
  },
} )