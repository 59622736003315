import awsConfig from '../../config/aws'
import awsLib from '../aws/aws'
import brandsignApi from './apis/brandsign'
import cognitoApi from './apis/cognito'


const aws = new awsLib( {
  cognito: {
    ...awsConfig.cognito.userPool,
  },
  s3: awsConfig.s3,
  graphqlAPI: awsConfig.clientAPI,
  eventAPI: awsConfig.eventAPI,
} )

export default {
  ...cognitoApi( aws ),
  ...brandsignApi(),
  
  query: aws.graphqlQuery,
  mutation: aws.graphqlMutation,
  sendConfirmationEmail: aws.sendConfirmationEmail,
  sendConfirmationSMS: aws.sendConfirmationSMS,
  confirmEmailAddress: aws.confirmEmailAddress,
  confirmPhoneNumber: aws.confirmPhoneNumber,
  getStripeCustomerData: aws.getStripeCustomerData,
  getStripeProductsList: aws.getStripeProductsList,
  subscribeToStripePlan: aws.subscribeToStripePlan,
  unsubscribeFromStripePlan: aws.unsubscribeFromStripePlan,
  getFrontierMatrices: aws.getFrontierMatrices,
  prepareOrderDocument: aws.prepareOrderDocument,
  event: aws.event,
}
