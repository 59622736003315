import { put } from 'redux-saga/effects'
import FusionReactor from '../../lib/FusionReactor'
import optimize from '../../lib/optimize'
import toggleObjectProperties from '../../lib/toggleObjectProperties'
import * as dataReactions from '../../providers/data/reactions'


export const REINITIALIZE_STATE = 'screens/optimize/REINITIALIZE_STATE'

export const CHANGE_FORM_FIELD = 'screens/optimize/CHANGE_FORM_FIELD'

export const TOGGLE_PANEL = 'screens/optimize/TOGGLE_PANEL'
export const TOGGLE_POPUP = 'screens/optimize/TOGGLE_POPUP'

export const OPTIMIZE = 'screens/optimize/OPTIMIZE'

const initialState = {
  formFields: {
    when_to_retire: 0,
    risk_level: 0,
    fund_version: 'Generic',
  },

  panels: {
    optimize: false,
  },

  popups: {
    when_to_retire: false,
    risk_level: false,
    fundSelection: false,
  },

  optimizationResults: {
    portfolio_fee: 0,
    exp_return: 0,
    expectedFundFees: 0,
    expectedSubscriptionFees: 0,
    min_return: 0,
    max_return: 0,
    sampleReturn: 0,
    sampleFundFees: 0,

    exp_returnSeries: [
      { x: 1, y: 1 },
      { x: 2, y: 2 },
      { x: 3, y: 5 },
      { x: 4, y: 11 },
    ],
    max_returnSeries: [
      { x: 1, y: 1 },
      { x: 2, y: 2 },
      { x: 3, y: 6 },
      { x: 4, y: 14 },
    ],
    min_returnSeries: [
      { x: 1, y: 1 },
      { x: 2, y: 2 },
      { x: 3, y: 4 },
      { x: 4, y: 9 },
    ],

    fund_list: [],
  },
}

export default FusionReactor.react({
  initialState,
  reactions: {
    [REINITIALIZE_STATE]: {
      reducer: (state, action) => ({ ...initialState }),
    },

    //
    // Controlled fields

    [CHANGE_FORM_FIELD]: {
      reducer: (state, action) => ({
        ...state,
        formFields: {
          ...state.formFields,
          [action.field]: action.value,
        },
      }),
    },

    //
    // Panels & popups

    [TOGGLE_PANEL]: {
      reducer: (state, action) => ({
        ...state,
        panels: toggleObjectProperties(state.panels, action.panel),
      }),
    },

    [TOGGLE_POPUP]: {
      reducer: (state, action) => ({
        ...state,
        popups: toggleObjectProperties(state.popups, action.popup),
      }),
    },

    //
    // Optimize

    [OPTIMIZE]: {
      reducer: (state, action) => {
        const { params } = action,
        result = optimize({
          ...params,
        })

        return {
          ...state,
          ...result,
        }
      },

      saga: function*(action) {
        const { params } = action

        if (params.track) {
          // Track only the initial portfolio optimization
          yield put(FusionReactor.reactWith(dataReactions.EVENT, {
            params: {
              event: 'Portfolio optimized',
            },
          }))
        }
      },
    },
  },
})
