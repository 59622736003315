const locale = {
  CurrencyFormat: '{value} kr',

  Subject: {
    order: 'order',
    orders: 'orders',
    Order: 'Order',
    Orders: 'Orders',
    recommendation: 'recommendation',
    recommendations: 'recommendations',
    Recommendation: 'Recommendation',
    Recommendations: 'Recommendations',
  },

  ErrorBoundary: {
    Text: 'Ooops!{br}Something went wrong.',
    ReportIssueButton: 'Report this issue',
  },

  Dashboard: {
    Drawer: {
      Home: 'Dashboard',
      MyAccount: 'My Account',
      MyDocuments: 'My Documents',
      Preferences: 'Preferences',
      Support: 'Support',
      Logout: 'Log Out',
    },
  },

  LoginScreen: {
    Alert: {
      Title: 'Sign in',
      Text: {
        UserNotFoundException: 'You are trying to login with with an account that doesn\'t exist, please <a href="#" id="intercom-launcher">contact support</a>.',
      },
      OkButton: 'OK',
    },
    YesNoScreen: {
      Title: 'Sign in',
      FirstParagraph: 'In order to sign in you need to have a BankID on this device.',
      SecondParagraph: 'Do you have a BankID on this device?',
      NoButton: 'No',
      YesButton: 'Yes',
    },
    SSNScreen: {
      Title: 'Enter your Personal ID',
      Paragraph: 'In order to connect you with your account we need your personal ID.',
      Form: {
        SSNFieldPlaceholder: 'Your personal ID',
        CancelButton: 'Cancel',
        YesButton: 'Yes',
      },
    },
    BankIDScreen: {
      StartBankIDApp: 'Start your BankID app.',
      AuthenticateButton: 'Authenticate',
    },
  },

  HomeScreen: {
    Title: 'Dashboard',
    AvailableHeading: 'Your insurance available for optimization',
    UnavailableHeading: 'Other insurance not available for optimization',
    InsuranceCard: {
      InsuranceDetails: {
        PremiumRecurrence: {
          OneTime: 'Premium: {value}',
          Monthly: 'Premium/mth: {value}',
        },
        PremiumStatus: 'Premium status: {value}',
        PremiumStatusValue: {
          J: 'Active',
          N: 'Inactive',
          K: 'Unknown',
        },
        PremiumLastPayedAt: 'Payed until: {value}',
        Holding: 'Holding: {value}',
        Status: 'Status: {value}',
        StatusValue: {
          G: 'Current',
          F: 'Vested',
          N: 'New',
          U: 'Payment',
          S: 'Paid',
        },
      },
      OptimizeButton: 'Start optimize',
    },
  },

  EmailRequiredScreen: {
    FailureAlert: {
      Title: 'Error',
      OkButton: 'Ok',
      AlreadyInUseMessage: 'This email address is already in use. Please contact support.',
      SendMessage: 'There was a problem sending your confirmation email. Please contact support.',
      GenericMessage: 'There was a problem setting your email address. Please contact support.',
    },
    EmailScreen: {
      Next: 'Next',
      Title: 'Email Required',
      Blurb: 'We need your email address to keep in touch about important information regarding your account and portfolio. Please type in your email address below:',
      Form: {
        EmailField: {
          Label: 'Your email address',
          Placeholder: 'Please type in your email address',
          Errors: {
            Invalid: 'Email address appears to be invalid',
          },
        },
      },
    },
    ConfirmScreen: {
      Title: 'Confirm Email',
      Blurb: 'We\'ve sent a confirmation email to "{email}". Please check your Inbox or Spam folder and click on the included link to confirm your email address.',
      ResendButton: 'Resend Confirmation Email',
    },
    ConfirmationEmail: {
      Subject: 'Confirm your Brightsave email',
      HtmlBody: '<p>Hi {first_name}</p>' +
        '<p>Please follow this link to confirm your email address:</p>' +
        '<p><a href="{link}">{link}</a></p>' +
        '<br>' +
        '<p>Thanks, the Brightsave.se team</p>',
      TextBody: 'Hi {first_name}\n' +
        'Please follow this link to confirm your email address:\n' +
        '{link}\n' +
        '\n' +
        'Thanks, the Brightsave.se team',
    },
  },

  EmailConfirmScreen: {
    Title: 'Confirm Email',
    Success: 'Your email address was confirmed successfully',
    Failure: 'Your email address could not be confirmed. <br> Please contact support.',
    GoToDashboardButton: 'Continue',
  },

  AgreementsScreen: {
    AboutScreen: {
      Title: 'About You',
      Next: 'Next',
      HelpPopups: {
        OkButton: 'Ok, got it',
      },
      CitizenshipHelpPopup: {
        Title: 'Citizenship',
        Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      },
      PaysForeignTaxHelpPopup: {
        Title: 'Taxes in other countries',
        Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      },
      PaysUSTaxHelpPopup: {
        Title: 'US taxpayer',
        Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      },
      IsPEPHelpPopup: {
        Title: 'PEP',
        Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      },
      Alerts: {
        CallButton: 'Call',
        ChatButton: 'Chat',
        OkButton: 'OK, got it',
      },
      PaysForeignTaxAlert: {
        Title: 'Verfication',
        Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      },
      PaysUSTaxAlert: {
        Title: 'Verfication',
        Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      },
      IsPEPAlert: {
        Title: 'Verfication',
        Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      },
      Form: {
        CitizenshipField: {
          Label: 'Your citizenship',
          None: 'None',
          Swedish: 'Swedish',
        },
        Questions: 'Questions',
        PaysForeignTaxField: {
          Legend: 'Do you pay taxes in other countries?',
          LabelYes: 'Yes',
          LabelNo: 'No',
        },
        PaysUSTaxField: {
          Legend: 'Are you a US tax payer?',
          LabelYes: 'Yes',
          LabelNo: 'No',
        },
        IsPEPField: {
          Legend: 'Are you, or someone close, a PEP?',
          LabelYes: 'Yes',
          LabelNo: 'No',
        },
      },
    },
    ConditionsScreen: {
      Title: 'Conditions',
      Next: 'Done',
      Form: {
        PersonalInformation: 'Personal Information',
        AgreesToDataProcessingField: {
          Legend: 'In order to keep you informed about your assets and events regarding these, we need to access your personal information.',
          Label: 'Brightsave may process my personal data to make sure my assets are optimized according to my preferences.',
        },
        AgreesToContactField: {
          Label: 'Brightsave may contact me through email, SMS or phone for issues regarding my assets.',
        },
        Agreement: 'Agreement',
        AgreesToCompanyTermsField: {
          Legend: 'The main contract is between Brightsave and {companyName} but we want to make sure that you understand that you\'ve understood the information you’ve been given. Read more at {here}.',
          LegendHere: 'https://brightsave.se/juridiska-dokument/',
          Label: 'I understand the conditions',
        },
        AgreesToIndividualTermsField: {
          Legend: 'The main contract is between Brightsave and you. We want to make sure that you understand that you\'ve understood the information you’ve been given. Read more at {here}.',
          LegendHere: 'https://brightsave.se/juridiska-dokument/',
          Label: 'I understand the conditions',
        },
      },
    },
  },

  IntroScreen: {
    BackButton: 'Back',
    NextButton: 'Next',
    FirstSlide: {
      Title: 'Optimize',
      Text: 'Pick one of the available insurances you’d like to optimize. Find out expected, minimum and maximum returns, compare your current and optimized portfolio and see the fund list.',
    },
    SecondSlide: {
      Title: 'Send order',
      Text: 'Our optimization is not moving your money anywhere, we\'re just re-arranging it where it is to make sure you get the best possible return and lowest fees based on your selected risk.',
    },
    ThirdSlide: {
      Title: 'Get notified',
      Text: 'We make sure that your money is being looked after everyday by our robots and if there is anything that needs to be changed, we will send notification for you to decide on how to proceed.',
      StartButton: 'Start',
    },
  },

  SubscriptionScreen: {
    Title: 'Subscriptions',
    BasicPlan: {
      Title: 'Basic',
      Blurb: 'Digital counselling',
      Benefits: '4',
      Benefit1: 'Access to our robot for advisory services.',
      Benefit2: '1 Insurance',
      Benefit3: 'Unlimited number of optimizations',
      Benefit4: 'Automatic rebalancing',
    },
    PremiumPlan: {
      Title: 'Premium',
      Blurb: 'Digital + personal advisory service',
      Benefits: '4',
      Benefit1: 'Everything in Basic plan',
      Benefit2: 'Personal certified advisor',
      Benefit3: 'Unlimited number of insurances',
      Benefit4: 'Advisory services for other insurance types',
    },
    Subscribe: 'Subscribe now for {value}/month',
    Form: {
      CardNumberField: {
        Label: 'Card Number',
      },
      ExpirationDateField: {
        Label: 'Card Number',
      },
      CVCField: {
        Label: 'CVC / CVV',
      },
      PostalCodeField: {
        Label: 'Postal code',
      },
      Subscribe: 'Subscribe now for {value}/month',
    },
    TrialAlert: {
      Title: 'Your free trial has expired',
      Message: 'Please signup to one of our plans to continue.',
      OkButton: 'OK',
    },
    SuccessAlert: {
      Title: 'Subscription Activated',
      Message: 'Thank you!',
      OkButton: 'Ok',
    },
    FailureAlert: {
      Title: 'Subscription Failed',
      GenericMessage: 'An error occurred while activating your subscription, please <a href="#" id="intercom-launcher">contact support</a>.',
      StripeCardMessage: 'Your card payment could not be processed, please <a href="#" id="intercom-launcher">contact support</a>.',
      OkButton: 'Ok',
    },
  },

  AccountScreen: {
    Title: 'My Account',
    SaveButton: 'Save',
    Form: {
      FirstNameField: {
        Label: 'First name',
        Placeholder: 'Your first name',
        Errors: {
          Empty: 'Your first name is required',
        },
      },
      LastNameField: {
        Label: 'Last name',
        Placeholder: 'Your last name',
        Errors: {
          Empty: 'Your last name is required',
        },
      },
      EmailField: {
        Label: 'Email',
        Placeholder: 'Your email address',
        Errors: {
          Empty: 'Your email address is required',
          Invalid: 'Your email address appears to be invalid',
        },
      },
      PhoneNumberField: {
        Label: 'Phone Number',
        Placeholder: '010xxxxxx',
        Errors: {
          Invalid: 'Your mobile phone number appears to be invalid',
        },
      },
    },
    Alert: {
      OkButton: 'OK',
    },
    UpdateSuccessAlert: {
      Title: 'Account updated',
      Text: 'Your account has been updated successfully',
    },
    ConfirmPhoneNumberSuccessAlert: {
      Title: 'Mobile number confirmed',
      Text: 'Your mobile number has been confirmed',
    },
    ConfirmationSMS: {
      Message: 'Your Brightsave confirmation code is: {code}',
    },
    ConfirmPhoneNumber: {
      Blurb: 'We\'ve sent a confirmation SMS to "{phone}". Please confirm your mobile phone number by typing in the confirmation code below:',
      FieldPlaceholder: 'ABCD',
      ResendButton: 'Resend Confirmation SMS',
    },
    Plans: {
      Basic: 'Basic',
      Premium: 'Premium',
    },
    Subscription: {
      WillBeCanceled: 'Your {plan} Subscription ends on {date} and will not be renewed',
      WillBeRenewed: 'Your {plan} Subscription will be automatically renewed on {date}',
      UnsubscribeButton: 'Cancel my subscription',
      UnsubscribeConfirm: {
        Title: 'Cancel subscription?',
        Message: 'Are you sure you want to cancel your subscription?',
        CancelButton: 'No',
        ConfirmButton: 'Yes',
      },
    },
    Trial: 'Your free trial ends in {trialRemaining} days',
  },

  PreferencesScreen: {
    Title: 'Preferences',
    SaveButton: 'Save',
    Channels: 'Channels',
    Frequency: 'Frequency',
    Form: {
      EmailField: {
        Label: 'Email',
      },
      SmsField: {
        Label: 'SMS',
      },
      NotificationsField: {
        Label: 'In-app notifications',
      },
      FrequencyField: {
        Label: {
          Anytime: 'Anytime',
          Monthly: 'Monthly',
          Quarterly: 'Monthly',
          Yearly: 'Once a year',
        },
      },
    },
  },

  DocumentsScreen: {
    Title: 'My Documents',
    None: 'You don\'t have any documents to display',
    SubjectsTitle: '{Subjects}',
    Subject: {
      Primary: '{Subject} {number}',
    },
    NewRecommendation: {
      Primary: 'New Recommendation',
      Secondary: 'New Recommendation',
    },
  },

  NotificationsScreen: {
    Title: 'Notifications',
    NoNotifications: 'You don’t have any notifications yet.',
    Today: 'Today',
    Order: 'Order {number}',
    OrderSuccess: 'Your order is successfully done!',
    SeeOrderButton: 'See order',
  },

  OptimizeScreen: {
    Next: 'Next',
    NetAmount: 'Net return',
    ReturnsCard: {
      Title: 'Prognosticated returns',
      Maximum: 'Maximum',
      Expected: 'Expected',
      Minimum: 'Minimum',
    },
    PortfolioCard: {
      Title: 'Portfolio',
      OptimizedPortfolio: 'Optimized Portfolio',
      CurrentPortfolio: 'Current Portfolio',
      FundFees: 'Fund fees',
      SubscriptionFees: 'Subscription fees',
    },
    FundlistCard: {
      Title: 'Fund list',
      Table: {
        Header: {
          Name: 'Name',
          Fee: 'Fee',
          Share: 'Share',
        },
      },
    },
    HelpPopups: {
      OkButton: 'Ok, got it',
    },
    WhenToRetirePopup: {
      Title: 'When to retire',
      Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
    RiskLevelPopup: {
      Title: 'Risk level',
      Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
    FundSelectionPopup: {
      Title: 'Fund Options',
      Text: '<strong>Sustainable:</strong> We select sustainable funds where there are good options, but if there are none we select ones that aren\'t necessarily are sustainable.<br>' +
        '<strong>Index:</strong> We select index funds where there are good options, but if there are none we select ones that aren\'t necessarily are index based.<br>' +
        '<strong>Generic:</strong> We select funds from all available funds regardless of classification.',
    },
    OptimizePanel: {
      WhenToRetire: 'When to retire ({age})',
      RiskLevel: 'Risk level ({risk})',
      FundSelection: 'Select preferred fund options',
      OnlySustainable: 'Only use sustainable funds',
      OnlyIndexFunds: 'Only use index funds',
      GenericFunds: 'Use all funds',
    },
  },

  OrderScreen: {
    Title: '{Subject}',
    Heading: 'Portfolio',
    Text: 'This is your optimized portfolio for the insurance. It reflects your choices done in previous screens and now it\'s time to send the order to the insurance company',
    Table: {
      Header: {
        Name: 'Name',
        Fee: 'Fee',
        Share: 'Share',
      },
    },
    PreparingScreen: {
      Text: 'Please wait{br}Preparing your order',
    },
    FailureScreen: {
      Text: 'Ooops!{br}Something went wrong.',
      GoToDashboardButton: 'Go to dashboard',
    },
    StatusScreen: {
      PleaseWait: 'Please wait.',
      Success: 'Thank you!{br}Your order was successfully sent.',
      Canceled: 'Your order was canceled.',
      GoToDashboardButton: 'Go to dashboard',
    },
    SigningPanel: {
      Text: 'I hereby confirm that the order above is correct and that by signing this with my BankID , the order will be sent to the insurance company and executed as soon as possible by the platform. Read more at {read_more}',
      ReadMoreLink: 'Read more',
      SignButton: 'Sign with BankID',
    },
  },

  OrderInstructionsScreen: {
    Title: 'Order Instructions',
    Heading: 'Portfolio',
    Text: 'This is your optimized portfolio for the insurance. It reflects your choices done in previous screens and now it\'s time to send the order to the insurance company. Skandia doesn\'t yet support digitally signed documents so you have to do it manually by: ',
    Skandia: {
      Heading: 'To complete your order, please follow these steps:',
      Text1: '1. Go to https://secure.skandia.se/home/ui/pages/pension/overview.aspx and login as per usual.',
      Text2: '2. Select the insurance that you\'ve just optimized and click on tha tab Change funds.',
      Text3: '3. Now you can enter the fund shares for both current and future fund purchase. Enter the name of the fund and the share in percent as given by the Brightsave app.',
    },
    Table: {
      Header: {
        Name: 'Name',
        Fee: 'Fee',
        Share: 'Share',
      },
    },
  },

  OrderRecommendationsScreen: {
    Title: 'Order Recommendation',
    Heading: 'Your updated portfolio',
    Text: 'Based on the changes in the new data, we recommend you the following protfolio to keep it at your chosen risk level ({riskLevel}) and your chosen save time ({saveTime} years).',
    Skandia: {
      Heading: 'To complete your order, please follow these steps:',
      Text1: '1. Go to https://secure.skandia.se/home/ui/pages/pension/overview.aspx and login as per usual.',
      Text2: '2. Select the insurance that you\'ve just optimized and click on tha tab Change funds.',
      Text3: '3. Now you can enter the fund shares for both current and future fund purchase. Enter the name of the fund and the share in percent as given by the Brightsave app.',
    },
    OptimizeButton: 'Start optimize',
    Table: {
      Header: {
        Name: 'Name',
        Fee: 'Fee',
        Share: 'Share',
      },
      New: 'New',
    },
  },
}

export default locale
