import * as Sentry from '@sentry/browser'
import _ from 'lodash'
import { call, put } from 'redux-saga/effects'
import api from '../../lib/api/api'
import FusionReactor from '../../lib/FusionReactor'
import * as requestStates from '../../lib/requestStates'
import toggleObjectProperties from '../../lib/toggleObjectProperties'


export const REINITIALIZE_STATE = 'screens/subscription/REINITIALIZE_STATE'
export const REINITIALIZE_SUBSCRIBE_TO_STRIPE_PLAN = 'screens/subscription/REINITIALIZE_SUBSCRIBE_TO_STRIPE_PLAN'

export const GET_STRIPE_PRODUCT_PLANS = 'screens/subscription/GET_STRIPE_PRODUCT_PLANS'
export const GET_STRIPE_PRODUCT_PLANS_REQUEST = 'screens/subscription/GET_STRIPE_PRODUCT_PLANS_REQUEST'
export const GET_STRIPE_PRODUCT_PLANS_SUCCESS = 'screens/subscription/GET_STRIPE_PRODUCT_PLANS_SUCCESS'
export const GET_STRIPE_PRODUCT_PLANS_FAILURE = 'screens/subscription/GET_STRIPE_PRODUCT_PLANS_FAILURE'

export const CHANGE_FORM_FIELD = 'screens/subscription/CHANGE_FORM_FIELD'

export const TOGGLE_SCREEN = 'screens/subscription/TOGGLE_SCREEN'
export const SET_ACTIVE_PLAN_TAB = 'screens/subscription/SET_ACTIVE_PLAN_TAB'

export const TOGGLE_ALERT = 'screens/subscription/TOGGLE_ALERT'

export const SUBSCRIBE_TO_STRIPE_PLAN = 'screens/subscription/SUBSCRIBE_TO_STRIPE_PLAN'
export const SUBSCRIBE_TO_STRIPE_PLAN_REQUEST = 'screens/subscription/SUBSCRIBE_TO_STRIPE_PLAN_REQUEST'
export const SUBSCRIBE_TO_STRIPE_PLAN_SUCCESS = 'screens/subscription/SUBSCRIBE_TO_STRIPE_PLAN_SUCCESS'
export const SUBSCRIBE_TO_STRIPE_PLAN_FAILURE = 'screens/subscription/SUBSCRIBE_TO_STRIPE_PLAN_FAILURE'

const initialState = {
  stripeProductPlans: null,
  formFields: {
    plan: null,
  },

  screens: {
    plans: true,
    checkout: false,
  },

  plansScreen: {
    activePlanTab: 0,
  },

  alerts: {
    trial: false,
    success: false,
    failure: false,
  },

  getStripeProductPlansRequest: { ...requestStates.initialState },
  subscribeToStripePlanRequest: { ...requestStates.initialState },
  e: '',
}

export default FusionReactor.react( {
  initialState,
  reactions: {
    [ REINITIALIZE_STATE ]: {
      reducer: ( state, action ) => ( { ...initialState } ),
    },

    [ REINITIALIZE_SUBSCRIBE_TO_STRIPE_PLAN ]: {
      reducer: ( state, action ) => ( {
        ...state,
        ..._.pick( initialState, [ 'alerts', 'subscribeToStripePlanRequest', 'e' ] ),
      } ),
    },

    //
    // Get Stripe data

    [ GET_STRIPE_PRODUCT_PLANS ]: {
      saga: function* ( action ) {
        yield put( FusionReactor.reactWith( GET_STRIPE_PRODUCT_PLANS_REQUEST, action ) )
      },
    },

    [ GET_STRIPE_PRODUCT_PLANS_REQUEST ]: {
      reducer: ( state, action ) => ( {
        ...state,
        getStripeProductPlansRequest: { ...requestStates.requestState },
      } ),

      saga: function* ( action ) {
        try {
          const { params } = action,
            result = yield call( api.getStripeProductsList ),
            product = _.find( result.data, { name: `app_${params.locale}` } ),
            basicMonthlyPlan = _.find( product.plans, { nickname: 'basic_monthly' } ),
            basicYearlyPlan = _.find( product.plans, { nickname: 'basic_yearly' } ),
            premiumMonthlyPlan = _.find( product.plans, { nickname: 'premium_monthly' } ),
            premiumYearlyPlan = _.find( product.plans, { nickname: 'premium_yearly' } )

          yield put( FusionReactor.reactWith( GET_STRIPE_PRODUCT_PLANS_SUCCESS, {
            result: {
              basicMonthlyPlan,
              basicYearlyPlan,
              premiumMonthlyPlan,
              premiumYearlyPlan,
            },
          } ) )
        } catch ( e ) {
          Sentry.captureException( e )
          yield put( FusionReactor.reactWith( GET_STRIPE_PRODUCT_PLANS_FAILURE, { e: _.get( e, 'message', '' ) } ) )
        }
      },
    },

    [ GET_STRIPE_PRODUCT_PLANS_SUCCESS ]: {
      reducer: ( state, action ) => {
        const { result } = action

        return {
          ...state,
          stripeProductPlans: result,
          getStripeProductPlansRequest: { ...requestStates.successState },
        }
      },
    },

    [ GET_STRIPE_PRODUCT_PLANS_FAILURE ]: {
      reducer: ( state, action ) => ( {
        ...state,
        getStripeProductPlansRequest: { ...requestStates.failureState },
        e: action.e,
      } ),
    },

    //
    // Controlled fields

    [ CHANGE_FORM_FIELD ]: {
      reducer: ( state, action ) => ( {
        ...state,
        formFields: {
          ...state.formFields,
          [ action.field ]: action.value,
        },
      } ),
    },

    //
    // Screens

    [ TOGGLE_SCREEN ]: {
      reducer: ( state, action ) => ( {
        ...state,
        screens: toggleObjectProperties( state.screens, action.screen ),
      } ),
    },

    [ SET_ACTIVE_PLAN_TAB ]: {
      reducer: ( state, action ) => {
        const { params } = action,
          { activePlanTab } = params

        return {
          ...state,
          plansScreen: {
            activePlanTab,
          },
        }
      },
    },

    //
    // Alerts

    [ TOGGLE_ALERT ]: {
      reducer: ( state, action ) => ( {
        ...state,
        alerts: toggleObjectProperties( state.alerts, action.alert ),
      } ),
    },

    //
    // Subscribe

    [ SUBSCRIBE_TO_STRIPE_PLAN ]: {
      saga: function* ( action ) {
        yield put( FusionReactor.reactWith( SUBSCRIBE_TO_STRIPE_PLAN_REQUEST, action ) )
      },
    },

    [ SUBSCRIBE_TO_STRIPE_PLAN_REQUEST ]: {
      reducer: ( state, action ) => ( {
        ...state,
        subscribeToStripePlanRequest: { ...requestStates.requestState },
      } ),

      saga: function* ( action ) {
        try {
          const {
              params: {
                stripe,
                owner,
                plan,
              },
            } = action,
            { source } = yield call( stripe.createSource, {
              type: 'card',
              owner,
            } ),
            result = yield call( api.subscribeToStripePlan, {
              source: _.get( source, 'id' ),
              plan: _.get( plan, 'id' ),
            } )

          yield put( FusionReactor.reactWith( SUBSCRIBE_TO_STRIPE_PLAN_SUCCESS, { result } ) )
        } catch ( e ) {
          Sentry.captureException( e )
          yield put( FusionReactor.reactWith( SUBSCRIBE_TO_STRIPE_PLAN_FAILURE, { e: _.get( e, 'message', '' ) } ) )
        }
      },
    },

    [ SUBSCRIBE_TO_STRIPE_PLAN_SUCCESS ]: {
      reducer: ( state, action ) => {
        return {
          ...state,
          subscribeToStripePlanRequest: { ...requestStates.successState },
        }
      },
    },

    [ SUBSCRIBE_TO_STRIPE_PLAN_FAILURE ]: {
      reducer: ( state, action ) => ( {
        ...state,
        subscribeToStripePlanRequest: { ...requestStates.failureState },
        e: action.e,
      } ),
    },
  },
} )
