import React from 'react'
import FusionReactor from '../../lib/FusionReactor'


class Component extends React.Component {
  componentDidMount() {
    window.analytics.page( '404' )
  }

  render() {
    return <div className="Screen FourOhFourScreen">404 Page Not Found</div>
  }
}

export default FusionReactor( Component )
