import Currency from '../../../things/currency/Currency'
import FusionReactor from '../../../lib/FusionReactor'
import moment from 'moment'
import React from 'react'
import { FormattedMessage } from 'react-intl'


class Component extends React.Component {
  render() {
    const {
      t,
      pin,
      premium_recurrence,
      premium_status,
      premium_last_payed_at,
      monthly_premium,
      holding,
      status,
    } = this.props

    //
    // Premium

    let premium_recurrenceI18nId = 'HomeScreen.InsuranceCard.InsuranceDetails.PremiumRecurrence.',
      premium_recurrenceI18nValue = monthly_premium

    if (0 === premium_recurrence) {
      premium_recurrenceI18nId += 'OneTime'
    }
    else {
      premium_recurrenceI18nId += 'Monthly'
    }

    // Premium status

    let PremiumStatus

    if (premium_status.length) {
      PremiumStatus = [
        <br key="premium-status-br"/>,
        <FormattedMessage
          key={ 'premium-status-value' }
          id="HomeScreen.InsuranceCard.InsuranceDetails.PremiumStatus"
          values={ {
            value: t( `HomeScreen.InsuranceCard.InsuranceDetails.PremiumStatusValue.${premium_status}` ),
          } }
        />,
      ]
    }

    // Premium last payed at

    let PremiumLastPayedAt

    if (null !== premium_last_payed_at) {
      PremiumLastPayedAt = [
        <br key="premium-last-payed-at-br"/>,
        <FormattedMessage
          key={ 'premium-last-payed-at-value' }
          id="HomeScreen.InsuranceCard.InsuranceDetails.PremiumLastPayedAt"
          values={ {
            value: moment(premium_last_payed_at).format('YYYY-MM-DD'),
          } }
        />,
      ]
    }

    //
    // Insurance status

    let statusI18nId = 'HomeScreen.InsuranceCard.InsuranceDetails.Status',
      statusI18nValue = t(`HomeScreen.InsuranceCard.InsuranceDetails.StatusValue.${status}`)

    //
    // Render

    return (
      <div>
        <strong>{ pin }</strong>

        <br/>

        <FormattedMessage
          id={ premium_recurrenceI18nId }
          values={ {
            value: <Currency value={ premium_recurrenceI18nValue }/>,
          } }
        />

        { PremiumStatus }

        { PremiumLastPayedAt }

        <br/>

        <FormattedMessage
          id="HomeScreen.InsuranceCard.InsuranceDetails.Holding"
          values={ {
            value: <Currency value={ holding }/>,
          } }
        />

        <br/>

        <FormattedMessage
          id={ statusI18nId }
          values={ {
            value: statusI18nValue,
          } }
        />
      </div>
    )
  }
}

export default FusionReactor(Component)
