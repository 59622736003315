export default ( theme ) => ( {

  //
  // General styles

  prevButton: {
    marginLeft: -12,
  },
  nextButton: {
    marginRight: -12,
  },

  //
  // Card styles

  card: {
    marginBottom: `${theme.spacing.unit * 2}px`,
    overflow: 'visible',
  },
  cardHeader: {
    borderBottom: '1px solid #E5E5E5',
    padding: `0 ${theme.spacing.unit * 2}px`,
  },
  cardTitle: {
    fontSize: '.9rem',
    fontWeight: '500',
    lineHeight: '.9rem',
  },
  cardAction: {
    opacity: '0',
    marginTop: '0',
  },
  cardContent: {
    padding: '0 !important',
  },

  //
  // Returns card styles

  returnsCardContent: {
    position: 'relative',
    padding: '0 0 0 0 !important',
    height: '256px',
  },
  returnsLegend: {
    position: 'absolute',
    top: 0,
    paddingTop: `${theme.spacing.unit}px`,

    '& .rv-discrete-color-legend-item': {
      padding: `0 ${theme.spacing.unit * 2}px`,
      margin: 0,
    },
  },
  returnsLegendItem: {
    fontSize: '.8rem',
    '& span': {
      fontWeight: 'bold',
    },
  },
  plot: {
    position: 'absolute',
    bottom: 0,
  },

  //
  // Portfolio card styles

  portfolioCardContent: {
    display: 'flex',
    flex: '1',
    flexDirection: 'row',
    padding: '0 !important',
  },
  portfolioCardCol: {
    position: 'relative',
    width: '50%',
    padding: '1rem 0 0 1rem',
  },
  portfolioCardLeftCol: {
    borderRight: '1px solid #E5E5E5',
  },
  portfolioCardRightCol: {},
  portfolioLegend: {
    position: 'absolute',
    zIndex: 1298,
  },
  portfolioLegendItem: {
    marginBottom: '10px',
    fontSize: '.8rem',

    '& span': {
      fontWeight: 'bold',
    },
  },
  portfolioPlot: {
    marginTop: '2rem',
  },

  //
  // Fund list card styles

  fund_listTableHead: {
    background: '#F2F2F2',
    '& tr': {
      height: 'auto',
    },
    '& th': {
      borderBottom: '0',
      padding: `${theme.spacing.unit * 2}px`,
      fontSize: '12px',
    },
  },
  fund_listTableBody: {
    '& tr': {
      height: 'auto',
    },
    '& td': {
      borderBottomColor: '#EAEAEA',
      padding: `${theme.spacing.unit * 2}px`,
    },
  },

  //
  // Optimize panel styles

  drawer: {
    '&>div:first-child': {
      // background: 'transparent',
      opacity: '.8 !important',
    },
  },
  drawerContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme.spacing.unit * 2}px`,
  },
  formRow: {
    width: '100%',
    display: 'flex',
    flex: '1',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginTop: `${theme.spacing.unit}px`,
    padding: `${theme.spacing.unit}px 0`,
  },
  formRowSlider: {
    marginBottom: '-15px',
    paddingBottom: '0',
  },
  formRowList: {
    marginTop: '0',
    padding: '0',
  },
  formControl: {
    display: 'flex',
    flex: '1',
    order: '1',

    '& legend': {
      color: '#000',
      fontSize: '.9rem',
    },
  },
  helpButton: {
    order: '2',
    marginTop: '-1rem',
    marginRight: '-12px',
  },
  slider: {
    height: '4px',
    boxShadow: 'none',
    borderRadius: '0',

    '& .rangeslider__fill': {
      background: '#2E5AFF',
    },

    '& .rangeslider__handle': {
      boxShadow: 'none',
      border: '1px solid #2E5AFF',
      background: '#2E5AFF',
    },

    '& .rangeslider__handle:after': {
      content: 'none',
    },
  },
  list: {
    width: '100%',
    padding: '0',
  },
  listItem: {
    margin: `${theme.spacing.unit * 3}px 0`,
    padding: '0',
  },
  listItemAction: {
    marginRight: '-15px',
  },
  listItemText: {
    color: '#000',
    fontSize: '.9rem',
  },
} )