import * as detectEnv from '../lib/detectEnv'

let brandsign = {
  url: 'https://sign.brightsave.se/BrandSign/Api/Rest/V.1',
  auth: {
    domain: 'Brightsave.se',
    accountId: '5000066',
  },
  sign: {
    domain: 'Navista.se',
    accountId: '5000065',
  }
}

if ( ! detectEnv.isProduction() ) {
  brandsign = {
    url: 'https://sign-dev.brightsave.se/BrandSign/Api/Rest/V.1',
      auth: {
    domain: 'Navista.se',
      accountId: '5500114',
  },
    sign: {
      domain: 'Navista.se',
        accountId: '5500114',
    }
  }
}

export default brandsign