import _ from 'lodash'
import fees from '../config/fees'
import consoleLog from './consoleLog'


export default (params, showLog = true) => {
  const {
    user,
    insuranceItem,
    optimizationData,
  } = params


  consoleLog(['***'], showLog)
  consoleLog(['Optimizing with the following params and fees:', params, fees], showLog)


  // ***


  const saveTime = params.when_to_retire - user.age,
    ageRisk = _.find(optimizationData.AgeRiskMapping, { savetime: `${saveTime}` })

  consoleLog(['Calculated save time:', saveTime], showLog)
  consoleLog(['Corresponding age risk mapping:', ageRisk], showLog)


  // ***


  const fundMap = _.filter(optimizationData.AssetClassFundMapping, { source: '*', version: params.fund_version })

  consoleLog(['Selected fund version:', params.fund_version], showLog)
  consoleLog(['Corresponding fund mapping:', fundMap], showLog)


  // ***


  const riskMin = parseInt(ageRisk.riskmin, 10),
    riskMax = parseInt(ageRisk.riskmax, 10),
    riskIndex = Math.round((params.risk_level / 7) * (riskMax - riskMin) + riskMin),
    frontier = optimizationData[`${params.fund_version}Frontier`][riskIndex - 1]

  consoleLog(['Calculated risk index:', riskIndex], showLog)
  consoleLog(['Corresponding frontier:', frontier], showLog)


  // ***


  const portfolioMeanReturn = parseFloat(frontier.meanreturn),
    portfolioVolatility = parseFloat(frontier.volatility)

  consoleLog(['Portfolio mean return:', portfolioMeanReturn], showLog)
  consoleLog(['Portfolio volatility:', portfolioVolatility], showLog)


  // ***


  const portfolio_feeLog = [],
    portfolio_fee = _.reduce(fundMap, (accumulator, fund) => {
      const fundFee = parseFloat(fund.fee),
        fundWeight = parseFloat(frontier[fund.fundname.trim().toLowerCase()])

      portfolio_feeLog.push(`+ Fund: ${fund.fundname}, fund fee: ${fundFee} x weight: ${fundWeight}`)
      return accumulator + (fundFee * fundWeight)
    }, 0)


  consoleLog(['Calculating portfolio fee', portfolio_feeLog], showLog)
  consoleLog(['Portfolio fee:', portfolio_fee], showLog)


  // ***


  const yearlyBrightsaveGains = insuranceItem.monthly_premium * 12 * (1 + (portfolioMeanReturn - fees.insuranceFee)),
    yearlySampleGains = insuranceItem.monthly_premium * 12 * (1 + (portfolioMeanReturn - fees.insuranceFee - (fees.samplePorfolioFee - portfolio_fee)))

  consoleLog([
    'Calculating yearly gains', [
      `yearlyBrightsaveGains: ${yearlyBrightsaveGains} = ${insuranceItem.monthly_premium} * 12 * ( 1 + ( portfolioMeanReturn: ${portfolioMeanReturn} - insuranceFee: ${fees.insuranceFee} - portfolio_fee: ${portfolio_fee} ) )`,
      `yearlySampleGains: ${yearlySampleGains} = ${insuranceItem.monthly_premium} * 12 * ( 1 + ( portfolioMeanReturn: ${portfolioMeanReturn} - insuranceFee: ${fees.insuranceFee} - ( samplePortfolioFee: ${fees.samplePorfolioFee} - portfolio_fee: ${portfolio_fee}) ) )`,
    ],
  ], showLog)


  // ***


  const exp_returnLog = [],
    exp_return = [{
      capital: insuranceItem.holding,
      netCapital: insuranceItem.holding,
    }]

  for (let y = 1; y <= saveTime; y++) {
    const startingCapital = exp_return[y - 1].netCapital,
      resultingCapital = (startingCapital * (1 + (portfolioMeanReturn - fees.insuranceFee))) - fees.fixedInsuranceFee - fees.brightsaveFee,
      resultingNetCapital = resultingCapital + yearlyBrightsaveGains,
      fundFee = (resultingCapital * portfolio_fee) + (insuranceItem.monthly_premium * 12 * portfolio_fee),
      subscriptionFee = y * fees.brightsaveFee

    exp_returnLog.push([
      `year ${y}`,
      `resultingCapital: ${resultingCapital} = ( startingCapital: ${startingCapital} * ( 1 + ( portfolioMeanReturn: ${portfolioMeanReturn} - insuranceFee: ${fees.insuranceFee} ) ) ) - fixedInsuranceFee: ${fees.fixedInsuranceFee} - brightsaveFee: ${fees.brightsaveFee}`,
      `resultingNetCapital: ${resultingNetCapital} = resultingCapital: ${resultingCapital} + yearlyBrightsaveGains: ${yearlyBrightsaveGains}`,
      `fundFee: ${fundFee} = ( resultingCapital: ${resultingCapital} * portfolio_fee: ${portfolio_fee} ) + ( premiumValue: ${insuranceItem.monthly_premium} * 12 * portfolio_fee: ${portfolio_fee} )`,
      `subscriptionFee: ${subscriptionFee} = year: ${y} * brightsaveFee: ${fees.brightsaveFee}`,
    ])

    exp_return.push({
      capital: resultingCapital,
      netCapital: resultingNetCapital,
      fundFee,
      subscriptionFee,
    })
  }

  consoleLog(['Calculating yearly expected return', exp_returnLog], showLog)
  consoleLog(['Expected return', exp_return], showLog)


  // ***


  const min_returnLog = [],
    max_returnLog = [],
    sampleReturnLog = [],
    min_return = [{ capital: insuranceItem.holding, netCapital: insuranceItem.holding }],
    max_return = [{ capital: insuranceItem.holding, netCapital: insuranceItem.holding }],
    sampleReturn = [{ capital: insuranceItem.holding, netCapital: insuranceItem.holding }]

  for (let y = 1; y <= saveTime; y++) {

    //
    // Minimum return

    {
      const startingCapital = min_return[y - 1].netCapital,
        resultingCapital = (startingCapital * (1 + (portfolioMeanReturn - fees.insuranceFee - portfolioVolatility))) - fees.fixedInsuranceFee - fees.brightsaveFee,
        resultingNetCapital = resultingCapital + yearlyBrightsaveGains

      min_returnLog.push([
        `year ${y}`,
        `resultingCapital: ${resultingCapital} = ( startingCapital: ${startingCapital} * ( 1 + ( portfolioMeanReturn: ${portfolioMeanReturn} - insuranceFee: ${fees.insuranceFee} - portfolioVolatility: ${portfolioVolatility} ) ) ) - fixedInsuranceFee: ${fees.fixedInsuranceFee} - brightsaveFee: ${fees.brightsaveFee}`,
        `resultingNetCapital: ${resultingNetCapital} = resultingCapital: ${resultingCapital} + yearlyBrightsaveGains: ${yearlyBrightsaveGains}`,
      ])

      min_return.push({
        capital: resultingCapital,
        netCapital: resultingNetCapital,
      })
    }

    //
    // Max return

    {
      const startingCapital = max_return[y - 1].netCapital,
        resultingCapital = startingCapital * (1 + (portfolioMeanReturn - fees.insuranceFee + portfolioVolatility)) - fees.fixedInsuranceFee - fees.brightsaveFee,
        resultingNetCapital = resultingCapital + yearlyBrightsaveGains

      max_returnLog.push([
        `year ${y}`,
        `startingCapital: ${startingCapital} * ( 1 + ( portfolioMeanReturn: ${portfolioMeanReturn} - insuranceFee: ${fees.insuranceFee} + portfolioVolatility: ${portfolioVolatility} ) ) - fixedInsuranceFee: ${fees.fixedInsuranceFee} - brightsaveFee: ${fees.brightsaveFee} = resultingCapital: ${resultingCapital}`,
        `resultingCapital: ${resultingCapital} + yearlyBrightsaveGains: ${yearlyBrightsaveGains} = resultingNetCapital: ${resultingNetCapital}`,
      ])

      max_return.push({
        capital: resultingCapital,
        netCapital: resultingNetCapital,
      })
    }

    //
    // Sample return

    {
      const startingCapital = sampleReturn[y - 1].netCapital,
        resultingCapital = startingCapital * (1 + (portfolioMeanReturn - fees.insuranceFee - (fees.samplePorfolioFee - portfolio_fee))) - fees.fixedInsuranceFee,
        resultingNetCapital = resultingCapital + yearlyBrightsaveGains,
        fundFee = (resultingCapital * fees.samplePorfolioFee) + (insuranceItem.monthly_premium * 12 * fees.samplePorfolioFee)

      sampleReturnLog.push([
        `year ${y}`,
        `resultingCapital: ${resultingCapital} = startingCapital: ${startingCapital} * ( 1 + ( portfolioMeanReturn: ${portfolioMeanReturn} - insuranceFee: ${fees.insuranceFee} -  ( samplePortfolioFee: ${fees.samplePorfolioFee} - portfolio_fee: ${portfolio_fee}) ) ) - fixedInsuranceFee: ${fees.fixedInsuranceFee}`,
        `resultingNetCapital: ${resultingNetCapital} = resultingCapital: ${resultingCapital} + yearlyBrightsaveGains: ${yearlyBrightsaveGains}`,
        `fundFee: ${fundFee} = ( resultingCapital: ${resultingCapital} * samplePorfolioFee: ${fees.samplePorfolioFee} ) + ( premiumValue: ${insuranceItem.monthly_premium} * 12 * samplePorfolioFee: ${fees.samplePorfolioFee} )`,
      ])

      sampleReturn.push({
        capital: resultingCapital,
        netCapital: resultingNetCapital,
        fundFee,
      })
    }
  }

  consoleLog(['Calculating yearly minimum return', min_returnLog], showLog)
  consoleLog(['Minimum return', min_return], showLog)
  consoleLog(['Calculating maximum return', max_returnLog], showLog)
  consoleLog(['Maximum return', max_return], showLog)
  consoleLog(['Calculating sample return', sampleReturnLog], showLog)
  consoleLog(['Sample return', sampleReturn], showLog)


  // ***


  // Filter out funds that don't have a weight
  const weightedFundList = _.filter(fundMap, (fund) => {
    const fundWeight = parseFloat(frontier[fund.fundname.trim().toLowerCase()])

    return 0 < fundWeight
  })


  return {
    optimizationResults: {
      portfolio_fee,
      exp_return: Math.round(_.last(exp_return).netCapital),
      expectedFundFees: Math.round(_.last(exp_return).fundFee),
      expectedSubscriptionFees: Math.round(_.last(exp_return).subscriptionFee),
      min_return: Math.round(_.last(min_return).netCapital),
      max_return: Math.round(_.last(max_return).netCapital),
      sampleReturn: Math.round(_.last(sampleReturn).netCapital),
      sampleFundFees: Math.round(_.last(sampleReturn).fundFee),

      exp_returnSeries: exp_return.map((item, key) => ({
        x: key + 1,
        y: Math.round(item.netCapital),
      })),
      min_returnSeries: min_return.map((item, key) => ({
        x: key + 1,
        y: Math.round(item.netCapital),
      })),
      max_returnSeries: max_return.map((item, key) => ({
        x: key + 1,
        y: Math.round(item.netCapital),
      })),

      fund_list: _.map(weightedFundList, (fund) => ({
        name: fund.fundname,
        weight: parseFloat(frontier[fund.fundname.trim().toLocaleLowerCase()]),
        fee: parseFloat(fund.fee),
        fact: fund.factsheet,
      })),
    },
  }
}
