import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Drawer from '@material-ui/core/Drawer'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Radio from '@material-ui/core/Radio'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import FullScreen from '@material-ui/icons/Fullscreen'
import HelpIcon from '@material-ui/icons/HelpOutline'
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import Slider from 'react-rangeslider'
import { Link } from 'react-router-dom'
import { DiscreteColorLegend, LineSeries, makeWidthFlexible, VerticalBarSeries, XYPlot } from 'react-vis'
import retirementAgeConfig from '../../config/retirementAge'
import FusionReactor from '../../lib/FusionReactor'
import optimize from '../../lib/optimize'
import * as redirectReactions from '../../providers/redirect/reactions'
import Dashboard from '../../rigging/dashboard/Dashboard'
import Hero from '../../rigging/hero/Hero'
import AlertDialog from '../../things/alert_dialog/AlertDialog'
import Currency from '../../things/currency/Currency'
import * as orderReactions from '../order/reactions'
import * as reactions from './reactions'
import styles from './styles'


const FlexibleXYPlot = makeWidthFlexible(XYPlot),
  FlexibleLegend = makeWidthFlexible(DiscreteColorLegend)

class Component extends React.Component {

  //
  // Lifecycle

  componentDidMount() {
    const {
      reactWith,
      state: {
        dataProvider: {
          user,
        },
      },
    } = this.props

    if (_.isUndefined(this.getInsuranceItem())) {
      reactWith(redirectReactions.REDIRECT_TO_HOME)
    }

    reactWith(reactions.REINITIALIZE_STATE)

    // These cause componentDidUpdate to fire, which will run the initial optimize calculation
    this.changeWhenToRetireField(Math.max(user.age + 1, retirementAgeConfig.initial))
    this.changeRiskLevelField(6)

    window.analytics.page('Optimize')
  }

  componentDidUpdate(prevProps) {
    const {
      state: {
        optimizeScreen: {
          formFields: prevFormFields,
        },
      },
    } = prevProps, {
      reactWith,
      state: {
        dataProvider: {
          user,
        },
        optimizeScreen: {
          formFields,
          optimizationResults: {
            expectedSubscriptionFees,
          },
        },
      },
    } = this.props,
      insuranceItem = this.getInsuranceItem(),
      optimizationData = this.getOptimizationData(),
      formFieldsHaveChanged = JSON.stringify(prevFormFields) !== JSON.stringify(formFields)

    if (formFieldsHaveChanged || !expectedSubscriptionFees) {
      reactWith(reactions.OPTIMIZE, {
        params: {
          user,
          insuranceItem,
          optimizationData,
          ...formFields,
          track: true,
        },
      })
    }
  }

  getInsuranceItem = () => {
    const {
      params: {
        id,
      },
      state: {
        dataProvider: {
          user,
        },
      },
    } = this.props

    return _.find(user.InsuranceItemList, { id, status: 'G' })
  }

  getOptimizationData = () => {
    const {
      state: {
        dataProvider: {
          optimizationData,
        },
      },
    } = this.props,
      insuranceItem = this.getInsuranceItem(),
      insuranceSource = insuranceItem.InsuranceSourceGet

    return _.find(optimizationData, { insurance_source_id: insuranceSource.id })
  }

  //
  // Controlled fields

  changeWhenToRetireField = (value) => {
    const { reactWith } = this.props
    reactWith(reactions.CHANGE_FORM_FIELD, {
      field: 'when_to_retire',
      value,
    })
  }

  changeRiskLevelField = (value) => {
    const { reactWith } = this.props
    reactWith(reactions.CHANGE_FORM_FIELD, {
      field: 'risk_level',
      value,
    })
  }

  changeFundVersionField = (e) => {
    const { reactWith } = this.props
    reactWith(reactions.CHANGE_FORM_FIELD, {
      field: 'fund_version',
      value: e.target.value,
    })
  }

  //
  // Panels & popups

  openOptimizePanel = () => {
    const { reactWith } = this.props
    reactWith(reactions.TOGGLE_PANEL, {
      panel: 'optimize',
    })
  }

  closeOptimizePanel = () => {
    const { reactWith } = this.props
    reactWith(reactions.TOGGLE_PANEL)
  }

  openWhenToRetirePopup = () => {
    const { reactWith } = this.props
    reactWith(reactions.TOGGLE_POPUP, {
      popup: 'when_to_retire',
    })
  }

  closeWhenToRetirePopup = () => {
    const { reactWith } = this.props
    reactWith(reactions.TOGGLE_POPUP)
  }

  openRiskLevelPopup = () => {
    const { reactWith } = this.props
    reactWith(reactions.TOGGLE_POPUP, {
      popup: 'risk_level',
    })
  }

  closeRiskLevelPopup = () => {
    const { reactWith } = this.props
    reactWith(reactions.TOGGLE_POPUP)
  }

  openFundSelectionPopup = () => {
    const { reactWith } = this.props
    reactWith(reactions.TOGGLE_POPUP, {
      popup: 'fundSelection',
    })
  }

  closeFundSelectionPopup = () => {
    const { reactWith } = this.props
    reactWith(reactions.TOGGLE_POPUP)
  }

  order = () => {
    const {
      reactWith,
      state: {
        dataProvider: {
          user,
        },
        optimizeScreen: {
          formFields,
          optimizationResults,
        },
      },
    } = this.props,
      insuranceItem = this.getInsuranceItem(),
      orderParams = {
        insurance_item_id: insuranceItem.id,
        ...formFields,
        holding: insuranceItem.holding,
        save_time: formFields.when_to_retire - user.age,
        ..._.pick(optimizationResults, ['portfolio_fee', 'fund_list', 'max_return', 'exp_return', 'min_return']),
      }

    reactWith(orderReactions.HYDRATE_ORDER_PARAMS, {
      params: orderParams,
    })

    reactWith(redirectReactions.REDIRECT_TO_ORDER)
  }

  //
  // Render

  render() {
    const {
      classes,
      t,
      state: {
        dataProvider: {
          user,
          user: {
            age,
            CompanyGet,
          },
        },
        optimizeScreen: {
          formFields,
          panels: {
            optimize: optimizePanelOpen,
          },
          popups: {
            when_to_retire: when_to_retirePopupOpen,
            risk_level: risk_levelPopupOpen,
            fundSelection: fundSelectionPopupOpen,
          },
          optimizationResults: {
            exp_return,
            expectedFundFees,
            expectedSubscriptionFees,
            min_return,
            max_return,
            sampleReturn,
            sampleFundFees,
            exp_returnSeries,
            max_returnSeries,
            min_returnSeries,
            fund_list,
          },
        },
      },
    } = this.props,
      insuranceItem = this.getInsuranceItem(),
      optimizationData = this.getOptimizationData(),
      insuranceSource = insuranceItem.InsuranceSourceGet,
      min_retirement_age = _.get(CompanyGet, 'min_retirement_age', retirementAgeConfig.min),
      max_retirement_age = _.get(CompanyGet, 'max_retirement_age', retirementAgeConfig.max),
      Prev = (
        <IconButton
          className={ classes.prevButton }
          color="inherit"
          component={ Link }
          to="/"
        >
          <ArrowBackIcon/>
        </IconButton>
      ),
      Next = (
        <Button
          className={ classes.nextButton }
          color="inherit"
          onClick={ this.order }
        >
          { t( 'OptimizeScreen.Next' ) }
        </Button>
      )

    let Fab
    if (!optimizePanelOpen) {
      Fab = ((className) =>
        <Button
            variant="fab"
            color="primary"
            className={ className }
            onClick={ this.openOptimizePanel }
          >
            <TrendingUpIcon/>
          </Button>
      )
    }

    const HelpPopups = [
      <AlertDialog
        key="when-to-retire-popup"
        title={ t( 'OptimizeScreen.WhenToRetirePopup.Title' ) }
        text={ t( 'OptimizeScreen.WhenToRetirePopup.Text' ) }
        labelOk={ t( 'OptimizeScreen.HelpPopups.OkButton' ) }
        open={ when_to_retirePopupOpen }
        onClose={ this.closeWhenToRetirePopup }
        onOk={ this.closeWhenToRetirePopup }
      />,

      <AlertDialog
        key="risk-level-popup"
        title={ t( 'OptimizeScreen.RiskLevelPopup.Title' ) }
        text={ t( 'OptimizeScreen.RiskLevelPopup.Text' ) }
        labelOk={ t( 'OptimizeScreen.HelpPopups.OkButton' ) }
        open={ risk_levelPopupOpen }
        onClose={ this.closeRiskLevelPopup }
        onOk={ this.closeRiskLevelPopup }
      />,

      <AlertDialog
        key="fund-selection-popup"
        title={ t( 'OptimizeScreen.FundSelectionPopup.Title' ) }
        text={ t( 'OptimizeScreen.FundSelectionPopup.Text' ) }
        labelOk={ t( 'OptimizeScreen.HelpPopups.OkButton' ) }
        open={ fundSelectionPopupOpen }
        onClose={ this.closeFundSelectionPopup }
        onOk={ this.closeFundSelectionPopup }
      />,
    ]

    const {
      optimizationResults: {
        max_return: absmax_return,
      },
    } = optimize({
        user,
        insuranceItem,
        optimizationData,
        when_to_retire: max_retirement_age,
        risk_level: 7,
        fund_version: 'Generic',
      }, false),
      RETURNS_SERIES = [{
          color: 'transparent',
          data: [
            { x: 1, y: absmax_return },
            { x: 2, y: absmax_return },
          ],
        },
        {
          color: '#00E5C1',
          legend: t('OptimizeScreen.ReturnsCard.Maximum'),
          legendValue: max_return,
          data: max_returnSeries,
          extraProps: {
            strokeDasharray: '7 3',
          },
        },
        {
          color: '#8875FF',
          legend: t('OptimizeScreen.ReturnsCard.Expected'),
          legendValue: exp_return,
          data: exp_returnSeries,
        },
        {
          color: '#FF5185',
          legend: t('OptimizeScreen.ReturnsCard.Minimum'),
          legendValue: min_return,
          data: min_returnSeries,
          extraProps: {
            strokeDasharray: '4 4',
          },
        },
      ],
      RETURNS_SERIES_LEGEND_COLORS = RETURNS_SERIES.reduce((acc, series) => {
        if (!_.isUndefined(series.legend)) {
          acc.push(series.color)
        }

        return acc
      }, []),
      RETURNS_SERIES_LEGEND_ITEMS = RETURNS_SERIES.reduce((acc, series) => {
        if (!_.isUndefined(series.legend)) {
          acc.push({
            legend: series.legend,
            legendValue: series.legendValue,
          })
        }

        return acc
      }, [])

    let Screen = (
      <Dashboard
        title={ `${insuranceSource.name} (${insuranceItem.pin})` }
        dark
        left={ Prev }
        right={ Next }
        fab={ Fab }
      >
        <div className="Screen OptimizeScreen">
          { HelpPopups }

          <Card className={ classes.card }>
            <CardHeader
              title={ t( 'OptimizeScreen.ReturnsCard.Title' ) }
              action={
                <IconButton>
                  <FullScreen/>
                </IconButton>
              }
              className={ classes.cardHeader }
              classes={ {
                title: classes.cardTitle,
                action: classes.cardAction,
              } }
            />
            <CardContent className={ classNames( classes.cardContent, classes.returnsCardContent ) }>
              <FlexibleLegend
                orientation="vertical"
                className={ classes.returnsLegend }
                colors={ RETURNS_SERIES_LEGEND_COLORS }
                items={ RETURNS_SERIES_LEGEND_ITEMS.map( ( item, key ) =>
                  <Typography
                    key={ `returns-lengend-item-${key}` }
                    className={ classes.returnsLegendItem }
                  >
                    { item.legend }<br/>
                    <span><Currency value={ item.legendValue }/></span>
                  </Typography>,
                ) }
              />

              <FlexibleXYPlot
                className={ classes.plot }
                height={ 256 }
                margin={ { left: 0, right: 0, top: 5, bottom: 5 } }
              >
                { RETURNS_SERIES.map( ( series, key ) =>
                  <LineSeries
                    key={ `returns-line-series-${key}` }
                    curve={ 'curveMonotoneX' }
                    style={ { stroke: series.color } }
                    data={ series.data }
                    { ..._.get( series, 'extraProps', {} ) }
                  />,
                ) }
              </FlexibleXYPlot>
            </CardContent>
          </Card>

          <Card className={ classes.card }>
            <CardHeader
              title={ t( 'OptimizeScreen.PortfolioCard.Title' ) }
              action={
                <IconButton>
                  <FullScreen/>
                </IconButton>
              }
              className={ classes.cardHeader }
              classes={ {
                title: classes.cardTitle,
                action: classes.cardAction,
              } }
            />
            <CardContent className={ classNames( classes.cardContent, classes.portfolioCardContent ) }>
              <div className={ classNames( classes.portfolioCardCol, classes.portfolioCardLeftCol ) }>
                <div className={ classes.portfolioLegend }>
                  <Typography
                    className={ classes.portfolioLegendItem }
                  >
                    <span>{ t( 'OptimizeScreen.PortfolioCard.OptimizedPortfolio' ) }</span><br/>
                    { t( 'OptimizeScreen.NetAmount' ) }<br/>
                    <span><Currency value={ exp_return }/></span>
                  </Typography>

                  <Typography
                    className={ classes.portfolioLegendItem }
                  >
                    { t( 'OptimizeScreen.PortfolioCard.FundFees' ) }<br/>
                    <span><Currency value={ expectedFundFees }/></span>
                  </Typography>

                  <Typography
                    className={ classes.portfolioLegendItem }
                  >
                    { t( 'OptimizeScreen.PortfolioCard.SubscriptionFees' ) }<br/>
                    <span><Currency value={ expectedSubscriptionFees }/></span>
                  </Typography>
                </div>

                <div className={ classes.portfolioPlot }>
                  <FlexibleXYPlot
                    height={ 128 }
                    margin={ { left: 0, right: 0, top: 0, bottom: 0 } }
                  >
                    <VerticalBarSeries
                      style={ { stroke: '#fff', fill: '#fff' } }
                      data={ [ { x: 0, y: sampleReturn } ] }
                    />
                    <VerticalBarSeries
                      style={ { stroke: '#00C3F8', fill: '#00C3F8' } }
                      data={ [ { x: 1, y: exp_return } ] }
                    />
                  </FlexibleXYPlot>
                </div>
              </div>
              <div className={ classNames( classes.portfolioCardCol, classes.portfolioCardRightCol ) }>
                <div className={ classes.portfolioLegend }>
                  <Typography
                    className={ classes.portfolioLegendItem }
                  >
                    <span>{ t( 'OptimizeScreen.PortfolioCard.CurrentPortfolio' ) }</span><br/>
                    { t( 'OptimizeScreen.NetAmount' ) }<br/>
                    <span><Currency value={ sampleReturn }/></span>
                  </Typography>

                  <Typography
                    className={ classes.portfolioLegendItem }
                  >
                    { t( 'OptimizeScreen.PortfolioCard.FundFees' ) }<br/>
                    <span><Currency value={ sampleFundFees }/></span>
                  </Typography>
                </div>

                <div className={ classes.portfolioPlot }>
                  <FlexibleXYPlot
                    height={ 128 }
                    margin={ { left: 0, right: 0, top: 0, bottom: 0 } }
                  >
                    <VerticalBarSeries
                      style={ { stroke: '#fff', fill: '#fff' } }
                      data={ [ { x: 0, y: exp_return } ] }
                    />
                    <VerticalBarSeries
                      style={ { stroke: '#D5D5D5', fill: '#D5D5D5' } }
                      data={ [ { x: 1, y: sampleReturn } ] }
                    />
                  </FlexibleXYPlot>
                </div>
              </div>
            </CardContent>
          </Card>

          <Card className={ classes.card }>
            <CardHeader
              title={ t( 'OptimizeScreen.FundlistCard.Title' ) }
              action={
                <IconButton>
                  <FullScreen/>
                </IconButton>
              }
              className={ classes.cardHeader }
              classes={ {
                title: classes.cardTitle,
                action: classes.cardAction,
              } }
            />
            <CardContent className={ classes.cardContent }>
              <Table className={ classes.table }>
                <TableHead className={ classes.fund_listTableHead }>
                  <TableRow>
                    <TableCell>{ t( 'OptimizeScreen.FundlistCard.Table.Header.Name' ) }</TableCell>
                    <TableCell numeric>{ t( 'OptimizeScreen.FundlistCard.Table.Header.Fee' ) }</TableCell>
                    <TableCell numeric>{ t( 'OptimizeScreen.FundlistCard.Table.Header.Share' ) }</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={ classes.fund_listTableBody }>
                  { fund_list.map( ( fund, key ) => (
                    <TableRow key={ `fundlist-${key}` }>
                      <TableCell>{ fund.name }</TableCell>
                      <TableCell numeric>{ ( fund.fee * 100 ).toFixed( 2 ) }%</TableCell>
                      <TableCell numeric>{ ( fund.weight * 100 ).toFixed( 2 ) }%</TableCell>
                    </TableRow>
                  ) ) }
                </TableBody>
              </Table>
            </CardContent>
          </Card>

          <Drawer
            anchor="bottom"
            open={ optimizePanelOpen }
            onClose={ this.closeOptimizePanel }
            className={ classes.drawer }
          >
            <div
              tabIndex={ 0 }
              role="button"
              onKeyDown={ this.closeOptimizePanel }
              className={ classes.drawerContainer }
              ref={ this.optimizePanelEl }
            >
              <div className={ classNames( classes.formRow, classes.formRowSlider ) }>
                <IconButton onClick={ this.openWhenToRetirePopup } className={ classes.helpButton }>
                  <HelpIcon/>
                </IconButton>

                <FormControl component="fieldset" className={ classes.formControl }>
                  <FormLabel
                    component="legend">{ t( 'OptimizeScreen.OptimizePanel.WhenToRetire', { age: formFields.when_to_retire } ) }</FormLabel>
                </FormControl>
              </div>

              <Slider
                min={ Math.max( min_retirement_age, age + 1 ) }
                max={ max_retirement_age }
                value={ formFields.when_to_retire }
                onChange={ this.changeWhenToRetireField }
                onClick={ ( e ) => {
                  e.preventDefault()
                  e.stopPropagation()
                } }
                tooltip={ false }
                className={ classes.slider }
              />

              <div className={ classNames( classes.formRow, classes.formRowSlider ) }>
                <IconButton onClick={ this.openRiskLevelPopup } className={ classes.helpButton }>
                  <HelpIcon/>
                </IconButton>

                <FormControl component="fieldset" className={ classes.formControl }>
                  <FormLabel
                    component="legend">{ t( 'OptimizeScreen.OptimizePanel.RiskLevel', { risk: formFields.risk_level } ) }</FormLabel>
                </FormControl>
              </div>

              <Slider
                min={ 1 }
                max={ 7 }
                value={ formFields.risk_level }
                onChange={ this.changeRiskLevelField }
                tooltip={ false }
                className={ classes.slider }
              />

              <div className={ classNames( classes.formRow, classes.formRowList ) }>
                <List className={ classes.list }>
                  <ListItem dense className={ classes.listItem }>
                    <ListItemText
                      primary={ t( 'OptimizeScreen.OptimizePanel.FundSelection' ) }
                      className={ classes.listItemText }
                    />

                    <ListItemSecondaryAction className={ classes.listItemAction }>
                      <IconButton onClick={ this.openFundSelectionPopup }>
                        <HelpIcon/>
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>

                  { insuranceSource.supports_sustainable_funds ? (
                    <ListItem dense className={ classes.listItem }>
                      <ListItemText
                        primary={ t( 'OptimizeScreen.OptimizePanel.OnlySustainable' ) }
                        className={ classes.listItemText }
                      />

                      <ListItemSecondaryAction className={ classes.listItemAction }>
                        <Radio
                          checked={ 'Sustainable' === formFields.fund_version }
                          onChange={ this.changeFundVersionField }
                          value="Sustainable"
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  ) : null }

                  <ListItem dense className={ classes.listItem }>
                    <ListItemText
                      primary={ t( 'OptimizeScreen.OptimizePanel.OnlyIndexFunds' ) }
                      className={ classes.listItemText }
                    />

                    <ListItemSecondaryAction className={ classes.listItemAction }>
                      <Radio
                        checked={ 'Index' === formFields.fund_version }
                        onChange={ this.changeFundVersionField }
                        value="Index"
                      />
                    </ListItemSecondaryAction>
                  </ListItem>

                  <ListItem dense className={ classes.listItem }>
                    <ListItemText
                      primary={ t( 'OptimizeScreen.OptimizePanel.GenericFunds' ) }
                      className={ classes.listItemText }
                    />

                    <ListItemSecondaryAction className={ classes.listItemAction }>
                      <Radio
                        checked={ 'Generic' === formFields.fund_version }
                        onChange={ this.changeFundVersionField }
                        value="Generic"
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </div>
            </div>
          </Drawer>
        </div>
      </Dashboard>
    )

    return (
      <Hero>
        { Screen }
      </Hero>
    )
  }
}

export default FusionReactor(withStyles(styles)(Component))
