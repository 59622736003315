import * as redirectReactions from '../../providers/redirect/reactions'
import _ from 'lodash'
import Avatar from '@material-ui/core/Avatar'
import Dashboard from '../../rigging/dashboard/Dashboard'
import FusionReactor from '../../lib/FusionReactor'
import Hero from '../../rigging/hero/Hero'
import React from 'react'
import styles from './styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'


class Component extends React.Component {
  componentDidMount() {
    const { reactWith } = this.props,
      order = this.getOrder()

    if ( _.isUndefined( order ) ) {
      reactWith( redirectReactions.REDIRECT_TO_HOME )
    }

    window.analytics.page( 'Order Instructions' )
  }

  getOrder = () => {
    const {
      params,
      state: {
        dataProvider: {
          user,
        },
      },
    } = this.props

    return _.find( user.OrderList, params )
  }

  getInsuranceItem = ( insurance_item_id ) => {
    const {
      state: {
        dataProvider: {
          user,
        },
      },
    } = this.props

    return _.find( user.InsuranceItemList, { id: insurance_item_id, status: 'G' } )
  }

  render() {
    const {
        classes,
        t,
      } = this.props,
      order = this.getOrder()

    let Header, Instructions, FundList = <div></div>

    if ( !_.isUndefined( order ) ) {
      const insuranceItem = this.getInsuranceItem( order.insurance_item_id ),
        insuranceSource = insuranceItem.InsuranceSourceGet,
        fund_list = JSON.parse( atob( order.fund_list ) )

      Header = (
        <div className={ classes.header }>
          <Avatar className={ classes.avatar }>
            { insuranceSource.name[ 0 ] }
          </Avatar>

          <Typography variant="subheading">
            { insuranceSource.name }
          </Typography>
        </div>
      )

      switch ( insuranceSource.ssn.toString() ) {
        case '165164060948':
          Instructions = (
            <div>
              <Typography className={ classes.heading }>
                { t( 'OrderInstructionsScreen.Skandia.Heading' ) }
              </Typography>

              <Typography className={ classes.text }>
                { t( 'OrderInstructionsScreen.Skandia.Text1' ) }
              </Typography>

              <Typography className={ classes.text }>
                { t( 'OrderInstructionsScreen.Skandia.Text2' ) }
              </Typography>

              <Typography className={ classes.text }>
                { t( 'OrderInstructionsScreen.Skandia.Text3' ) }
              </Typography>
            </div>
          )
          break

        default:
          Instructions = (
            <div>
              <Typography className={ classes.heading }>
                { t( 'OrderInstructionsScreen.Heading' ) }
              </Typography>

              <Typography className={ classes.text }>
                { t( 'OrderInstructionsScreen.Text' ) }
              </Typography>
            </div>
          )
          break
      }

      FundList = (
        <Table className={ classes.table }>
          <TableHead className={ classes.tableHead }>
            <TableRow>
              <TableCell>{ t( 'OrderInstructionsScreen.Table.Header.Name' ) }</TableCell>
              <TableCell numeric>{ t( 'OrderInstructionsScreen.Table.Header.Fee' ) }</TableCell>
              <TableCell numeric>{ t( 'OrderInstructionsScreen.Table.Header.Share' ) }</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={ classes.tableBody }>
            { fund_list.map( ( fund, key ) => (
              <TableRow key={ `fundlist-${key}` }>
                <TableCell>{ fund.name }</TableCell>
                <TableCell numeric>{ ( fund.fee * 100 ).toFixed( 2 ) }%</TableCell>
                <TableCell numeric>{ ( fund.weight * 100 ).toFixed( 2 ) }%</TableCell>
              </TableRow>
            ) ) }
          </TableBody>
        </Table>
      )
    }

    return (
      <Hero>
        <Dashboard
          title={ t( 'OrderInstructionsScreen.Title' ) }
          left="drawer"
          mainUnpadded
        >
          <div className="Screen OrderInstructionsScreen">
            { Header }

            { Instructions }

            { FundList }
          </div>
        </Dashboard>
      </Hero>
    )
  }
}

export default FusionReactor( withStyles( styles )( Component ) )
