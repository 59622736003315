const icon = {
  width: '64px',
  height: '64px',
  marginBottom: '1.5rem',
}

export default ( theme ) => ( {
  fullScreen: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  fullScreenText: {
    opacity: '.54',
    marginBottom: '1.5rem',
    fontWeight: 'bold',
    textAlign: 'center',
  },

  failureIcon: {
    ...icon,
    color: '#FF0000',
  },
} )