import FusionReactor from '../../lib/FusionReactor'


export const REINITIALIZE_STATE = 'screens/documents/REINITIALIZE_STATE'

const initialState = {}

export default FusionReactor.react( {
  initialState,
  reactions: {
    [ REINITIALIZE_STATE ]: {
      reducer: ( state, action ) => ( { ...initialState } ),
    },
  },
} )
