import FusionReactor from '../../lib/FusionReactor'


export const CHANGE_LOCALE_TO_EN = 'rigging/locales/CHANGE_LOCALE_TO_EN'
export const CHANGE_LOCALE_TO_SE = 'rigging/locales/CHANGE_LOCALE_TO_SE'
export const TOGGLE_LOCALE = 'rigging/locales/TOGGLE_LOCALE'

export default FusionReactor.react( {
  initialState: {
    currentLocale: localStorage.getItem( 'currentLocale' ) || 'se',
  },
  reactions: {
    [ CHANGE_LOCALE_TO_EN ]: {
      reducer: ( state, action ) => ( { ...state, currentLocale: 'en' } ),
    },

    [ CHANGE_LOCALE_TO_SE ]: {
      reducer: ( state, action ) => ( { ...state, currentLocale: 'se' } ),
    },

    [ TOGGLE_LOCALE ]: {
      reducer: ( state, action ) => ( {
        ...state,
        currentLocale: 'se' === state.currentLocale ? 'en' : 'se',
      } ),
    },
  },
} )
