import * as authReactions from './reactions'
import * as dataReactions from '../../providers/data/reactions'
import * as detectEnv from '../../lib/detectEnv'
import * as redirectReactions from '../../providers/redirect/reactions'
import _ from 'lodash'
import FusionReactor from '../../lib/FusionReactor'
import isEmail from 'validator/lib/isEmail'
import LinearProgress from '@material-ui/core/LinearProgress'
import moment from 'moment'
import React from 'react'


export default (ComponentToAuthenticate) => {
  return FusionReactor(
    class extends React.Component {
      componentDidMount() {
        const { reactWith } = this.props
        reactWith(dataReactions.REINITIALIZE_UPDATE_USER_STATE, { key: 'auth' })
        reactWith(authReactions.AUTH)
      }

      componentDidUpdate() {
        const {
          reactWith,
          location: {
            pathname,
          },
          state: {
            dataProvider: {
              user,
              stripeCustomerData: {
                activeSubscription,
              },
              getUserRequest: {
                ranBefore: getUserRanBefore,
                hasFailed: getUserHasFailed,
                hasSucceeded: getUserHasSucceeded,
              },
              updateUserRequests: {
                auth: {
                  ranBefore: updateUserRanBefore,
                },
              },
              getStripeCustomerDataRequest: {
                ranBefore: getStripeCustomerDataRanBefore,
                hasSucceeded: getStripeCustomerDataHasSucceeded,
              },
              getOptimizationDataRequest: {
                ranBefore: getOptimizationDataRanBefore,
                hasFailed: getOptimizationDataHasFailed,
              },
            },
            authRigging: {
              authRequest: {
                hasFailed: authHasFailed,
                hasSucceeded: authHasSucceeded,
              },
            },
          },
          t,
        } = this.props

        if ((authHasFailed || getUserHasFailed || getOptimizationDataHasFailed) && !detectEnv.isDevelopment()) {

          reactWith(redirectReactions.REDIRECT_TO_LOGIN)

        }
        else if (authHasSucceeded) {

          // Get user and optimization data
          if (!getUserRanBefore) {
            reactWith(dataReactions.GET_USER, { t })
          }

          // Get user Stripe data if they require a subscription
          if (getUserHasSucceeded) {
            if (!getOptimizationDataRanBefore) {
              const insuranceSourceIds = _.map(user.InsuranceItemList, (insuranceItem) => `"${insuranceItem.InsuranceSourceGet.id}"`)
              reactWith(dataReactions.GET_OPTIMIZATION_DATA, {
                params: {
                  ids: _.join(_.uniq(insuranceSourceIds), ',')
                }
              })
            }

            if (!getStripeCustomerDataRanBefore && user.requires_subscription) {
              reactWith(dataReactions.GET_STRIPE_CUSTOMER_DATA)
            }
          }

          if ((getUserHasSucceeded && user.requires_subscription && getStripeCustomerDataHasSucceeded) ||
            (getUserHasSucceeded && !user.requires_subscription)) {
            // Record the user's first time logging in
            if (!user.first_logged_in_at && !updateUserRanBefore) {
              reactWith(dataReactions.UPDATE_USER, {
                key: 'auth',
                params: {
                  id: user.id,
                  first_logged_in_at: moment().format()
                },
              })
            }
            else {
              if ((_.isNull(user.email) || !isEmail(user.email)) && pathname !== '/email-required') {
                // Collect user email if none was provided
                reactWith(redirectReactions.REDIRECT_TO_EMAIL_REQUIRED)
              }
              else if (!user.collected_agreements_at && !_.includes(['/email-required', '/agreements'], pathname)) {
                // Collect user info if it hasn't been collected
                reactWith(redirectReactions.REDIRECT_TO_AGREEMENTS)
              }
              else if (user.requires_subscription && user.trial_expired && _.isUndefined(activeSubscription) && !_.includes(['/email-required', '/agreements', '/intro', '/subscription'], pathname)) {
                // Ask users to subscribe if they don't have an active subscription
                reactWith(redirectReactions.REDIRECT_TO_SUBSCRIPTION)
              }
            }
          }

        }
      }

      inFlight = () => {
        const {
          state: {
            dataProvider: {
              user,
              stripeCustomerData,
              optimizationData,
            },
          },
        } = this.props

        // Use `user.id` instead of `authRequest.inFlight` to be able to auth on
        // every mount in the background, without displaying the linear progress below
        return _.isNull(user.id) ||
          (user.requires_subscription && _.isNull(stripeCustomerData.id)) ||
          !_.keys(optimizationData).length
      }

      render() {
        let Content
        if (this.inFlight()) {
          Content = <LinearProgress/>
        }
        else {
          Content = <ComponentToAuthenticate/>
        }

        return (
          <div className="Rigging AuthRigging">
            { Content }
          </div>
        )
      }
    },
  )
}
