import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import _ from 'lodash'
import React from 'react'
import FusionReactor from '../../../lib/FusionReactor'


const styles = ( theme ) => ( {
  image: {
    marginBottom: '3rem',
  },
  typography: {
    textAlign: 'center',
    marginBottom: '1rem',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '1rem',
  },
  button: {
    margin: `${theme.spacing.unit}px`,
  },
  loginInFlight: {
    marginLeft: `${theme.spacing.unit}px`,
  },
} )

class Component extends React.Component {
  render() {
    const {
      classes,
      t,
      params: {
        authenticationId,
      },
      loginPending,
      loginInFlight,
      disableAuthenticate,
      onAuthenticate,
    } = this.props

    let LoginPending
    if ( loginPending && !_.get( authenticationId, 'length', 0 ) ) {
      LoginPending = (
        <Typography key="typography" variant="subheading" gutterBottom className={ classes.typography }>
          { t( 'LoginScreen.BankIDScreen.StartBankIDApp' ) }
        </Typography>
      )
    }

    let LoginInFlight
    if ( loginInFlight ) {
      LoginInFlight = <CircularProgress classes={ { root: classes.loginInFlight } } size={ 20 }/>
    }

    return [
      <img
        key="image"
        className={ classes.image }
        src="/login/bankid_logo.png"
        alt="BankID"
      />,

      LoginPending,

      <div key="buttons" className={ classes.buttons }>
        <Button
          variant="raised"
          color="primary"
          disabled={ disableAuthenticate }
          onClick={ onAuthenticate }
          className={ classes.button }
        >
          { t( 'LoginScreen.BankIDScreen.AuthenticateButton' ) }
          { LoginInFlight }
        </Button>
      </div>,
    ]
  }
}

export default FusionReactor( withStyles( styles )( Component ) )
