import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import AlertDialog from '../..//things/alert_dialog/AlertDialog'
import isEmail from 'validator/lib/isEmail'
import isMobilePhone from 'validator/lib/isMobilePhone'
import FusionReactor from '../../lib/FusionReactor'
import * as dataReactions from '../../providers/data/reactions'
import Dashboard from '../../rigging/dashboard/Dashboard'
import Hero from '../../rigging/hero/Hero'
import ConfirmDialog from '../../things/confirm_dialog/ConfirmDialog'
import * as reactions from './reactions'
import styles from './styles'


class Component extends React.Component {

  //
  // Lifecycle

  componentDidMount() {
    const {
      reactWith,
      state: {
        dataProvider: {
          user,
        },
      },
    } = this.props

    reactWith(dataReactions.REINITIALIZE_UPDATE_USER_STATE, { key: 'account' })
    reactWith(reactions.REINITIALIZE_STATE)
    reactWith(reactions.HYDRATE_FORM_FIELDS, {
      formFields: _.pick(user, [
        'first_name',
        'last_name',
        'email',
        'phone_number',
      ]),
    })

    window.analytics.page('Account')
  }

  componentDidUpdate() {
    const {
      state: {
        accountScreen: {
          createPhoneNumberConfirmationRequest: {
            hasSucceeded: createPhoneNumberConfirmationHasSucceeded,
          },
          sendConfirmationSMSRequest: {
            ranBefore: sendConfirmationSMSRanBefore,
          },
        },
      },
    } = this.props

    if (createPhoneNumberConfirmationHasSucceeded && !sendConfirmationSMSRanBefore) {
      this.sendConfirmationSMS()
    }
  }

  getLatestPhoneNumberConfirmation = () => {
    const {
      state: {
        dataProvider: {
          user: {
            PhoneNumberConfirmationList,
          },
        },
      },
    } = this.props,
      filteredPhoneNumberConfirmationList = _.filter(PhoneNumberConfirmationList, (phoneNumberConfirmation) => phoneNumberConfirmation.confirmation_status !== 'COMPLETE'),
      sortedPhoneNumberConfirmationList = _.orderBy(filteredPhoneNumberConfirmationList, ['created_at'], ['desc'])

    return _.get(sortedPhoneNumberConfirmationList, '0')
  }

  //
  // Dashboard

  dashboardInFlight = () => {
    const {
      state: {
        dataProvider: {
          updateUserRequests: {
            account: {
              inFlight: updateUserInFlight,
            },
          },
        },
        accountScreen: {
          createPhoneNumberConfirmationRequest: {
            inFlight: createPhoneNumberConfirmationInFlight,
          },
          sendConfirmationSMSRequest: {
            inFlight: sendConfirmationSMSInFlight,
          },
          confirmPhoneNumberRequest: {
            inFlight: confirmPhoneNumberInFlight,
          },
        },
      },
    } = this.props

    return updateUserInFlight || createPhoneNumberConfirmationInFlight || sendConfirmationSMSInFlight || confirmPhoneNumberInFlight
  }

  //
  // Controlled fields

  changeFirstNameField = (e) => {
    const { reactWith } = this.props
    reactWith(reactions.CHANGE_FORM_FIELD, {
      field: 'first_name',
      value: e.target.value,
    })
  }

  changeLastNameField = (e) => {
    const { reactWith } = this.props
    reactWith(reactions.CHANGE_FORM_FIELD, {
      field: 'last_name',
      value: e.target.value,
    })
  }

  changeEmailField = (e) => {
    const { reactWith } = this.props
    reactWith(reactions.CHANGE_FORM_FIELD, {
      field: 'email',
      value: e.target.value,
    })
  }

  changePhoneNumberField = (e) => {
    const { reactWith } = this.props
    reactWith(reactions.CHANGE_FORM_FIELD, {
      field: 'phone_number',
      value: e.target.value,
    })
  }

  changeConfirmationCodeField = (e) => {
    const {
      reactWith,
      state: {
        accountScreen: {
          formFields,
        },
      },
    } = this.props,
      latestPhoneNumberConfirmation = this.getLatestPhoneNumberConfirmation()

    if (4 >= e.target.value.length) {
      reactWith(reactions.CHANGE_FORM_FIELD, {
        field: 'confirmation_code',
        value: e.target.value.toUpperCase(),
      })
    }

    let { phoneNumberConfirmationId, phoneNumberConfirmationCode } = formFields
    if (!_.isUndefined(latestPhoneNumberConfirmation) || !phoneNumberConfirmationId.length) {
      phoneNumberConfirmationId = latestPhoneNumberConfirmation.id
      phoneNumberConfirmationCode = latestPhoneNumberConfirmation.confirmation_code
    }

    if (e.target.value === phoneNumberConfirmationCode) {
      reactWith(reactions.CONFIRM_PHONE_NUMBER, {
        params: {
          id: phoneNumberConfirmationId,
        },
      })
    }
  }

  //
  // Confirm

  openUnsubscribeConfirm = () => {
    const { reactWith } = this.props
    reactWith(reactions.TOGGLE_CONFIRM, { confirm: 'unsubscribe' })
  }

  closeUnsubscribeConfirm = (e) => {
    const { reactWith } = this.props
    reactWith(reactions.TOGGLE_CONFIRM)
  }

  //
  // Form

  formIsValid = () => {
    return !this.formIsInvalid()
  }

  formIsInvalid = () => {
    return this.fieldsAreEmpty() || this.emailFieldIsInvalid() || this.phoneNumberFieldIsInvalid()
  }

  fieldsAreEmpty = () => {
    const {
      state: {
        accountScreen: {
          formFields,
        },
      },
    } = this.props,
      fields = _.pick(formFields, [
        'first_name',
        'last_name',
        'email',
      ])

    for (let f in fields) {
      if (!fields[f].toString().length) {
        return true
      }
    }

    return false
  }

  emailFieldIsInvalid = () => {
    const {
      state: {
        accountScreen: {
          formFields: {
            email,
          },
        },
      },
    } = this.props

    if (email.length) {
      return !isEmail(email)
    }
  }

  phoneNumberFieldIsInvalid = () => {
    const {
      state: {
        accountScreen: {
          formFields: {
            phone_number,
          },
        },
      },
    } = this.props

    if (phone_number.length) {
      return !isMobilePhone(phone_number) || 9 > phone_number.length
    }
  }

  updateUser = () => {
    const {
      reactWith,
      state: {
        dataProvider: {
          user,
        },
        accountScreen: {
          formFields,
        },
      },
    } = this.props,
      updateParams = {
        id: user.id,
        ..._.pick(formFields, ['first_name', 'last_name', 'email', 'phone_number']),
      }

    //
    // Phone number

    if (formFields.phone_number !== user.phone_number) {
      if (!formFields.phone_number.length) {
        //
        // Phone number deleted, can simply update

        updateParams.phone_number = 'n/a'
      }
      else {

        //
        // Confirm new phone number

        const latestPhoneNumberConfirmation = this.getLatestPhoneNumberConfirmation()
        if (_.isUndefined(latestPhoneNumberConfirmation) || formFields.phone_number !== latestPhoneNumberConfirmation.phone_number) {
          const confirmation_code = (Math.random().toString(36).substring(2, 4) + Math.random().toString(36).substring(2, 4)).toString().toUpperCase()

          reactWith(reactions.CREATE_PHONE_NUMBER_CONFIRMATION, {
            params: {
              user_id: user.id,
              phone_number: formFields.phone_number,
              confirmation_code,
            },
          })
        }
      }
    }

    //
    // Update user

    reactWith(dataReactions.UPDATE_USER, {
      key: 'account',
      params: {
        ...updateParams,
      },
    })
  }

  sendConfirmationSMS = () => {
    const {
      reactWith,
      t,
      state: {
        dataProvider: {
          user,
        },
        accountScreen: {
          formFields,
        },
      },
    } = this.props,
      latestPhoneNumberConfirmation = this.getLatestPhoneNumberConfirmation()

    let { phoneNumberConfirmationId, phoneNumberConfirmationCode } = formFields
    if (!_.isUndefined(latestPhoneNumberConfirmation) || !phoneNumberConfirmationId.length) {
      phoneNumberConfirmationId = latestPhoneNumberConfirmation.id
      phoneNumberConfirmationCode = latestPhoneNumberConfirmation.confirmation_code
    }

    const i18nParams = {
        ..._.pick(user, ['first_name', 'last_name']),
        code: phoneNumberConfirmationCode,
      },
      sendConfirmationParams = {
        id: phoneNumberConfirmationId,
        message: t('AccountScreen.ConfirmationSMS.Message', i18nParams),
      }

    reactWith(reactions.SEND_CONFIRMATION_SMS, {
      params: sendConfirmationParams,
    })
  }

  unsubscribe = () => {
    const {
      reactWith,
    } = this.props

    this.closeUnsubscribeConfirm()
    reactWith(reactions.UNSUBSCRIBE_FROM_STRIPE_PLAN)
  }

  //
  // Render

  render() {
    const {
      classes,
      t,
      state: {
        dataProvider: {
          user: {
            requires_subscription,
            trial_period_length,
            days_since_trial_started,
            trial_expired,
          },
          stripeCustomerData: {
            activeSubscription,
          },
          updateUserRequests: {
            account: {
              hasSucceeded: updateUserHasSucceeded,
            },
          },
        },
        accountScreen: {
          formFields,
          confirm: {
            unsubscribe: showUnsubscribeConfirm,
          },
          confirmPhoneNumberRequest: {
            hasSucceeded: confirmPhoneNumberHasSucceeded,
          },
          unsubscribeFromStripePlanRequest: {
            hasSucceeded: unsubscribeFromStripePlanHasSucceeded,
            inFlight: unsubscribeFromStripePlanInFlight,
          },
        },
      },
    } = this.props,
      latestPhoneNumberConfirmation = this.getLatestPhoneNumberConfirmation()

    let SaveButton = (
      <Button
        color="inherit"
        className={ classes.nextButton }
        disabled={ this.formIsInvalid() || this.dashboardInFlight() }
        onClick={ this.updateUser }
      >
        { t( 'AccountScreen.SaveButton' ) }
      </Button>
    )

    //
    // Alert

    let Alert, alertTitle = '',
      alertText = ''

    if (updateUserHasSucceeded) {
      alertTitle = t('AccountScreen.UpdateSuccessAlert.Title')
      alertText = t('AccountScreen.UpdateSuccessAlert.Text')
    }
    else if (confirmPhoneNumberHasSucceeded) {
      alertTitle = t('AccountScreen.ConfirmPhoneNumberSuccessAlert.Title')
      alertText = t('AccountScreen.ConfirmPhoneNumberSuccessAlert.Text')
    }

    if (alertTitle.length) {
      Alert = (
        <AlertDialog
          open={ true }
          title={ alertTitle }
          text={ alertText }
          labelOk={ t( 'AccountScreen.Alert.OkButton' ) }
          onClose={ () => window.location.reload() }
          onOk={ () => window.location.reload() }
        />
      )
    }

    //
    // Mobile phone number confirmation

    let PhoneNumberConfirmation
    if (latestPhoneNumberConfirmation) {
      PhoneNumberConfirmation = [
        <Typography key="confirm-blurb" variant="subheading" className={ classes.confirmBlurb }>
          { t( 'AccountScreen.ConfirmPhoneNumber.Blurb', { phone: latestPhoneNumberConfirmation.phone_number } ) }
        </Typography>,

        <div key="confirm-field" className={ classes.formRow }>
          <TextField
            className={ classes.confirmFormControl }
            placeholder={ t( 'AccountScreen.ConfirmPhoneNumber.FieldPlaceholder' ) }
            type="text"
            value={ formFields.confirmation_code }
            onChange={ this.changeConfirmationCodeField }
          />
        </div>,

        <Button
          key="confirm-resend"
          className={ classes.resendButton }
          variant="raised"
          color="primary"
          onClick={ this.sendConfirmationSMS }
        >
          { t( 'AccountScreen.ConfirmPhoneNumber.ResendButton' ) }
        </Button>,
      ]
    }

    //
    // Active subscription info

    let ActiveTrial, ActiveSubscription, UnsubscribeButton, UnsubscribeConfirm
    if (!_.isUndefined(activeSubscription)) {
      let {
        plan,
        current_period_end: planRenewDate,
        cancel_at_period_end: planWillBeCanceled,
      } = activeSubscription, { nickname: planName } = plan

      let planI18nKey = `AccountScreen.Plans`,
        blurbI18nKey = `AccountScreen.Subscription`

      if ('basic_monthly' === planName) {
        planI18nKey = `${planI18nKey}.Basic`
      }
      else if ('premium_monthly' === planName) {
        planI18nKey = `${planI18nKey}.Premium`
      }

      if (planWillBeCanceled || unsubscribeFromStripePlanHasSucceeded) {
        blurbI18nKey = `${blurbI18nKey}.WillBeCanceled`
      }
      else {
        blurbI18nKey = `${blurbI18nKey}.WillBeRenewed`
        UnsubscribeButton = (
          <Button
            variant="contained"
            color="primary"
            className={ classes.cancelSubscriptionButton }
            disabled={ unsubscribeFromStripePlanInFlight }
            onClick={ this.openUnsubscribeConfirm }
          >
            { t( 'AccountScreen.Subscription.UnsubscribeButton' ) }
            { unsubscribeFromStripePlanInFlight ? <CircularProgress
              classes={ { root: classes.cancelSubscriptionInFlight } }
              size={ 20 }
            /> : null }
          </Button>
        )
      }

      if (showUnsubscribeConfirm) {
        UnsubscribeConfirm = (
          <ConfirmDialog
            open={ true }
            title={ t( 'AccountScreen.Subscription.UnsubscribeConfirm.Title' ) }
            text={ t( 'AccountScreen.Subscription.UnsubscribeConfirm.Message' ) }
            labelCancel={ t( 'AccountScreen.Subscription.UnsubscribeConfirm.CancelButton' ) }
            labelConfirm={ t( 'AccountScreen.Subscription.UnsubscribeConfirm.ConfirmButton' ) }
            onCancel={ this.closeUnsubscribeConfirm }
            onClose={ this.closeUnsubscribeConfirm }
            onConfirm={ this.unsubscribe }
          />
        )
      }

      ActiveSubscription = (
        <div className={ classes.activeSubscription }>
          <Typography variant="subheading">
            { t( blurbI18nKey, {
              plan: t( planI18nKey ),
              date: moment( planRenewDate * 1000 ).format( 'YYYY-MM-DD' ),
            } ) }
          </Typography>

          { UnsubscribeButton }

          { UnsubscribeConfirm }
        </div>
      )
    }
    else if (requires_subscription && trial_period_length && !trial_expired) {
      ActiveTrial = (
        <div className={ classes.activeTrial }>
          <Typography variant="subheading">
            { t( 'AccountScreen.Trial', { trialRemaining: trial_period_length - days_since_trial_started } ) }
          </Typography>
        </div>
      )
    }

    let first_nameFieldProps
    if (!formFields.first_name.length) {
      first_nameFieldProps = {
        error: true,
        helperText: t('AccountScreen.Form.FirstNameField.Errors.Empty'),
      }
    }

    let last_nameFieldProps
    if (!formFields.last_name.length) {
      last_nameFieldProps = {
        error: true,
        helperText: t('AccountScreen.Form.LastNameField.Errors.Empty'),
      }
    }

    let emailFieldProps
    if (!formFields.email.length) {
      emailFieldProps = {
        error: true,
        helperText: t('AccountScreen.Form.EmailField.Errors.Empty'),
      }
    }
    else if (this.emailFieldIsInvalid()) {
      emailFieldProps = {
        error: true,
        helperText: t('AccountScreen.Form.EmailField.Errors.Invalid'),
      }
    }

    let phoneNumberProps
    if (formFields.phone_number.length && this.phoneNumberFieldIsInvalid()) {
      phoneNumberProps = {
        error: true,
        helperText: t('AccountScreen.Form.PhoneNumberField.Errors.Invalid'),
      }
    }

    //
    // Return

    return (
      <Hero>
        <Dashboard
          title={ t( 'AccountScreen.Title' ) }
          left="drawer"
          right={ SaveButton }
          inFlight={ this.dashboardInFlight() }
        >
          <div className="Screen AccountScreen">
            { Alert }

            <div className={ classes.formRow }>
              <TextField
                className={ classes.formControl }
                label={ t( 'AccountScreen.Form.FirstNameField.Label' ) }
                placeholder={ t( 'AccountScreen.Form.FirstNameField.Placeholder' ) }
                type="text"
                fullWidth
                required
                value={ formFields.first_name }
                onChange={ this.changeFirstNameField }
                { ...first_nameFieldProps }
              />
            </div>

            <div className={ classes.formRow }>
              <TextField
                className={ classes.formControl }
                label={ t( 'AccountScreen.Form.LastNameField.Label' ) }
                placeholder={ t( 'AccountScreen.Form.LastNameField.Placeholder' ) }
                type="text"
                fullWidth
                required
                value={ formFields.last_name }
                onChange={ this.changeLastNameField }
                { ...last_nameFieldProps }
              />
            </div>

            <div className={ classes.formRow }>
              <TextField
                className={ classes.formControl }
                label={ t( 'AccountScreen.Form.EmailField.Label' ) }
                placeholder={ t( 'AccountScreen.Form.EmailField.Placeholder' ) }
                type="email"
                fullWidth
                required
                value={ formFields.email }
                onChange={ this.changeEmailField }
                { ...emailFieldProps }
              />
            </div>

            <div className={ classes.formRow }>
              <TextField
                className={ classes.formControl }
                label={ t( 'AccountScreen.Form.PhoneNumberField.Label' ) }
                placeholder={ t( 'AccountScreen.Form.PhoneNumberField.Placeholder' ) }
                type="tel"
                fullWidth
                value={ formFields.phone_number }
                onChange={ this.changePhoneNumberField }
                { ...phoneNumberProps }
              />
            </div>

            { PhoneNumberConfirmation }

            { ActiveSubscription }

            { ActiveTrial }
          </div>
        </Dashboard>
      </Hero>
    )
  }
}

export default FusionReactor(withStyles(styles)(Component))
