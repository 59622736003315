import * as dataReactions from '../../providers/data/reactions'
import * as requestStates from '../../lib/requestStates'
import * as Sentry from '@sentry/browser'
import _ from 'lodash'
import api from '../../lib/api/api'
import btoa from 'btoa'
import FusionReactor from '../../lib/FusionReactor'
import toggleObjectProperties from '../../lib/toggleObjectProperties'
import { call, put } from 'redux-saga/effects'


export const REINITIALIZE_STATE = 'screens/order/REINITIALIZE_STATE'

export const HYDRATE_ORDER_PARAMS = 'screens/order/HYDRATE_ORDER_PARAMS'

export const TOGGLE_PANEL = 'screens/order/TOGGLE_PANEL'

export const GET_SIGNING_STATUS = 'screens/order/GET_SIGNING_STATUS'
export const GET_SIGNING_STATUS_REQUEST = 'screens/order/GET_SIGNING_STATUS_REQUEST'
export const GET_SIGNING_STATUS_SUCCESS = 'screens/order/GET_SIGNING_STATUS_SUCCESS'
export const GET_SIGNING_STATUS_FAILURE = 'screens/order/GET_SIGNING_STATUS_FAILURE'

export const PREPARE_ORDER_DOCUMENT = 'screens/order/PREPARE_ORDER_DOCUMENT'
export const PREPARE_ORDER_DOCUMENT_REQUEST = 'screens/order/PREPARE_ORDER_DOCUMENT_REQUEST'
export const PREPARE_ORDER_DOCUMENT_SUCCESS = 'screens/order/PREPARE_ORDER_DOCUMENT_SUCCESS'
export const PREPARE_ORDER_DOCUMENT_FAILURE = 'screens/order/PREPARE_ORDER_DOCUMENT_FAILURE'

export const SIGN_ORDER_DOCUMENT = 'screens/order/SIGN_ORDER_DOCUMENT'
export const SIGN_ORDER_DOCUMENT_REQUEST = 'screens/order/SIGN_ORDER_DOCUMENT_REQUEST'
export const SIGN_ORDER_DOCUMENT_SUCCESS = 'screens/order/SIGN_ORDER_DOCUMENT_SUCCESS'
export const SIGN_ORDER_DOCUMENT_FAILURE = 'screens/order/SIGN_ORDER_DOCUMENT_FAILURE'

const initialState = {
  orderParams: {
    user_id: null,
    insurance_item_id: null,
    when_to_retire: null,
    risk_level: null,
    fund_version: null,
    portfolio_fee: null,
    fund_list: null,
  },

  orderDocument: {
    document: '',
    signingLink: '',
    signing_status: '',
    order: null,
  },

  panels: {
    sign: true,
  },

  prepareOrderDocumentRequest: { ...requestStates.initialState },
  signOrderDocumentRequest: { ...requestStates.initialState },
  getSigningStatusRequest: { ...requestStates.initialState },
  e: '',
}

export default FusionReactor.react({
  initialState,
  reactions: {
    [REINITIALIZE_STATE]: {
      reducer: (state, action) => ({ ...initialState }),
    },

    [HYDRATE_ORDER_PARAMS]: {
      reducer: (state, action) => ({
        ...state,
        orderParams: {
          ...action.params,
        },
      }),
    },

    //
    // Panels

    [TOGGLE_PANEL]: {
      reducer: (state, action) => ({
        ...state,
        panels: toggleObjectProperties(state.panels, action.panel),
      }),
    },

    //
    // Get document signing status

    [GET_SIGNING_STATUS]: {
      saga: function*(action) {
        yield put(FusionReactor.reactWith(GET_SIGNING_STATUS_REQUEST, action))
      },
    },

    [GET_SIGNING_STATUS_REQUEST]: {
      reducer: (state, action) => ({
        ...state,
        getSigningStatusRequest: { ...requestStates.requestState },
      }),

      saga: function*(action) {
        try {
          const { params } = action, { OperationResult: signingResult } = yield call(api.brandsign.signing_status, params.signing_task_id), { Status: result } = signingResult

          // TODO: update signing status + download PDF document in a single API call
          // yield call( api.orders.update, action.user_id, action.orderId, {
          //   signing_status: signing_status,
          // } )

          yield put(FusionReactor.reactWith(GET_SIGNING_STATUS_SUCCESS, { result }))
        }
        catch (e) {
          Sentry.captureException(e)
          yield put(FusionReactor.reactWith(GET_SIGNING_STATUS_FAILURE, { e: _.get(e, 'message', '') }))
        }
      },
    },

    [GET_SIGNING_STATUS_SUCCESS]: {
      reducer: (state, action) => {
        const { result } = action

        return {
          ...state,
          orderDocument: {
            ...state.orderDocument,
            signing_status: result,
          },
          getSigningStatusRequest: { ...requestStates.successState },
        }
      },

      saga: function*(action) {
        const { result } = action

        let event = 'Order canceled'
        if ('COMPLETE' === result) {
          event = 'Order signed'
        }

        yield put(FusionReactor.reactWith(dataReactions.EVENT, {
          params: {
            event,
          },
        }))
      },
    },

    [GET_SIGNING_STATUS_FAILURE]: {
      reducer: (state, action) => ({
        ...state,
        getSigningStatusRequest: { ...requestStates.failureState },
        e: action.e,
      }),
    },

    //
    // Prepare document

    [PREPARE_ORDER_DOCUMENT]: {
      saga: function*(action) {
        yield put(FusionReactor.reactWith(PREPARE_ORDER_DOCUMENT_REQUEST, action))
      },
    },

    [PREPARE_ORDER_DOCUMENT_REQUEST]: {
      reducer: (state, action) => ({
        ...state,
        prepareOrderDocumentRequest: { ...requestStates.requestState },
      }),

      saga: function*(action) {
        try {
          const { params } = action,
          result = yield call(api.prepareOrderDocument, params)

          yield put(FusionReactor.reactWith(PREPARE_ORDER_DOCUMENT_SUCCESS, { result }))
        }
        catch (e) {
          Sentry.captureException(e)
          yield put(FusionReactor.reactWith(PREPARE_ORDER_DOCUMENT_FAILURE, { e: _.get(e, 'message', '') }))
        }
      },
    },

    [PREPARE_ORDER_DOCUMENT_SUCCESS]: {
      reducer: (state, action) => {
        const { result } = action

        return {
          ...state,
          orderDocument: {
            ...state.orderDocument,
            document: result.document,
          },
          prepareOrderDocumentRequest: { ...requestStates.successState },
        }
      },
    },

    [PREPARE_ORDER_DOCUMENT_FAILURE]: {
      reducer: (state, action) => ({
        ...state,
        prepareOrderDocumentRequest: { ...requestStates.failureState },
        e: action.e,
      }),
    },

    //
    // Sign document

    [SIGN_ORDER_DOCUMENT]: {
      saga: function*(action) {
        yield put(FusionReactor.reactWith(SIGN_ORDER_DOCUMENT_REQUEST, action))
      },
    },

    [SIGN_ORDER_DOCUMENT_REQUEST]: {
      reducer: (state, action) => ({
        ...state,
        signOrderDocumentRequest: { ...requestStates.requestState },
      }),

      saga: function*(action) {
        try {
          const { params } = action,
          { OperationResult: signingResult } = yield call(api.brandsign.signDocument, params.document, params.insuranceSourceSSN, params.userSSN),
          { DirectLink: signingLink } = signingResult.DocumentsToSign[0].RequestedSignatories[0],
            createOrderResult = yield call(api.mutation, `
              mutation OrderCreate {
                OrderCreate(
                  user_id: "${params.user_id}",
                  insurance_item_id: "${params.insurance_item_id}",
                  signed_document_link: "${signingLink}",
                  signing_task_id: "${signingResult.SigningTaskGuid}",
                  signing_expires_at: "${signingResult.ExpirationTime}",
                  signing_status: "${signingResult.Status}",
                  when_to_retire: ${params.when_to_retire},
                  risk_level: ${params.risk_level},
                  fund_version: "${params.fund_version}",
                  holding: ${params.holding},
                  save_time: ${params.save_time},
                  portfolio_fee: ${params.portfolio_fee},
                  fund_list: "${btoa( JSON.stringify( params.fund_list ) )}",
                  max_return: ${params.max_return},
                  exp_return: ${params.exp_return},
                  min_return: ${params.min_return}
                ) {
                  id,
                  signing_task_id,
                  signing_status,
                  signed_document_link,
                  created_at
                }
              }
            `)

          yield put(FusionReactor.reactWith(SIGN_ORDER_DOCUMENT_SUCCESS, {
            result: {
              signingLink,
              ...createOrderResult,
            },
          }))
        }
        catch (e) {
          Sentry.captureException(e)
          yield put(FusionReactor.reactWith(SIGN_ORDER_DOCUMENT_FAILURE, { e: _.get(e, 'message', '') }))
        }
      },
    },

    [SIGN_ORDER_DOCUMENT_SUCCESS]: {
      reducer: (state, action) => {
        const { signingLink } = action.result,
          order = _.get(action.result, 'data.OrderCreate', null)

        return {
          ...state,
          orderDocument: {
            ...state.orderDocument,
            signingLink,
            order,
          },
          signOrderDocumentRequest: { ...requestStates.successState },
        }
      },

      saga: function*(action) {
        yield put(FusionReactor.reactWith(dataReactions.EVENT, {
          params: {
            event: 'Order created',
          },
        }))
      },
    },

    [SIGN_ORDER_DOCUMENT_FAILURE]: {
      reducer: (state, action) => ({
        ...state,
        signOrderDocumentRequest: { ...requestStates.failureState },
        e: action.e,
      }),
    },
  },
})
