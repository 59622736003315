import * as detectEnv from './detectEnv'


export default (log, showLog) => {
  if (detectEnv.isProduction() || !showLog) {
    return
  }

  console.log(log)
}
