import FusionReactor from '../../lib/FusionReactor'


export const REINITIALIZE_STATE = 'rigging/dashboard/REINITIALIZE_STATE'

export const OPEN_DRAWER = 'rigging/dashboard/OPEN_DRAWER'
export const CLOSE_DRAWER = 'rigging/dashboard/CLOSE_DRAWER'

const initialState = {
  drawerOpen: false,
}

export default FusionReactor.react( {
  initialState,
  reactions: {
    [ REINITIALIZE_STATE ]: {
      reducer: ( state, action )=> ( { ...initialState } ),
    },

    [ OPEN_DRAWER ]: {
      reducer: ( state, action ) => ( { ...state, drawerOpen: true } ),
    },

    [ CLOSE_DRAWER ]: {
      reducer: ( state, action ) => ( { ...state, drawerOpen: false } ),
    },
  },
} )
