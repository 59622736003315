export const initialState = {
  inFlight: false,
  hasFinished: null,
  ranBefore: false,
  hasSucceeded: null,
  hasFailed: null,
  error: '',
}

export const requestState = {
  inFlight: true,
  hasFinished: false,
  ranBefore: true,
  hasSucceeded: null,
  hasFailed: null,
  error: '',
}

export const successState = {
  inFlight: false,
  hasFinished: true,
  ranBefore: true,
  hasSucceeded: true,
  hasFailed: false,
  error: '',
}

export const failureState = {
  inFlight: false,
  hasFinished: true,
  ranBefore: true,
  hasSucceeded: false,
  hasFailed: true,
  error: '',
}
