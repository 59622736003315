import _ from 'lodash'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { combineReducers } from 'redux'
import { takeEvery } from 'redux-saga/effects'


const FusionReactor = ( Component, opt = {} ) => {

  //
  // Option defaults

  const defaultOptions = {
      withIntl: true,
    },
    options = {
      ...defaultOptions,
      ...opt,
    }

  //
  // Map the entire Redux store to a state prop which will be available to your Component

  const mapStoreToProps = ( store ) => ( { state: { ...store } } )

  //
  // Map dispatch to props so components can dispatch actions directly

  const mapDispatchToProps = ( dispatch ) => ( {
    dispatch: ( action ) => {
      if ( _.isArray( action ) ) {
        return action.reduce(
          ( accumulator, currentAction, currentIndex ) =>
            ( accumulator[ currentIndex ] = dispatch( currentAction ) ),
        )
      } else {
        return dispatch( action )
      }
    },
  } )

  //
  // Map your Component's own props on top of the above

  const mapOwnProps = ( mappedStore, mappedDispatch, ownProps ) => {
    const props = {
      ...mappedStore,
      ...mappedDispatch,
      ...ownProps,
    }

    // Make React Router's params props easier to access
    props.params = ownProps.match.params

    return props
  }

  //
  // Wrap with own component that extends functionality

  let FusionComponent = class extends React.Component {
    // Let Component dispatch reactions
    reactWith = ( actionType, actionParams ) => {
      const { dispatch } = this.props
      dispatch( FusionReactor.reactWith( actionType, actionParams ) )
    }

    // Make ReactIntl's translation method, easier to access (see below for injectIntl HOC)
    t = ( id, params = {} ) => this.props.intl.formatMessage( { id }, params )
    thtml = ( id, params = {} ) => this.props.intl.formatHTMLMessage( { id }, params )

    render() {
      const props = {
        ...this.props,
        reactWith: this.reactWith,
      }

      if ( true === options.withIntl ) {
        props.t = this.t
        props.thtml = this.thtml
      }

      return <Component { ...props } />
    }
  }

  //
  // injectIntl HOC to make the intl prop available

  if ( true === options.withIntl ) {
    FusionComponent = injectIntl( FusionComponent )
  }

  //
  // Connect to Redux and Router

  FusionComponent = withRouter(
    connect( mapStoreToProps, mapDispatchToProps, mapOwnProps )( FusionComponent ),
  )

  return FusionComponent
}

//
// Create actions used to dispatch reactions

FusionReactor.reactWith = ( actionType, actionParams = {} ) => ( {
  ...actionParams,
  type: actionType,
} )

//
// Reaction creator, used to create reducers and map sagas to actions

FusionReactor.react = ( reactWith ) => {
  const {
    initialState,
    reactions,
  } = reactWith

  return {
    reducer: ( state = initialState, action ) => {
      let reactionTypes = Object.keys( reactions )
      for ( let i = reactionTypes.length - 1; i >= 0; i-- ) {
        const actionType = reactionTypes[ i ]
        const reaction = reactions[ actionType ]
        if ( _.isFunction( reaction.reducer ) && actionType === action.type ) {
          return reaction.reducer( state, action )
        }
      }

      return state
    },
    sagas: _.transform( reactions, ( accumulator, reaction, actionType ) => {
      if ( _.isFunction( reaction.saga ) ) {
        return ( accumulator[ actionType ] = reaction.saga )
      }
    } ),
  }
}

//
// Combine a hash of reducers created in the reaction creator above

FusionReactor.combineReducers = ( reducers ) =>
  combineReducers(
    _.transform(
      reducers,
      ( accumulator, reducer, index ) => ( accumulator[ index ] = reducer.reducer ),
    ),
  )

//
// Run sagas created in the reaction creator above

FusionReactor.initializeSagas = ( sagaMiddleware, sagas ) => {
  sagaMiddleware.run( function* () {
    for ( let i = sagas.length - 1; i >= 0; i-- ) {
      let reactionTypes = Object.keys( sagas[ i ].sagas )
      for ( let j = reactionTypes.length - 1; j >= 0; j-- ) {
        const actionType = reactionTypes[ j ]
        const saga = sagas[ i ].sagas[ actionType ]
        yield takeEvery( actionType, saga )
      }
    }
  } )
}

export default FusionReactor
