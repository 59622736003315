import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { withStyles } from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Typography from '@material-ui/core/Typography'
import StarIcon from '@material-ui/icons/Star'
import _ from 'lodash'
import React from 'react'
import SwipeableViews from 'react-swipeable-views'
import FusionReactor from '../../../lib/FusionReactor'


const styles = ( theme ) => ( {
  tabContent: {},
  blurb: {
    margin: `${theme.spacing.unit * 3}px`,
  },
  benefitList: {
    margin: `${theme.spacing.unit * 3}px 0`,
  },
  benefitListItem: {
    alignItems: 'normal',
  },
  benefitListItemIcon: {
    margin: `${theme.spacing.unit}px`,
    marginTop: 0,
  },
  benefitListItemText: {},
  checkoutButton: {
    display: 'block',
    margin: `0 auto`,
  },
} )

class Component extends React.Component {
  render() {
    const {
      classes,
      t,
      activeTab,
      onChangeTab,
      basicPlanBlurb,
      premiumPlanBlurb,
      basicPlanBenefits,
      premiumPlanBenefits,
      basicCheckoutButtonLabel,
      premiumCheckoutButtonLabel,
      onBasicCheckout,
      onPremiumCheckout,
    } = this.props

    //
    // i18n

    let BasicPlanBenefits = [],
      PremiumPlanBenefits = [],
      BasicPlanTabContent = [],
      PremiumPlanTabContent = []

    //
    // Blurbs

    if ( basicPlanBlurb.length ) {
      BasicPlanTabContent.push(
        <Typography
          key="basic-plan-blurb"
          variant="subheading"
          className={ classes.blurb }>
          { basicPlanBlurb }
        </Typography>,
      )
    }

    if ( premiumPlanBlurb.length ) {
      PremiumPlanTabContent.push(
        <Typography
          key="premium-plan-blurb"
          variant="subheading"
          className={ classes.blurb }>
          { premiumPlanBlurb }
        </Typography>,
      )
    }

    //
    // Benefits

    BasicPlanBenefits = _.map( basicPlanBenefits, ( benefit, index ) =>
      <ListItem key={ `basic-plan-benefit-${index}` } className={ classes.benefitListItem }>
        <ListItemIcon className={ classes.benefitListItemIcon }>
          <StarIcon/>
        </ListItemIcon>
        <ListItemText className={ classes.benefitListItemText }>
          { benefit }
        </ListItemText>
      </ListItem>,
    )

    if ( BasicPlanBenefits.length ) {
      BasicPlanBenefits = (
        <List key="basic-plan-benefits" className={ classes.benefitList }>
          { BasicPlanBenefits }
        </List>
      )

      BasicPlanTabContent.push( BasicPlanBenefits )
    }

    PremiumPlanBenefits = _.map( premiumPlanBenefits, ( benefit, index ) =>
      <ListItem key={ `premium-plan-benefit-${index}` } className={ classes.benefitListItem }>
        <ListItemIcon className={ classes.benefitListItemIcon }>
          <StarIcon/>
        </ListItemIcon>
        <ListItemText className={ classes.benefitListItemText }>
          { benefit }
        </ListItemText>
      </ListItem>,
    )

    if ( PremiumPlanBenefits.length ) {
      PremiumPlanBenefits = (
        <List key="premium-plan-benefits" className={ classes.benefitList }>
          { PremiumPlanBenefits }
        </List>
      )

      PremiumPlanTabContent.push( PremiumPlanBenefits )
    }

    //
    // Checkout buttons

    BasicPlanTabContent.push(
      <Button
        key="basic-plan-checkout-button"
        variant="contained"
        color="primary"
        className={ classes.checkoutButton }
        onClick={ onBasicCheckout }>
        { basicCheckoutButtonLabel }
      </Button>,
    )

    PremiumPlanTabContent.push(
      <Button
        key="premium-plan-checkout-button"
        variant="contained"
        color="primary"
        className={ classes.checkoutButton }
        onClick={ onPremiumCheckout }>
        { premiumCheckoutButtonLabel }
      </Button>,
    )

    //
    // Return

    return (
      <div>
        <AppBar position="static" color="default">
          <Tabs
            value={ activeTab }
            onChange={ ( evt, index ) => onChangeTab( index ) }
            indicatorColor="primary"
            textColor="primary"
            fullWidth
          >
            <Tab label={ t( 'SubscriptionScreen.BasicPlan.Title' ) }/>
            <Tab label={ t( 'SubscriptionScreen.PremiumPlan.Title' ) }/>
          </Tabs>
        </AppBar>
        <SwipeableViews
          index={ activeTab }
          onChangeIndex={ onChangeTab }
        >
          <div className={ classes.tabContent }>
            { BasicPlanTabContent }
          </div>

          <div className={ classes.tabContent }>
            { PremiumPlanTabContent }
          </div>
        </SwipeableViews>
      </div>
    )
  }
}

export default FusionReactor( withStyles( styles )( Component ) )
