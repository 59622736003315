import Button from '@material-ui/core/Button/Button'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import _ from 'lodash'
import React from 'react'
import isEmail from 'validator/lib/isEmail'
import FusionReactor from '../../lib/FusionReactor'
import * as redirectReactions from '../../providers/redirect/reactions'
import Dashboard from '../../rigging/dashboard/Dashboard'
import Hero from '../../rigging/hero/Hero'
import AlertDialog from '../../things/alert_dialog/AlertDialog'
import * as reactions from './reactions'
import styles from './styles'


class Component extends React.Component {

  //
  // Lifecycle

  componentDidMount() {
    const {
      reactWith,
      state: {
        dataProvider: {
          user,
        },
      },
    } = this.props,
      latestEmailConfirmation = this.getLatestEmailConfirmation()

    reactWith(reactions.REINITIALIZE_STATE)

    // User already has an email
    if (!_.isNull(user.email) && isEmail(user.email)) {
      reactWith(redirectReactions.REDIRECT_TO_HOME)
    }

    if (_.isUndefined(latestEmailConfirmation)) {
      this.showEmailScreen()
    }
    else {
      this.showConfirmationScreen()
    }

    window.analytics.page('Email Required')
  }

  componentDidUpdate(prevProps) {
    const {
      state: {
        emailRequiredScreen: {
          alerts: {
            failure: showFailureAlert,
          },
          screens: {
            confirmation: showConfirmationScreen,
          },
          formFields: {
            emailConfirmationId,
          },
          createEmailConfirmationRequest: {
            hasFailed: createEmailConfirmationHasFailed,
          },
          sendConfirmationEmailRequest: {
            hasSucceeded: sendConfirmationEmailHasSucceeded,
            hasFailed: sendConfirmationEmailHasFailed,
          },
        },
      },
    } = this.props, {
      state: {
        emailRequiredScreen: {
          formFields: {
            emailConfirmationId: prevEmailConfirmationId,
          },
        },
      },
    } = prevProps

    if (!showFailureAlert && (createEmailConfirmationHasFailed || sendConfirmationEmailHasFailed)) {
      this.showFailureAlert()
    }

    if (!showConfirmationScreen && sendConfirmationEmailHasSucceeded) {
      this.showConfirmationScreen()
    }

    if (prevEmailConfirmationId !== emailConfirmationId && emailConfirmationId.length) {
      this.sendConfirmationEmail()
    }
  }

  getLatestEmailConfirmation = () => {
    const {
      state: {
        dataProvider: {
          user: {
            EmailConfirmationList,
          },
        },
      },
    } = this.props,
      sortedEmailConfirmationList = _.orderBy(EmailConfirmationList, ['created_at'], ['desc'])

    return _.get(sortedEmailConfirmationList, '0')
  }

  //
  // Dashboard

  dashboardInFlight = () => {
    const {
      state: {
        emailRequiredScreen: {
          createEmailConfirmationRequest: {
            inFlight: createEmailConfirmationInFlight,
          },
          sendConfirmationEmailRequest: {
            inFlight: sendConfirmationEmailInFlight,
          },
        },
      },
    } = this.props

    return createEmailConfirmationInFlight || sendConfirmationEmailInFlight
  }

  //
  // Alerts

  showFailureAlert = () => {
    const { reactWith } = this.props
    reactWith(reactions.TOGGLE_ALERT, { alert: 'failure' })
  }

  reinitializeEmailConfirmations = () => {
    const { reactWith } = this.props
    reactWith(reactions.REINITIALIZE_EMAIL_CONFIRMATIONS)
  }

  //
  // Screens

  showEmailScreen = () => {
    const { reactWith } = this.props
    reactWith(reactions.TOGGLE_SCREEN, { screen: 'email' })
  }

  showConfirmationScreen = () => {
    const { reactWith } = this.props
    reactWith(reactions.TOGGLE_SCREEN, { screen: 'confirmation' })
  }

  //
  // Controlled fields

  changeEmailField = (e) => {
    const { reactWith } = this.props
    reactWith(reactions.CHANGE_FORM_FIELD, {
      field: 'email',
      value: e.target.value,
    })
  }

  //
  // Form

  formIsValid = () => {
    return !this.formIsInvalid()
  }

  formIsInvalid = () => {
    return this.fieldsAreEmpty() || this.emailFieldIsInvalid()
  }

  fieldsAreEmpty = () => {
    const {
      state: {
        emailRequiredScreen: {
          formFields: {
            email,
          },
        },
      },
    } = this.props

    return !email.length
  }

  emailFieldIsInvalid = () => {
    const {
      state: {
        emailRequiredScreen: {
          formFields: {
            email,
          },
        },
      },
    } = this.props

    if (email.length) {
      return !isEmail(email)
    }
  }

  confirmEmail = () => {
    const {
      reactWith,
      state: {
        dataProvider: {
          user: {
            id: user_id,
          },
        },
        emailRequiredScreen: {
          formFields: {
            email,
          },
        },
      },
    } = this.props

    reactWith(reactions.CREATE_EMAIL_CONFIRMATION, {
      params: {
        user_id,
        email,
      },
    })
  }

  sendConfirmationEmail = () => {
    const {
      reactWith,
      thtml,
      state: {
        dataProvider: {
          user,
        },
        emailRequiredScreen: {
          formFields,
        },
      },
    } = this.props,
      latestEmailConfirmation = this.getLatestEmailConfirmation()

    let emailConfirmationId = formFields.emailConfirmationId
    if (!_.isUndefined(latestEmailConfirmation) || !emailConfirmationId.length) {
      emailConfirmationId = latestEmailConfirmation.id
    }

    const i18nParams = {
        ..._.pick(user, ['first_name', 'last_name', 'email']),
        link: `${window.location.origin}/email-confirm/${emailConfirmationId}`,
      },
      sendConfirmationParams = {
        id: emailConfirmationId,
        subject: thtml('EmailRequiredScreen.ConfirmationEmail.Subject', i18nParams),
        htmlBody: thtml('EmailRequiredScreen.ConfirmationEmail.HtmlBody', i18nParams),
        textBody: thtml('EmailRequiredScreen.ConfirmationEmail.TextBody', i18nParams),
      }

    reactWith(reactions.SEND_CONFIRMATION_EMAIL, {
      params: sendConfirmationParams,
    })
  }

  //
  // Render

  render() {
    const {
      classes,
      t,
      state: {
        emailRequiredScreen: {
          alerts: {
            failure: showFailureAlert,
          },
          screens: {
            email: showEmailScreen,
            confirmation: showConfirmationScreen,
          },
          formFields: {
            email: emailField,
          },
          sendConfirmationEmailRequest: {
            hasFailed: sendConfirmationEmailHasFailed,
          },
          e: errorMessage,
        },
      },
    } = this.props,
      latestEmailConfirmation = this.getLatestEmailConfirmation(),
      confirmationEmailAddress = _.get(latestEmailConfirmation, 'email', emailField)

    let Alert, dashboardTitle, PrevButton, NextButton, Screen

    //
    // Alerts

    if (showFailureAlert) {
      let i18nErrorMessage
      if (errorMessage.match(/already in use/)) {
        i18nErrorMessage = t('EmailRequiredScreen.FailureAlert.AlreadyInUseMessage')
      }
      else if (sendConfirmationEmailHasFailed) {
        i18nErrorMessage = t('EmailRequiredScreen.FailureAlert.SendMessage')
      }
      else {
        i18nErrorMessage = t('EmailRequiredScreen.FailureAlert.GenericMessage')
      }

      Alert = <AlertDialog
        open={ true }
        title={ t( 'EmailRequiredScreen.FailureAlert.Title' ) }
        text={ i18nErrorMessage }
        labelOk={ t( 'EmailRequiredScreen.FailureAlert.OkButton' ) }
        onClose={ this.reinitializeEmailConfirmations }
        onOk={ this.reinitializeEmailConfirmations }
      />
    }

    //
    // Screens

    if (showEmailScreen) {
      dashboardTitle = t('EmailRequiredScreen.EmailScreen.Title')

      NextButton = (
        <Button
          className={ classes.nextButton }
          color="inherit"
          disabled={ !this.formIsValid() || this.dashboardInFlight() }
          onClick={ this.confirmEmail }
        >
          { t( 'EmailRequiredScreen.EmailScreen.Next' ) }
        </Button>
      )

      let emailFieldError = {}
      if (this.emailFieldIsInvalid()) {
        emailFieldError = {
          error: true,
          helperText: t('EmailRequiredScreen.EmailScreen.Form.EmailField.Errors.Invalid'),
        }
      }

      Screen = [
        <Typography key="email-blurb" variant="subheading" className={ classes.emailBlurb }>
          { t( 'EmailRequiredScreen.EmailScreen.Blurb' ) }
        </Typography>,

        <div key="email-form" className={ classes.formRow }>
          <TextField
            className={ classes.formControl }
            id="email"
            label={ t( 'EmailRequiredScreen.EmailScreen.Form.EmailField.Label' ) }
            placeholder={ t( 'EmailRequiredScreen.EmailScreen.Form.EmailField.Placeholder' ) }
            type="email"
            fullWidth
            required
            value={ emailField }
            onChange={ this.changeEmailField }
            { ...emailFieldError }
          />
        </div>,
      ]
    }
    else if (showConfirmationScreen) {
      dashboardTitle = t('EmailRequiredScreen.ConfirmScreen.Title')

      if (latestEmailConfirmation) {
        PrevButton = (
          <IconButton
            className={ classes.prevButton }
            color="inherit"
            disabled={ this.dashboardInFlight() }
            onClick={ this.showEmailScreen }
          >
            <ArrowBackIcon/>
          </IconButton>
        )
      }

      Screen = [
        <Typography key="confirm-blurb" variant="subheading" className={ classes.confirmBlurb }>
          { t( 'EmailRequiredScreen.ConfirmScreen.Blurb', { email: confirmationEmailAddress } ) }
        </Typography>,

        <Button
          key="resend-button"
          className={ classes.resendButton }
          variant="raised"
          color="primary"
          onClick={ this.sendConfirmationEmail }
        >
          { t( 'EmailRequiredScreen.ConfirmScreen.ResendButton' ) }
        </Button>,
      ]
    }

    //
    // Return

    return (
      <Hero>
        <Dashboard
          title={ dashboardTitle }
          left={ PrevButton }
          right={ NextButton }
          inFlight={ this.dashboardInFlight() }
        >
          <div className="Screen EmailRequiredScreen">
            { Alert }
            { Screen }
          </div>
        </Dashboard>
      </Hero>
    )
  }
}

export default FusionReactor(withStyles(styles)(Component))
