import * as Sentry from '@sentry/browser'
import _ from 'lodash'
import { call, put } from 'redux-saga/effects'
import api from '../../lib/api/api'
import FusionReactor from '../../lib/FusionReactor'
import * as requestStates from '../../lib/requestStates'


export const REINITIALIZE_STATE = 'screens/email_confirm/REINITIALIZE_STATE'

export const CONFIRM_EMAIL_ADDRESS = 'screens/email_confirm/CONFIRM_EMAIL_ADDRESS'
export const CONFIRM_EMAIL_ADDRESS_REQUEST = 'screens/email_confirm/CONFIRM_EMAIL_ADDRESS_REQUEST'
export const CONFIRM_EMAIL_ADDRESS_SUCCESS = 'screens/email_confirm/CONFIRM_EMAIL_ADDRESS_SUCCESS'
export const CONFIRM_EMAIL_ADDRESS_FAILURE = 'screens/email_confirm/CONFIRM_EMAIL_ADDRESS_FAILURE'

const initialState = {
  confirmEmailAddressRequest: { ...requestStates.initialState },
  e: '',
}

export default FusionReactor.react( {
  initialState,
  reactions: {
    [ REINITIALIZE_STATE ]: {
      reducer: ( state, action ) => ( { ...initialState } ),
    },

    //
    // Confirm email address

    [ CONFIRM_EMAIL_ADDRESS ]: {
      saga: function* ( action ) {
        yield put( FusionReactor.reactWith( CONFIRM_EMAIL_ADDRESS_REQUEST, action ) )
      },
    },

    [ CONFIRM_EMAIL_ADDRESS_REQUEST ]: {
      reducer: ( state, action ) => ( {
        ...state,
        confirmEmailAddressRequest: { ...requestStates.requestState },
      } ),

      saga: function* ( action ) {
        try {
          const { params } = action,
            result = yield call( api.confirmEmailAddress, params.id )

          yield put( FusionReactor.reactWith( CONFIRM_EMAIL_ADDRESS_SUCCESS, { result } ) )
        } catch ( e ) {
          Sentry.captureException( e )
          yield put( FusionReactor.reactWith( CONFIRM_EMAIL_ADDRESS_FAILURE, { e: _.get( e, 'message', '' ) } ) )
        }
      },
    },

    [ CONFIRM_EMAIL_ADDRESS_SUCCESS ]: {
      reducer: ( state, action ) => {
        return {
          ...state,
          confirmEmailAddressRequest: { ...requestStates.successState },
        }
      },
    },

    [ CONFIRM_EMAIL_ADDRESS_FAILURE ]: {
      reducer: ( state, action ) => ( {
        ...state,
        confirmEmailAddressRequest: { ...requestStates.failureState },
        e: action.e,
      } ),
    },
  },
} )
