import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import _ from 'lodash'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import FusionReactor from '../../lib/FusionReactor'
import * as redirectReactions from '../../providers/redirect/reactions'
import Dashboard from '../../rigging/dashboard/Dashboard'
import Hero from '../../rigging/hero/Hero'
import AlertDialog from '../../things/alert_dialog/AlertDialog'
import Currency from '../../things/currency/Currency'
import * as reactions from './reactions'
import CheckoutScreen from './screens/Checkout'
import PlansScreen from './screens/Plans'
import styles from './styles'


class Component extends React.Component {

  //
  // Lifecycle

  componentDidMount() {
    const {
      intl: {
        locale,
      },
      reactWith,
      state: {
        dataProvider: {
          user,
          stripeCustomerData: {
            activeSubscription,
          },
        },
      },
    } = this.props

    reactWith( reactions.REINITIALIZE_STATE )
    reactWith( reactions.GET_STRIPE_PRODUCT_PLANS, {
      params: {
        locale,
      },
    } )

    if ( 0 < user.trial_period_length && user.trial_expired ) {
      this.openTrialAlert()
    }

    // User doesn't need a subscription or already has an active subscription
    if ( !user.requires_subscription || !user.trial_expired || !_.isUndefined( activeSubscription ) ) {
      reactWith( redirectReactions.REDIRECT_TO_HOME )
    }

    window.analytics.page( 'Subscription' )
  }

  componentDidUpdate() {
    const {
      reactWith,
      state: {
        subscriptionScreen: {
          alerts: {
            success: showSuccessAlert,
            failure: showFailureAlert,
          },
          subscribeToStripePlanRequest: {
            hasSucceeded: subscribeToStripePlanHasSucceeded,
            hasFailed: subscribeToStripePlanHasFailed,
          },
        },
      },
    } = this.props

    if ( subscribeToStripePlanHasFailed && !showFailureAlert ) {
      reactWith( reactions.TOGGLE_ALERT, { alert: 'failure' } )
    } else if ( subscribeToStripePlanHasSucceeded && !showSuccessAlert ) {
      reactWith( reactions.TOGGLE_ALERT, { alert: 'success' } )
    }
  }

  //
  // Controlled fields

  changePlan = ( plan ) => {
    const { reactWith } = this.props
    reactWith( reactions.CHANGE_FORM_FIELD, {
      field: 'plan',
      value: plan,
    } )
  }

  //
  // Screens

  showPlansScreen = () => {
    const { reactWith } = this.props

    this.changePlan( null )
    reactWith( reactions.TOGGLE_SCREEN, { screen: 'plans' } )
  }

  showCheckoutScreen = ( plan ) => {
    const { reactWith } = this.props

    this.changePlan( plan )
    reactWith( reactions.TOGGLE_SCREEN, { screen: 'checkout' } )
  }

  setActivePlanTab = ( activePlanTab ) => {
    const { reactWith } = this.props
    reactWith( reactions.SET_ACTIVE_PLAN_TAB, {
      params: {
        activePlanTab,
      },
    } )
  }

  //
  // Alerts

  openTrialAlert = () => {
    const { reactWith } = this.props
    reactWith( reactions.TOGGLE_ALERT, { alert: 'trial' } )
  }

  closeTrialAlert = () => {
    const { reactWith } = this.props
    reactWith( reactions.TOGGLE_ALERT )
  }

  reinitializeSubscribeToStripePlan = () => {
    const { reactWith } = this.props
    reactWith( reactions.REINITIALIZE_SUBSCRIBE_TO_STRIPE_PLAN )
  }

  reinitializeEverything = () => {
    window.location.reload()
  }

  //
  // Dashboard

  dashboardInFlight = () => {
    const {
      state: {
        subscriptionScreen: {
          getStripeProductPlansRequest: {
            inFlight: getStripeProductsListInFlight,
          },
        },
      },
    } = this.props

    return getStripeProductsListInFlight
  }

  //
  // Form

  subscribe = async ( stripe ) => {
    const {
      reactWith,
      state: {
        dataProvider: {
          user: {
            first_name,
            last_name,
            email,
          },
        },
        subscriptionScreen: {
          formFields: {
            plan,
          },
        },
      },
    } = this.props

    reactWith( reactions.SUBSCRIBE_TO_STRIPE_PLAN, {
      params: {
        stripe,
        plan,
        owner: {
          name: `${first_name} ${last_name}`,
          email,
        },
      },
    } )
  }

  //
  // Render

  render() {
    const {
      classes,
      t,
      state: {
        subscriptionScreen: {
          stripeProductPlans,
          formFields,
          screens: {
            plans: showPlansScreen,
            checkout: showCheckoutScreen,
          },
          plansScreen: {
            activePlanTab,
          },
          alerts: {
            trial: showTrialAlert,
            success: showSuccessAlert,
            failure: showFailureAlert,
          },
          subscribeToStripePlanRequest: {
            inFlight: subscribeToStripePlanInFlight,
          },
          e,
        },
      },
    } = this.props

    let dashboardProps = {
      title: t( 'SubscriptionScreen.Title' ),
      inFlight: this.dashboardInFlight(),
    }, screenProps, PrevButton, Alert, Screen

    if ( showTrialAlert ) {
      Alert = (
        <AlertDialog
          open={ true }
          title={ t( 'SubscriptionScreen.TrialAlert.Title' ) }
          text={ t( 'SubscriptionScreen.TrialAlert.Message' ) }
          labelOk={ t( 'SubscriptionScreen.TrialAlert.OkButton' ) }
          onClose={ this.closeTrialAlert }
          onOk={ this.closeTrialAlert }
        />
      )
    } else if ( showSuccessAlert ) {
      Alert = (
        <AlertDialog
          open={ true }
          title={ t( 'SubscriptionScreen.SuccessAlert.Title' ) }
          text={ t( 'SubscriptionScreen.SuccessAlert.Message', { message: e } ) }
          labelOk={ t( 'SubscriptionScreen.SuccessAlert.OkButton' ) }
          onClose={ this.reinitializeEverything }
          onOk={ this.reinitializeEverything }
        />
      )
    } else if ( showFailureAlert ) {
      let errorMessage = t( 'SubscriptionScreen.FailureAlert.GenericMessage' )
      if ( _.isString( e ) && e.match( /StripeCardError/ ) ) {
        errorMessage = t( 'SubscriptionScreen.FailureAlert.StripeCardMessage' )
      }

      Alert = (
        <AlertDialog
          open={ true }
          title={ t( 'SubscriptionScreen.FailureAlert.Title' ) }
          text={ errorMessage }
          labelOk={ t( 'SubscriptionScreen.FailureAlert.OkButton' ) }
          onClose={ this.reinitializeSubscribeToStripePlan }
          onOk={ this.reinitializeSubscribeToStripePlan }
        />
      )
    }

    if ( !_.isNull( stripeProductPlans ) ) {

      //
      // Plans screen

      const {
        basicMonthlyPlan,
        premiumMonthlyPlan,
      } = stripeProductPlans

      if ( showPlansScreen ) {
        dashboardProps = {
          ...dashboardProps,
          mainUnpadded: true,
        }


        let basicPlanBlurb = t( `SubscriptionScreen.BasicPlan.Blurb` ),
          premiumPlanBlurb = t( `SubscriptionScreen.PremiumPlan.Blurb` ),
          howManyBasicBenefits = parseInt( t( `SubscriptionScreen.BasicPlan.Benefits` ), 10 ),
          howManyPremiumPlanBenefits = parseInt( t( `SubscriptionScreen.PremiumPlan.Benefits` ), 10 ),
          basicPlanBenefits = [],
          premiumPlanBenefits = [],
          basicCheckoutButtonLabel = (
            <FormattedMessage
              id="SubscriptionScreen.Subscribe"
              values={ {
                value: <Currency value={ basicMonthlyPlan.amount / 100 }/>,
              } }
            />
          ),
          premiumCheckoutButtonLabel = (
            <FormattedMessage
              id="SubscriptionScreen.Subscribe"
              values={ {
                value: <Currency value={ premiumMonthlyPlan.amount / 100 }/>,
              } }
            />
          )

        for ( let i = 1; i <= howManyBasicBenefits; i++ ) {
          basicPlanBenefits.push( t( `SubscriptionScreen.BasicPlan.Benefit${i}` ) )
        }

        for ( let i = 1; i <= howManyPremiumPlanBenefits; i++ ) {
          premiumPlanBenefits.push( t( `SubscriptionScreen.PremiumPlan.Benefit${i}` ) )
        }

        screenProps = {
          activeTab: activePlanTab,
          onChangeTab: this.setActivePlanTab,

          basicPlanBlurb,
          premiumPlanBlurb,
          basicPlanBenefits,
          premiumPlanBenefits,

          basicCheckoutButtonLabel,
          premiumCheckoutButtonLabel,

          onBasicCheckout: () => this.showCheckoutScreen( basicMonthlyPlan ),
          onPremiumCheckout: () => this.showCheckoutScreen( premiumMonthlyPlan ),
        }

        Screen = <PlansScreen { ...screenProps }/>
      } else if ( showCheckoutScreen ) {

        //
        // Checkout screen

        PrevButton = (
          <IconButton
            className={ classes.prevButton }
            color="inherit"
            disabled={ this.dashboardInFlight() }
            onClick={ this.showPlansScreen }
          >
            <ArrowBackIcon/>
          </IconButton>
        )

        dashboardProps = {
          ...dashboardProps,
          left: PrevButton,
        }

        screenProps = {
          cardNumberFieldLabel: t( 'SubscriptionScreen.Form.CardNumberField.Label' ),
          expirationDateFieldLabel: t( 'SubscriptionScreen.Form.ExpirationDateField.Label' ),
          cvcFieldLabel: t( 'SubscriptionScreen.Form.CVCField.Label' ),
          postalCodeFieldLabel: t( 'SubscriptionScreen.Form.PostalCodeField.Label' ),
          payButtonLabel: (
            <FormattedMessage
              id="SubscriptionScreen.Form.Subscribe"
              values={ {
                value: <Currency value={ formFields.plan.amount / 100 }/>,
              } }
            />
          ),
          onPay: this.subscribe,
          inFlight: subscribeToStripePlanInFlight,
        }

        Screen = <CheckoutScreen { ...screenProps } />
      }
    }

    //
    // Return

    return (
      <Hero>
        <Dashboard { ...dashboardProps }>
          <div className="Screen SubscriptionScreen">
            { Alert }
            { Screen }
          </div>
        </Dashboard>
      </Hero>
    )
  }
}

export default FusionReactor( withStyles( styles )( Component ) )
