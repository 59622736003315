import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Drawer from '@material-ui/core/Drawer'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import SignIcon from '@material-ui/icons/BorderColor'
import CloseIcon from '@material-ui/icons/Close'
import _ from 'lodash'
import React from 'react'
import ReactDOM from 'react-dom'
import { FormattedMessage } from 'react-intl'
import FusionReactor from '../../lib/FusionReactor'
import * as redirectReactions from '../../providers/redirect/reactions'
import Dashboard from '../../rigging/dashboard/Dashboard'
import Hero from '../../rigging/hero/Hero'
import * as reactions from './reactions'
import FailureScreen from './screens/Failure'
import PreparingScreen from './screens/Preparing'
import SigningStatusScreen from './screens/SigningStatus'
import styles from './styles'


class Component extends React.Component {
  componentDidMount() {
    const {
      reactWith,
      state: {
        dataProvider: {
          user,
        },
        orderScreen: {
          orderParams: {
            when_to_retire,
            risk_level,
            portfolio_fee,
            fund_list,
          },
        },
      },
    } = this.props,
      order = this.getOrder(),
      insuranceItem = this.getInsuranceItem()

    if (!_.isUndefined(order)) {
      reactWith(reactions.GET_SIGNING_STATUS, {
        params: {
          signing_task_id: order.signing_task_id,
        },
      })
    }
    else if (!_.isUndefined(insuranceItem)) {
      const insuranceSource = insuranceItem.InsuranceSourceGet

      reactWith(reactions.PREPARE_ORDER_DOCUMENT, {
        params: {
          orderTemplate: 'Navista',
          userFirstName: user.first_name,
          userLastName: user.last_name,
          userSSN: user.ssn,
          insuranceItemHolding: insuranceItem.holding,
          insuranceSourceName: insuranceSource.name,
          insuranceSourceSSN: insuranceItem.pin,
          saveTime: when_to_retire - user.age,
          risk_level,
          portfolio_fee: `${( portfolio_fee * 100 ).toFixed( 2 )}%`,
          fund_list: fund_list.map((fund) => `
            <tr>
              <td>${ fund.name }</td>
              <td>${ ( fund.weight * 100 ).toFixed( 2 ) }%</td>
              <td nowrap="nowrap">${ fund.fact }</td>
            </tr>
          `).join(''),
        },
      })
    }
    else {
      reactWith(redirectReactions.REDIRECT_TO_HOME)
    }

    window.analytics.page('Order')
  }

  componentDidUpdate() {
    const {
      reactWith,
      state: {
        dataProvider: {
          eventRequest: {
            inFlight: eventInFlight,
          },
        },
        orderScreen: {
          orderDocument: {
            signingLink,
            signing_status,
            order,
          },
          signOrderDocumentRequest: {
            hasSucceeded: signOrderDocumentHasSucceeded,
          },
          getSigningStatusRequest: {
            hasSucceeded: getSigningStatusHasSucceeded,
          },
        },
      },
    } = this.props,
      signedOrder = this.getOrder()

    // Redirect to signing service
    if (signOrderDocumentHasSucceeded && signingLink.length && order && !eventInFlight) {
      // Don't encode URI components or Brandsign won't redirect properly
      const returnUrl = `${window.location.href}/${order.id}`,
        redirectUrl = `${signingLink}&returnUrl=${returnUrl}&linkUserBack=true`

      window.location.replace(redirectUrl)
    }

    // Redirect to order instructions
    if (getSigningStatusHasSucceeded && 'COMPLETE' === signing_status && signedOrder) {
      const insuranceItem = this.getInsuranceItem(signedOrder.insurance_item_id),
        insuranceSource = insuranceItem.InsuranceSourceGet

      if (!insuranceSource.supports_digital_signatures) {
        reactWith(redirectReactions.REDIRECT_TO_ORDER_INSTRUCTIONS, {
          params: {
            id: signedOrder.id,
          },
        })
      }
    }
  }

  getOrder = (orderId = null) => {
    const {
      params,
      state: {
        dataProvider: {
          user,
        },
      },
    } = this.props,
      id = _.get(params, 'id') // _.get() the id, because params can be undefined

    return _.find(user.OrderList, { id: orderId || id })
  }

  getInsuranceItem = (insurance_item_id = null) => {
    const {
      state: {
        dataProvider: {
          user,
        },
        orderScreen: {
          orderParams,
        },
      },
    } = this.props

    return _.find(user.InsuranceItemList, { id: insurance_item_id || orderParams.insurance_item_id, status: 'G' })
  }

  moveSignFab = (signingPanelEl) => {
    let signingFabBottom = 16
    if (signingPanelEl) {
      const signingPanelHeight = window.getComputedStyle(signingPanelEl).getPropertyValue('height')
      signingFabBottom = parseInt(signingPanelHeight, 10) + 10
    }

    const signingFabRef = ReactDOM.findDOMNode(this.refs.signingFab)
    if (signingFabRef) {
      signingFabRef.style.bottom = `${signingFabBottom}px`
    }
  }

  openSignPanel = () => {
    const { reactWith } = this.props
    reactWith(reactions.TOGGLE_PANEL, {
      panel: 'sign',
    })
  }

  closeSignPanel = () => {
    const { reactWith } = this.props
    reactWith(reactions.TOGGLE_PANEL)
  }

  signOrderDocumentInFlight = () => {
    const {
      state: {
        orderScreen: {
          signOrderDocumentRequest: {
            inFlight: signOrderDocumentInFlight,
            hasSucceeded: signOrderDocumentHasSucceeded,
          },
        },
      },
    } = this.props

    return signOrderDocumentInFlight || signOrderDocumentHasSucceeded
  }

  signOrderDocument = () => {
    const {
      reactWith,
      state: {
        dataProvider: {
          user,
        },
        orderScreen: {
          orderParams: {
            when_to_retire,
            risk_level,
            fund_version,
            holding,
            save_time,

            portfolio_fee,
            fund_list,
            max_return,
            exp_return,
            min_return,
          },
          orderDocument: {
            document,
          },
        },
      },
    } = this.props,
      insuranceItem = this.getInsuranceItem(),
      insuranceSource = insuranceItem.InsuranceSourceGet

    reactWith(reactions.SIGN_ORDER_DOCUMENT, {
      params: {
        user_id: user.id,
        userSSN: user.ssn,
        insurance_item_id: insuranceItem.id,
        insuranceSourceSSN: insuranceSource.ssn,
        document,

        when_to_retire,
        risk_level,
        fund_version,
        holding,
        save_time,

        portfolio_fee,
        fund_list,
        max_return,
        exp_return,
        min_return,
      },
    })
  }

  render() {
    const {
      classes,
      t,
      state: {
        orderScreen: {
          orderParams: {
            fund_list,
          },
          orderDocument: {
            signing_status,
          },
          panels: {
            sign: signPanelOpen,
          },
          prepareOrderDocumentRequest: {
            inFlight: prepareOrderDocumentInFlight,
            hasFailed: prepareOrderDocumentHasFailed,
          },
          signOrderDocumentRequest: {
            hasFailed: signOrderDocumentHasFailed,
          },
          getSigningStatusRequest: {
            inFlight: getSigningStatusInFlight,
            hasFailed: getSigningStatusHasFailed,
          },
        },
      },
    } = this.props,
      order = this.getOrder(),
      insuranceItem = this.getInsuranceItem(),
      i18n = _.get(insuranceItem, 'i18n', {})

    let Fab, Screen

    if (!signPanelOpen) {
      Fab = ((className) =>
        <Button
            variant="fab"
            color="primary"
            className={ className }
            onClick={ signPanelOpen ? this.closeSignPanel : this.openSignPanel }
            ref="signingFab"
          >
            { signPanelOpen ? <CloseIcon/> : <SignIcon/> }
          </Button>
      )
    }

    if (prepareOrderDocumentInFlight) {
      Screen = <PreparingScreen/>
    }
    else if (prepareOrderDocumentHasFailed || signOrderDocumentHasFailed || getSigningStatusHasFailed) {
      Screen = <FailureScreen/>
    }
    else if (!_.isUndefined(order)) {
      Screen = <SigningStatusScreen
        inFlight={ getSigningStatusInFlight }
        signing_status={ signing_status }
      />
    }
    else if (!_.isUndefined(insuranceItem)) {
      let insuranceSource = insuranceItem.InsuranceSourceGet,
        SignDocumentsInFlight

      if (this.signOrderDocumentInFlight()) {
        SignDocumentsInFlight = <CircularProgress classes={ { root: classes.signingInFlight } } size={ 20 }/>
      }

      Screen = (
        <Dashboard
          title={ t( 'OrderScreen.Title', { ...i18n } ) }
          left="drawer"
          fab={ Fab }
          mainUnpadded
        >
          <div className="Screen OrderScreen">
            <div className={ classes.header }>
              <Avatar className={ classes.avatar }>
                { insuranceSource.name[ 0 ] }
              </Avatar>

              <Typography variant="subheading">
                { insuranceSource.name }
              </Typography>
            </div>

            <Typography className={ classes.heading }>
              { t( 'OrderScreen.Heading' ) }
            </Typography>

            <Typography className={ classes.text }>
              { t( 'OrderScreen.Text' ) }
            </Typography>

            <Table className={ classes.table }>
              <TableHead className={ classes.tableHead }>
                <TableRow>
                  <TableCell>{ t( 'OrderScreen.Table.Header.Name' ) }</TableCell>
                  <TableCell numeric>{ t( 'OrderScreen.Table.Header.Fee' ) }</TableCell>
                  <TableCell numeric>{ t( 'OrderScreen.Table.Header.Share' ) }</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={ classes.tableBody }>
                { fund_list.map( ( fund, key ) => (
                  <TableRow key={ `fundlist-${key}` }>
                    <TableCell>{ fund.name }</TableCell>
                    <TableCell numeric>{ ( fund.fee * 100 ).toFixed( 2 ) }%</TableCell>
                    <TableCell numeric>{ ( fund.weight * 100 ).toFixed( 2 ) }%</TableCell>
                  </TableRow>
                ) ) }
              </TableBody>
            </Table>

            <Drawer
              anchor="bottom"
              open={ signPanelOpen }
              onClose={ this.closeSignPanel }
              className={ classes.signingPanel }
            >
              <div
                tabIndex={ 0 }
                role="button"
                onKeyDown={ this.closeSignPanel }
                className={ classes.signingPanelContainer }
                ref={ this.moveSignFab }
              >
                <Typography className={ classes.signingPanelText }>
                  <FormattedMessage
                    id="OrderScreen.SigningPanel.Text"
                    values={ {
                      read_more: <a href="/">{ t( 'OrderScreen.SigningPanel.ReadMoreLink' ) }</a>,
                    } }
                  />
                </Typography>

                <Button
                  variant="raised"
                  color="primary"
                  disabled={ this.signOrderDocumentInFlight() }
                  onClick={ this.signOrderDocument }>
                  { t( 'OrderScreen.SigningPanel.SignButton' ) }
                  { SignDocumentsInFlight }
                </Button>
              </div>
            </Drawer>
          </div>
        </Dashboard>
      )
    }

    return (
      <Hero>
        { Screen }
      </Hero>
    )
  }
}

export default FusionReactor(withStyles(styles)(Component))
