const icon = {
  width: '64px',
  height: '64px',
  marginBottom: '1.5rem',
}

export default ( theme ) => ( {
  fullScreen: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  fullScreenText: {
    opacity: '.54',
    marginBottom: '1.5rem',
    fontWeight: 'bold',
    textAlign: 'center',
  },

  preparingIndicator: {
    ...icon,
  },

  statusIndicator: {
    ...icon,
  },

  successIcon: {
    ...icon,
    color: '#FFB600',
  },

  failureIcon: {
    ...icon,
    color: '#FF0000',
  },

  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    alignItems: 'center',
    marginBottom: '1rem',
    padding: `${theme.spacing.unit * 3}px ${theme.spacing.unit}px`,
  },

  avatar: {
    marginRight: `${theme.spacing.unit * 2}px`,
    marginLeft: `${theme.spacing.unit}px`,
    background: '#FFCC6F',
  },

  heading: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    color: '#979797',
  },

  text: {
    marginBottom: '1rem',
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    color: '#979797',
  },

  table: {
    marginBottom: '3rem',
  },

  tableHead: {
    background: '#F2F2F2',
    '& tr': {
      height: 'auto',
    },
    '& th': {
      borderBottom: '0',
      padding: `${theme.spacing.unit * 2}px`,
      fontSize: '12px',
    },
  },

  tableBody: {
    '& tr': {
      height: 'auto',
    },
    '& td': {
      borderBottomColor: '#EAEAEA',
      padding: `${theme.spacing.unit * 2}px`,
    },
  },

  signingPanel: {
    '&>div:first-child': {
      // background: 'transparent',
      opacity: '.8 !important',
    },
  },

  signingPanelContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px`,
  },

  signingPanelText: {
    marginBottom: `${theme.spacing.unit * 2}px`,
    color: '#757575',
  },

  signingInFlight: {
    marginLeft: `${theme.spacing.unit}px`,
  },
} )