import Button from '@material-ui/core/Button'
import MobileStepper from '@material-ui/core/MobileStepper'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import React from 'react'
import FusionReactor from '../../lib/FusionReactor'
import * as redirectReactions from '../../providers/redirect/reactions'
import Hero from '../../rigging/hero/Hero'
import getNotifiedSVG from './img/get_notified.svg'
import optimizeSVG from './img/optimize.svg'
import sendOrderSVG from './img/send_order.svg'
import * as reactions from './reactions'
import styles from './styles'


class Component extends React.Component {
  componentDidMount() {
    const { reactWith } = this.props
    reactWith( reactions.REINITIALIZE_STATE )
    window.analytics.page( 'Intro' )
  }

  nextStep = () => {
    const { reactWith } = this.props
    reactWith( reactions.NEXT_STEP )
  }

  prevStep = () => {
    const { reactWith } = this.props
    reactWith( reactions.PREV_STEP )
  }

  start = () => {
    const { reactWith } = this.props
    reactWith( redirectReactions.REDIRECT_TO_HOME )
  }

  render() {
    const {
      classes,
      t,
      state: {
        introScreen: {
          step,
        },
      },
    } = this.props

    let Slide
    if ( !step ) {
      Slide = (
        <div className={ classes.slide }>
          <img src={ optimizeSVG } alt={ t( 'IntroScreen.FirstSlide.Title' ) } className={ classes.slideImage }/>

          <Typography variant="title" className={ classes.slideTitle }>
            { t( 'IntroScreen.FirstSlide.Title' ) }
          </Typography>

          <Typography variant="subheading" className={ classes.slideText }>
            { t( 'IntroScreen.FirstSlide.Text' ) }
          </Typography>
        </div>
      )
    } else if ( 1 === step ) {
      Slide = (
        <div className={ classes.slide }>
          <img src={ sendOrderSVG } alt={ t( 'IntroScreen.SecondSlide.Title' ) } className={ classes.slideImage }/>

          <Typography variant="title" className={ classes.slideTitle }>
            { t( 'IntroScreen.SecondSlide.Title' ) }
          </Typography>

          <Typography variant="subheading" className={ classes.slideText }>
            { t( 'IntroScreen.SecondSlide.Text' ) }
          </Typography>
        </div>
      )
    } else if ( 2 === step ) {
      Slide = (
        <div className={ classes.slide }>
          <img src={ getNotifiedSVG } alt={ t( 'IntroScreen.ThirdSlide.Title' ) } className={ classes.slideImage }/>

          <Typography variant="title" className={ classes.slideTitle }>
            { t( 'IntroScreen.ThirdSlide.Title' ) }
          </Typography>

          <Typography variant="subheading" className={ classes.slideText }>
            { t( 'IntroScreen.ThirdSlide.Text' ) }
          </Typography>

          <Button variant="raised" color="primary" className={ classes.button } onClick={ this.start }>
            { t( 'IntroScreen.ThirdSlide.StartButton' ) }
          </Button>
        </div>
      )
    }

    return (
      <Hero>
        <div className="Screen IntroScreen">
          <div className={ classes.slider }>
            { Slide }

            <MobileStepper
              variant="dots"
              steps={ 3 }
              position="static"
              activeStep={ step }
              className={ classes.stepper }
              backButton={
                <Button size="small" onClick={ this.prevStep } disabled={ !step }>
                  <KeyboardArrowLeft/>
                  { t( 'IntroScreen.BackButton' ) }
                </Button>
              }
              nextButton={
                <Button size="small" onClick={ this.nextStep } disabled={ 2 === step }>
                  { t( 'IntroScreen.NextButton' ) }
                  <KeyboardArrowRight/>
                </Button>
              }
            />
          </div>
        </div>
      </Hero>
    )
  }
}

export default FusionReactor( withStyles( styles )( Component ) )
