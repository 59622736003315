export default ( theme ) => ( {
  noNotifications: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noNotificationsIcon: {
    width: '64px',
    height: '64px',
    marginBottom: '1.5rem',
    color: '#FFB600',
  },
  noNotificationsText: {
    opacity: '.54',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  notificationsGroup: {
    marginBottom: '1rem',
  },
  notificationsGroupHeading: {
    padding: `${theme.spacing.unit * 2}px`,
  },
  notification: {
    boxShadow: 'none',
  },
  notificationHeader: {
    paddingBottom: '0',
  },
  notificationContent: {
    paddingTop: '12px',
    paddingLeft: '56px',
    paddingBottom: '12px !important',
  },
  avatar: {
    background: '#03DCE9',
  },
} )
