import AppBar from '@material-ui/core/AppBar'
import CircularProgress from '@material-ui/core/CircularProgress'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { withStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import FileIcon from '@material-ui/icons/Description'
import EqualizerIcon from '@material-ui/icons/Equalizer'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import HelpIcon from '@material-ui/icons/Help'
import MenuIcon from '@material-ui/icons/Menu'
import PersonIcon from '@material-ui/icons/Person'
import SettingsIcon from '@material-ui/icons/Settings'
import React from 'react'
import { NavLink } from 'react-router-dom'
import FusionReactor from '../../lib/FusionReactor'
import logo from './img/logo.svg'
import * as reactions from './reactions'
import styles from './styles'


class Component extends React.Component {
  componentDidMount() {
    window.intercomSettings = {
      custom_launcher_selector: '#intercom-launcher',
    }
  }

  openDrawer = () => {
    const { reactWith } = this.props
    reactWith( reactions.OPEN_DRAWER )
  }

  closeDrawer = () => {
    const { reactWith } = this.props
    reactWith( reactions.CLOSE_DRAWER )
  }

  render() {
    const {
      classes,
      t,
      inFlight,
      left,
      right,
      fab,
      title,
      dark,
      mainGrayUnpadded,
      mainUnpadded,
      mainGray,
      children,
      state: {
        dashboardRigging: {
          drawerOpen,
        },
      },
    } = this.props

    let DrawerButton = (
      <IconButton
        className={ classes.drawerButton }
        color="inherit"
        aria-label="Menu"
        onClick={ this.openDrawer }
      >
        <MenuIcon/>
      </IconButton>
    )

    let mainClassName = classes.main
    let appBarClassName = classes.appBar

    if ( dark ) {
      mainClassName = classes.mainDark
      appBarClassName = classes.appBarDark
    } else if ( mainUnpadded ) {
      mainClassName = classes.mainUnpadded
    } else if ( mainGray ) {
      mainClassName = classes.mainGray
    } else if ( mainGrayUnpadded ) {
      mainClassName = classes.mainGrayUnpadded
    }

    let Fab
    if ( fab ) {
      Fab = fab( classes.fab )
    }

    return (
      <div className="Rigging DashboardRigging" style={ { position: 'relative', minHeight: 0 } }>
        { Fab }

        <div className={ appBarClassName }>
          <Drawer open={ drawerOpen } onClose={ this.closeDrawer } className={ classes.drawer }
                  classes={ { paper: classes.drawerPaper } }>
            <div
              tabIndex={ 0 }
              role="button"
              onClick={ this.closeDrawer }
              onKeyDown={ this.closeDrawser }
            >
              <div className={ classes.drawerList }>
                <List>
                  <ListItem className={ classes.drawerLogo }>
                    <ListItemIcon>
                      <img src={ logo } alt="Brightsave"/>
                    </ListItemIcon>
                    <ListItemText primary="Brightsave"/>
                  </ListItem>

                  <ListItem button component={ NavLink } to="/" exact className={ classes.drawerItem }
                            activeClassName={ classes.drawerActiveItem }>
                    <ListItemIcon>
                      <EqualizerIcon/>
                    </ListItemIcon>
                    <ListItemText primary={ t( 'Dashboard.Drawer.Home' ) }/>
                  </ListItem>

                  <ListItem button component={ NavLink } to="/account" exact className={ classes.drawerItem }
                            activeClassName={ classes.drawerActiveItem }>
                    <ListItemIcon>
                      <PersonIcon/>
                    </ListItemIcon>
                    <ListItemText primary={ t( 'Dashboard.Drawer.MyAccount' ) }/>
                  </ListItem>

                  <ListItem button component={ NavLink } to="/documents" exact className={ classes.drawerItem }
                            activeClassName={ classes.drawerActiveItem }>
                    <ListItemIcon>
                      <FileIcon/>
                    </ListItemIcon>
                    <ListItemText primary={ t( 'Dashboard.Drawer.MyDocuments' ) }/>
                  </ListItem>

                  <ListItem button component={ NavLink } to="/preferences" exact className={ classes.drawerItem }
                            activeClassName={ classes.drawerActiveItem }>
                    <ListItemIcon>
                      <SettingsIcon/>
                    </ListItemIcon>
                    <ListItemText primary={ t( 'Dashboard.Drawer.Preferences' ) }/>
                  </ListItem>

                  <ListItem button className={ classes.drawerItem }
                            id="intercom-launcher">
                    <ListItemIcon>
                      <HelpIcon/>
                    </ListItemIcon>
                    <ListItemText primary={ t( 'Dashboard.Drawer.Support' ) }/>
                  </ListItem>

                  <ListItem button component={ NavLink } to="/login" className={ classes.drawerItem }>
                    <ListItemIcon>
                      <ExitToAppIcon/>
                    </ListItemIcon>
                    <ListItemText primary={ t( 'Dashboard.Drawer.Logout' ) }/>
                  </ListItem>
                </List>
              </div>
            </div>
          </Drawer>

          <AppBar
            className={ appBarClassName }
            position={ 'static' }
          >
            <Toolbar>
              { 'drawer' === left ? DrawerButton : left }
              <Typography variant="title" color="inherit" className={ classes.appBarTitle }>
                { title }
              </Typography>
              { right }
            </Toolbar>
          </AppBar>
        </div>

        <div className={ mainClassName }>
          { inFlight ?
            <div className={ classes.progress }>
              <CircularProgress/>
            </div> : children
          }
        </div>
      </div>
    )
  }
}

export default FusionReactor( withStyles( styles )( Component ) )
