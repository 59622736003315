// import Table from '@material-ui/core/Table'
// import TableBody from '@material-ui/core/TableBody'
// import TableCell from '@material-ui/core/TableCell'
// import TableHead from '@material-ui/core/TableHead'
// import TableRow from '@material-ui/core/TableRow'
import * as redirectReactions from '../../providers/redirect/reactions'
import _ from 'lodash'
import atob from 'atob'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Dashboard from '../../rigging/dashboard/Dashboard'
import FusionReactor from '../../lib/FusionReactor'
import Hero from '../../rigging/hero/Hero'
import React from 'react'
import styles from './styles'
import Typography from '@material-ui/core/Typography'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'


class Component extends React.Component {
  componentDidMount() {
    const { reactWith } = this.props, { deviation } = this.getOrderWithDeviation()

    if (_.isUndefined(deviation)) {
      reactWith(redirectReactions.REDIRECT_TO_HOME)
    }

    window.analytics.page('Order Recommendations')
  }

  getOrderWithDeviation = () => {
    const {
      params,
      state: {
        dataProvider: {
          user,
        },
      },
    } = this.props,
      order = _.find(user.OrderList, { id: params.order_id }) || { DeviationList: [] },
      deviation = _.find(order.DeviationList, { id: params.id })

    return {
      order,
      deviation,
    }
  }

  getInsuranceItem = (insurance_item_id) => {
    const {
      state: {
        dataProvider: {
          user,
        },
      },
    } = this.props

    return _.find(user.InsuranceItemList, { id: insurance_item_id, status: 'G' })
  }

  //
  // Render

  render() {
    const {
      classes,
      t,
    } = this.props, { order, deviation } = this.getOrderWithDeviation()

    let Header, Instructions //, FundList = <div></div>

    if (!_.isUndefined(deviation)) {
      const insuranceItem = this.getInsuranceItem(order.insurance_item_id),
        insuranceSource = insuranceItem.InsuranceSourceGet
      // ,
      // orderFundList = JSON.parse(atob(order.fund_list)),
      // deviationFundList = JSON.parse(atob(deviation.fund_list))

      //
      // Header

      Header = (
        <div className={ classes.header }>
          <Avatar className={ classes.avatar }>
            { insuranceSource.name[ 0 ] }
          </Avatar>

          <Typography variant="subheading">
            { insuranceSource.name }
          </Typography>
        </div>
      )

      //
      // Instructions

      const i18nParams = { riskLevel: order.risk_level, saveTime: order.save_time }

      switch (insuranceSource.ssn.toString()) {
        // case '165164060948':
        //   Instructions = (
        //     <div>
        //       <Typography className={ classes.heading }>
        //         { t( 'OrderRecommendationsScreen.Skandia.Heading', i18nParams ) }
        //       </Typography>

        //       <Typography className={ classes.text }>
        //         { t( 'OrderRecommendationsScreen.Skandia.Text1', i18nParams ) }
        //       </Typography>

        //       <Typography className={ classes.text }>
        //         { t( 'OrderRecommendationsScreen.Skandia.Text2', i18nParams ) }
        //       </Typography>

        //       <Typography className={ classes.text }>
        //         { t( 'OrderRecommendationsScreen.Skandia.Text3', i18nParams ) }
        //       </Typography>
        //     </div>
        //   )
        //   break

        default:
          Instructions = (
            <div>
              <Typography className={ classes.heading }>
                { t( 'OrderRecommendationsScreen.Heading', i18nParams ) }
              </Typography>

              <Typography className={ classes.text }>
                { t( 'OrderRecommendationsScreen.Text', i18nParams ) }
              </Typography>
            </div>
          )
          break
      }

      //
      // Link to optimization screen

      Instructions = [
        <div key="order-instructions">
          {Instructions}
        </div>,
        <Button
          key="optimize-button"
          variant="raised"
          color="primary"
          className={ classes.button }
          component={ Link }
          to={ `/optimize/${insuranceItem.id}` }
        >
          { t( 'OrderRecommendationsScreen.OptimizeButton' ) }
        </Button>
      ]

      //
      // Fund list

      // const FundRows = []

      // // Display deviation funds, not in original order fund list

      // for (let d in deviationFundList) {
      //   let deviationFund = deviationFundList[d],
      //     orderFund = _.find(orderFundList, { name: deviationFund.name })

      //   if (_.isUndefined(orderFund)) {
      //     FundRows.push(
      //       <TableRow key={ `deviation-fund-${d}` }>
      //         <TableCell><strong>{t('OrderRecommendationsScreen.Table.New')}:</strong> {deviationFund.name}</TableCell>
      //         <TableCell numeric>{ `${( deviationFund.fee * 100 ).toFixed( 2 )}%` }</TableCell>
      //         <TableCell numeric>{ `${( deviationFund.weight * 100 ).toFixed( 2 )}%`}</TableCell>
      //       </TableRow>
      //     )
      //   }
      // }

      //   // Display order fund list
      //   for (let o in orderFundList) {
      //     const orderFund = orderFundList[o],
      //       deviationFund = _.find(deviationFundList, { name: orderFund.name }) || { fee: 'n/a', weight: 'n/a' }

      //     let fundChangedClass = null
      //     if (orderFund.fee !== deviationFund.fee || orderFund.weight !== deviationFund.weight) {
      //       fundChangedClass = classes.fundChanged
      //     }

      //     FundRows.push(
      //       <TableRow key={ `order-fund-${o}` } className={fundChangedClass}>
      //         <TableCell>{ orderFund.name }</TableCell>
      //         <TableCell numeric>{ ( orderFund.fee * 100 ).toFixed( 2 ) }%</TableCell>
      //         <TableCell numeric>{ ( orderFund.weight * 100 ).toFixed( 2 ) }%</TableCell>
      //       </TableRow>
      //     )

      //     if (null !== fundChangedClass && 'n/a' !== deviationFund.fee && 'n/a' !== deviationFund.weight) {
      //       FundRows.push(
      //         <TableRow key={ `deviation-fund-${o}` }>
      //           <TableCell>&nbsp;</TableCell>
      //           <TableCell numeric>{ `${( deviationFund.fee * 100 ).toFixed( 2 )}%` }</TableCell>
      //           <TableCell numeric>{ `${( deviationFund.weight * 100 ).toFixed( 2 )}%`}</TableCell>
      //         </TableRow>
      //       )
      //     }
      //   }

      //   FundList = (
      //     <Table className={ classes.table }>
      //       <TableHead className={ classes.tableHead }>
      //         <TableRow>
      //           <TableCell>{ t( 'OrderRecommendationsScreen.Table.Header.Name' ) }</TableCell>
      //           <TableCell numeric>{ t( 'OrderRecommendationsScreen.Table.Header.Fee' ) }</TableCell>
      //           <TableCell numeric>{ t( 'OrderRecommendationsScreen.Table.Header.Share' ) }</TableCell>
      //         </TableRow>
      //       </TableHead>
      //       <TableBody className={ classes.tableBody }>
      //         {FundRows}
      //       </TableBody>
      //     </Table>
      //   )
    }

    //
    // Return

    return (
      <Hero>
        <Dashboard
          title={ t( 'OrderRecommendationsScreen.Title' ) }
          left="drawer"
          mainUnpadded
        >
          <div className="Screen OrderRecommendationsScreen">
            { Header }

            { Instructions }

            { /* FundList */ }
          </div>
        </Dashboard>
      </Hero>
    )
  }
}

export default FusionReactor(withStyles(styles)(Component))
