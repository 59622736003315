import _ from 'lodash'
import React from 'react'
import { IntlProvider } from 'react-intl'
import FusionReactor from '../../lib/FusionReactor'


class Component extends React.Component {
  render() {
    const {
      children,
      messages,
      state: { localesRigging: { currentLocale } },
    } = this.props

    return (
      <IntlProvider locale={ currentLocale } messages={ messages[ currentLocale ] }>
        <div
          className={ `Rigging LocalesRigging Locale${_.toUpper( currentLocale )}` }
        >
          { children }
        </div>
      </IntlProvider>
    )
  }
}

export default FusionReactor( Component, { withIntl: false } )
