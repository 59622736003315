import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import FusionReactor from '../../lib/FusionReactor'
import styles from './styles'


class Component extends React.Component {
  render() {
    const {
      classes,
      children,
      height,
    } = this.props

    return (
      <div className="Rigging HeroRigging">
        <div className={ classes.hero }>
          <div className={ classes.heroContent }>
            <Card className={ classes.card } style={ { height } }>
              <CardContent className={ classes.cardContent }>
                { children }
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    )
  }
}

export default FusionReactor( withStyles( styles )( Component ) )
