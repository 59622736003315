import _ from 'lodash'
import testSSNs from '../config/testSSNs'
import * as detectEnv from './detectEnv'


const isTestSSN = ( ssn ) => {
  return ( detectEnv.isDevelopment() && _.includes( testSSNs, ssn ) ) ||
    ( !detectEnv.isDevelopment() && '197102211898' === ssn )
}

export default isTestSSN