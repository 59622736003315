import Checkbox from '@material-ui/core/Checkbox'
import Divider from '@material-ui/core/Divider'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import FusionReactor from '../../../lib/FusionReactor'


const styles = ( theme ) => ( {
  formRow: {
    marginTop: `${theme.spacing.unit}px`,
    padding: `${theme.spacing.unit}px`,
  },
  rowHeading: {
    marginBottom: '.8rem',
    fontSize: '1.1rem',
  },
  rowLegend: {
    marginBottom: '.8rem',
    lineHeight: '1.2',
  },
  checkboxLabel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    verticalAlign: 'top',
    marginBottom: '.8rem',
  },
  checkboxControl: {
    height: '29px',
  },
} )

class Component extends React.Component {
  render() {
    const {
      classes,
      t,
      companyName,

      // Form fields
      agrees_to_data_processingField,
      agrees_to_contactField,
      agrees_to_termsField,

      onChangeAgreesToDataProcessingField,
      onChangeAgreesToContactField,
      onChangeAgreesToTermsField,
    } = this.props

    let agrees_to_termsI18n = 'AgreementsScreen.ConditionsScreen.Form.AgreesToIndividualTermsField'
    if ( null === companyName ) {
      agrees_to_termsI18n = 'AgreementsScreen.ConditionsScreen.Form.AgreesToIndividualTermsField'
    }

    return (
      <div>
        <div className={ classes.formRow }>
          <Typography variant="subheading" className={ classes.rowHeading }>
            { t( 'AgreementsScreen.ConditionsScreen.Form.PersonalInformation' ) }
          </Typography>

          <FormControl component="fieldset" className={ classes.formControl }>
            <FormLabel component="legend" className={ classes.rowLegend }>
              { t( 'AgreementsScreen.ConditionsScreen.Form.AgreesToDataProcessingField.Legend' ) }
            </FormLabel>
            <FormGroup>
              <FormControlLabel
                className={ classes.checkboxLabel }
                control={
                  <Checkbox
                    className={ classes.checkboxControl }
                    checked={ agrees_to_data_processingField }
                    onChange={ onChangeAgreesToDataProcessingField }
                    value="Yes"
                  />
                }
                label={ t( 'AgreementsScreen.ConditionsScreen.Form.AgreesToDataProcessingField.Label' ) }
              />
              <FormControlLabel
                className={ classes.checkboxLabel }
                control={
                  <Checkbox
                    className={ classes.checkboxControl }
                    checked={ agrees_to_contactField }
                    onChange={ onChangeAgreesToContactField }
                    value="Yes"
                  />
                }
                label={ t( 'AgreementsScreen.ConditionsScreen.Form.AgreesToContactField.Label' ) }
              />
            </FormGroup>
          </FormControl>
        </div>

        <Divider/>

        <div className={ classes.formRow }>
          <Typography variant="subheading" className={ classes.rowHeading }>
            { t( 'AgreementsScreen.ConditionsScreen.Form.Agreement' ) }
          </Typography>

          <FormControl component="fieldset" className={ classes.formControl }>
            <FormLabel component="legend" className={ classes.rowLegend }>
              <FormattedMessage
                id={ `${agrees_to_termsI18n}.Legend` }
                values={ {
                  companyName,
                  here: <a
                    href="https://brightsave.se/wp-content/uploads/2018/08/Villkor-f%C3%B6r-r%C3%A5dgivningstj%C3%A4nsten-BrightSave-Anv%C3%A4ndare.pdf"
                    target="_blank"
                    rel="noopener noreferrer">{ t( `${agrees_to_termsI18n}.LegendHere` ) }</a>,
                } }
              />
            </FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={ agrees_to_termsField }
                    onChange={ onChangeAgreesToTermsField }
                    value="Yes"
                  />
                }
                label={ t( `${agrees_to_termsI18n}.Label` ) }
              />
            </FormGroup>
          </FormControl>
        </div>
      </div>
    )
  }
}

export default FusionReactor( withStyles( styles )( Component ) )