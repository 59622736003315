import _ from 'lodash'
import { call, put } from 'redux-saga/effects'
import api from '../../lib/api/api'
import FusionReactor from '../../lib/FusionReactor'
import * as requestStates from '../../lib/requestStates'


export const REINITIALIZE_STATE = 'rigging/auth/REINITIALIZE_STATE'

export const AUTH = 'rigging/auth/AUTH'
export const AUTH_REQUEST = 'rigging/auth/AUTH_REQUEST'
export const AUTH_SUCCESS = 'rigging/auth/AUTH_SUCCESS'
export const AUTH_FAILURE = 'rigging/auth/AUTH_FAILURE'

const initialState = {
  authRequest: { ...requestStates.initialState },
  e: '',
}

export default FusionReactor.react( {
  initialState: initialState,
  reactions: {
    [ REINITIALIZE_STATE ]: {
      reducer: ( state, action ) => ( { ...initialState } ),
    },

    //
    // Auth

    [ AUTH ]: {
      saga: function* ( action ) {
        yield put( FusionReactor.reactWith( AUTH_REQUEST, action ) )
      },
    },

    [ AUTH_REQUEST ]: {
      reducer: ( state, action ) => ( {
        ...state,
        authRequest: { ...requestStates.requestState },
      } ),

      saga: function* ( action ) {
        try {
          const isAuthenticated = yield call( api.cognito.auth )
          if ( !isAuthenticated ) {
            // noinspection ExceptionCaughtLocallyJS
            throw new Error( 'Can\'t authenticate user' )
          }

          yield put( FusionReactor.reactWith( AUTH_SUCCESS ) )
        } catch ( e ) {
          // We don't want Sentry to complain everytime somebody's session expires
          // Sentry.captureException( e )
          yield put( FusionReactor.reactWith( AUTH_FAILURE, { e: _.get( e, 'message', '' ) } ) )
        }
      },
    },

    [ AUTH_SUCCESS ]: {
      reducer: ( state, action ) => ( {
        ...state,
        authRequest: { ...requestStates.successState },
      } ),
    },

    [ AUTH_FAILURE ]: {
      reducer: ( state, action ) => ( {
        ...state,
        authRequest: { ...requestStates.failureState },
        e: action.e,
      } ),
    },
  },
} )
