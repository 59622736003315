export const detectEnv = () => {
  if (null !== window.location.hostname.match(/(localhost|cloud9|dev)/g)) {
    return 'development'
  }

  if (null !== window.location.hostname.match(/staging/g)) {
    return 'staging'
  }

  return 'production'
}

export const isDevelopment = () => {
  return 'development' === detectEnv()
}

export const isStaging = () => {
  return 'staging' === detectEnv()
}

export const isProduction = () => {
  return 'production' === detectEnv()
}
