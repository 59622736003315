import FusionReactor from '../../lib/FusionReactor'


export const REINITIALIZE_STATE = 'providers/redirect/REINITIALIZE_STATE'
export const REDIRECT_TO_HOME = 'providers/redirect/REDIRECT_TO_HOME'
export const REDIRECT_TO_LOGIN = 'providers/redirect/REDIRECT_TO_LOGIN'
export const REDIRECT_TO_EMAIL_REQUIRED = 'providers/redirect/REDIRECT_TO_EMAIL_REQUIRED'
export const REDIRECT_TO_AGREEMENTS = 'providers/redirect/REDIRECT_TO_AGREEMENTS'
export const REDIRECT_TO_INTRO = 'providers/redirect/REDIRECT_TO_INTRO'
export const REDIRECT_TO_SUBSCRIPTION = 'providers/redirect/REDIRECT_TO_SUBSCRIPTION'
export const REDIRECT_TO_ACCOUNT = 'providers/redirect/REDIRECT_TO_ACCOUNT'
export const REDIRECT_TO_ORDER = 'providers/redirect/REDIRECT_TO_ORDER'
export const REDIRECT_TO_ORDER_INSTRUCTIONS = 'providers/redirect/REDIRECT_TO_ORDER_INSTRUCTIONS'

const initialState = {
  redirectTo: '',
}

export default FusionReactor.react( {
  initialState: initialState,
  reactions: {
    [ REINITIALIZE_STATE ]: {
      reducer: ( state, action )=> ( { ...initialState } ),
    },

    [ REDIRECT_TO_HOME ]: {
      reducer: ( state, action ) => ( { ...state, redirectTo: '/' } ),
    },

    [ REDIRECT_TO_LOGIN ]: {
      reducer: ( state, action ) => ( { ...state, redirectTo: '/login' } ),
    },

    [ REDIRECT_TO_EMAIL_REQUIRED ]: {
      reducer: ( state, action ) => ( { ...state, redirectTo: '/email-required' } ),
    },

    [ REDIRECT_TO_AGREEMENTS ]: {
      reducer: ( state, action ) => ( { ...state, redirectTo: '/agreements' } ),
    },

    [ REDIRECT_TO_INTRO ]: {
      reducer: ( state, action ) => ( { ...state, redirectTo: '/intro' } ),
    },

    [ REDIRECT_TO_SUBSCRIPTION ]: {
      reducer: ( state, action ) => ( { ...state, redirectTo: '/subscription' } ),
    },

    [ REDIRECT_TO_ACCOUNT ]: {
      reducer: ( state, action ) => ( { ...state, redirectTo: '/account' } ),
    },

    [ REDIRECT_TO_ORDER ]: {
      reducer: ( state, action ) => ( { ...state, redirectTo: '/order' } ),
    },

    [ REDIRECT_TO_ORDER_INSTRUCTIONS ]: {
      reducer: ( state, action ) => ( { ...state, redirectTo: `/order-instructions/${action.params.id}` } ),
    },
  },
} )
