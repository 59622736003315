import _ from 'lodash'
import Avatar from '@material-ui/core/Avatar'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import FileIcon from '@material-ui/icons/Description'
import FusionReactor from '../../lib/FusionReactor'
import IconButton from '@material-ui/core/IconButton'
import InfoIcon from '@material-ui/icons/Info'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import moment from 'moment'
import React from 'react'
import styles from './styles'
import Typography from '@material-ui/core/Typography'
import UpIcon from '@material-ui/icons/SubdirectoryArrowRight'
import { NavLink } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

class Component extends React.Component {
  render() {
    const {
      t,
      classes,
      documents,
    } = this.props,
     i18n = _.get(documents, '0.InsuranceItemGet.i18n', {})

    let counter = documents.length,
      recPerInsItem = [],
      Documents = _.reduce(documents, (acc, doc, key) => {
        acc.push(
          <ListItem key={ `doc-${doc.id}` } className={ classes.listItem }>
            <ListItemAvatar>
              <Avatar
                className={ 'COMPLETE' === doc.signing_status ? classes.avatarComplete : classes.avatarPending }
                onClick={ () => window.location = doc.signed_document_link }
              >
                <FileIcon/>
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={ t( 'DocumentsScreen.Subject.Primary', { number: counter--, ...i18n } ) }
              secondary={ moment.unix(doc.created_at).format( 'YYYY-MM-DD HH:mm' ) }
            />
            <ListItemSecondaryAction>
              <IconButton aria-label="Info" onClick={ () => window.location = doc.signed_document_link }>
                <InfoIcon/>
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        )

        //
        // Deviations only for latest complete order, per insurance

        const deviation = _.get(doc, 'DeviationList.0')
        if ('COMPLETE' === doc.signing_status && !_.includes(recPerInsItem, doc.insurance_item_id) && !_.isUndefined(deviation)) {
          acc.push(
            <ListItem
              key={ `doc-${doc.id}-deviation-${deviation.id}` }
              className={ classes.listItem }
              component={ NavLink }
              to={ `/order-recommendations/${doc.id}/${deviation.id}` }
            >
              <ListItemAvatar>
                <Avatar className={ classes.avatarRecommendation }>
                  <UpIcon/>
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={ t( 'DocumentsScreen.NewRecommendation.Primary' ) }
                secondary={ t( 'DocumentsScreen.NewRecommendation.Secondary' ) }
              />
              <ListItemSecondaryAction>
                <IconButton
                  component={ NavLink }
                  to={ `/order-recommendations/${doc.id}/${deviation.id}` }
                >
                  <ChevronRightIcon/>
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          )
          recPerInsItem.push(doc.insurance_item_id)
        }

        return acc
      }, [])

    Documents = (
      <div className={ classes.listGroup }>
        <Typography className={ classes.listGroupHeading }>{ t( 'DocumentsScreen.SubjectsTitle', {...i18n} ) }</Typography>

        <List dense={ false }>
          { Documents }
        </List>
      </div>
    )

    return Documents
  }
}

export default FusionReactor(withStyles(styles)(Component))
