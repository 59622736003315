import FusionReactor from '../../lib/FusionReactor'


export const REINITIALIZE_STATE = 'screens/preferences/REINITIALIZE_STATE'

export const HYDRATE_FORM_FIELDS = 'screens/preferences/HYDRATE_FORM_FIELDS'
export const CHANGE_FORM_FIELD = 'screens/preferences/CHANGE_FORM_FIELD'

const initialState = {
  formFields: {
    wants_notifications: false,
    notification_frequency: '',
    wants_email_notifications: false,
    wants_sms_notifications: false,
  },
}

export default FusionReactor.react( {
  initialState,
  reactions: {
    [ REINITIALIZE_STATE ]: {
      reducer: ( state, action ) => ( { ...initialState } ),
    },

    //
    // Controlled fields

    [ HYDRATE_FORM_FIELDS ]: {
      reducer: ( state, action ) => ( {
        ...state,
        formFields: {
          ...action.formFields,
        },
      } ),
    },

    [ CHANGE_FORM_FIELD ]: {
      reducer: ( state, action ) => ( {
        ...state,
        formFields: {
          ...state.formFields,
          [ action.field ]: action.value,
        },
      } ),
    },
  },
} )
