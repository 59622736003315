import Account from '../screens/account/Account'
import Agreements from '../screens/agreements/Agreements'
import AuthRigging from '../rigging/auth/Auth'
import Documents from '../screens/documents/Documents'
import EmailConfirm from '../screens/email_confirm/EmailConfirm'
import EmailRequired from '../screens/email_required/EmailRequired'
import FourOhFour from '../screens/four_oh_four/FourOhFour'
import Home from '../screens/home/Home'
import Intro from '../screens/intro/Intro'
import Login from '../screens/login/Login'
import Notifications from '../screens/notifications/Notifications'
import Optimize from '../screens/optimize/Optimize'
import Order from '../screens/order/Order'
import OrderInstructions from '../screens/order_instructions/OrderInstructions'
import OrderRecommendations from '../screens/order_recommendations/OrderRecommendations'
import Preferences from '../screens/preferences/Preferences'
import Subscriptions from '../screens/subscription/Subscription'


export default [
  { path: '/', component: AuthRigging( Home ) },
  { path: '/email-required', component: AuthRigging( EmailRequired ) },
  { path: '/email-confirm/:id', component: EmailConfirm },
  { path: '/agreements', component: AuthRigging( Agreements ) },
  { path: '/intro', component: AuthRigging( Intro ) },
  { path: '/subscription', component: AuthRigging( Subscriptions ) },
  { path: '/account', component: AuthRigging( Account ) },
  { path: '/preferences', component: AuthRigging( Preferences ) },
  { path: '/documents', component: AuthRigging( Documents ) },
  { path: '/notifications', component: AuthRigging( Notifications ) },
  { path: '/optimize/:id', component: AuthRigging( Optimize ) },
  { path: '/order', component: AuthRigging( Order ) },
  { path: '/order/:id', component: AuthRigging( Order ) },
  { path: '/order-recommendations/:order_id/:id', component: AuthRigging( OrderRecommendations ) },
  { path: '/order-instructions/:id', component: AuthRigging( OrderInstructions ) },

  { path: '/login', component: Login },
  { path: '/login/:authenticationId', component: Login },

  // Default route in case none of the above match
  { component: FourOhFour },
]
