import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import NotificationsIcon from '@material-ui/icons/NotificationsActive'
import _ from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'
import FusionReactor from '../../lib/FusionReactor'
import Dashboard from '../../rigging/dashboard/Dashboard'
import Hero from '../../rigging/hero/Hero'
import ActiveInsuranceCard from './cards/ActiveInsurance'
import InactiveInsuranceCard from './cards/InactiveInsurance'
import styles from './styles'


class Component extends React.Component {
  componentDidMount() {
    window.analytics.page('Home')
  }

  render() {
    const {
      classes,
      t,
      state: {
        dataProvider: {
          user: {
            InsuranceItemList: insuranceItems,
          },
        },
      },
    } = this.props

    let Insurance = [],
      ActiveInsurance = [],
      InactiveInsurance = []

    for (let i in insuranceItems) {
      const insuranceItem = insuranceItems[i],
        insuranceSource = insuranceItem.InsuranceSourceGet,
        insuranceCardProps = {
          key: `insurance-${i}`,
          title: insuranceSource.name,
          ..._.pick(insuranceItem, [
            'pin',
            'premium_recurrence',
            'monthly_premium',
            'premium_status',
            'premium_last_payed_at',
            'holding',
            'status'
          ]),
          to: `/optimize/${encodeURIComponent( insuranceItem.id )}`,
        }

      if ('G' === insuranceItem.status) {
        ActiveInsurance.push(<ActiveInsuranceCard { ...insuranceCardProps } />)
      }
      else {
        InactiveInsurance.push(<InactiveInsuranceCard { ...insuranceCardProps } />)
      }
    }

    if (ActiveInsurance.length) {
      Insurance.push(
        <div key="active-insurance">
          <Typography className={ classes.insuranceGroupHeading }>
            { t( 'HomeScreen.AvailableHeading' ) }
          </Typography>

          { ActiveInsurance }
        </div>,
      )
    }

    if (InactiveInsurance.length) {
      Insurance.push(
        <div key="inactive-insurance">
          <Typography className={ classes.insuranceGroupHeading }>
            { t( 'HomeScreen.UnavailableHeading' ) }
          </Typography>

          { InactiveInsurance }
        </div>,
      )
    }

    let NotificationsButton = (
      <Button
        color="inherit"
        className={ classes.notificationsButton }
        component={ Link }
        to="/notifications"
      >
        <NotificationsIcon/>
      </Button>
    )

    NotificationsButton = null // TODO: comment this out when we'll have notifications

    return (
      <Hero>
        <Dashboard
          title={ t( 'HomeScreen.Title' ) }
          left="drawer"
          right={ NotificationsButton }
          mainGray
        >
          <div className="Screen HomeScreen">
            { Insurance }
          </div>
        </Dashboard>
      </Hero>
    )
  }
}

export default FusionReactor(withStyles(styles)(Component))
