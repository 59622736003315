import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControl from '@material-ui/core/FormControl/FormControl'
import FormLabel from '@material-ui/core/FormLabel/FormLabel'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import {
  CardCVCElement,
  CardExpiryElement,
  CardNumberElement,
  injectStripe,
  PostalCodeElement,
} from 'react-stripe-elements'
import FusionReactor from '../../../lib/FusionReactor'


const styles = ( theme ) => ( {
  form: {
    margin: `${theme.spacing.unit * 2}px`,
  },
  formControl: {
    margin: `${theme.spacing.unit * 2}px`,
    '& > label': {
      marginBottom: `${theme.spacing.unit}px`,
    },
  },
  stripeElement: {},
  payButton: {
    display: 'block',
    margin: `${theme.spacing.unit * 3}px auto`,
  },
  inFlight: {
    marginLeft: `${theme.spacing.unit * 2}px`,
  },
} )

class Component extends React.Component {
  render() {
    const {
        classes,
        cardNumberFieldLabel,
        expirationDateFieldLabel,
        cvcFieldLabel,
        postalCodeFieldLabel,
        payButtonLabel,
        onPay,
        stripe,
        inFlight,
      } = this.props,
      formControlProps = {
        component: 'fieldset',
        fullWidth: true,
        required: true,
        disabled: inFlight,
        className: classes.formControl,
      },
      stripeElementProps = {
        style: {
          base: {
            color: '#424770',
            letterSpacing: '0.025em',
            fontFamily: 'Source Code Pro, monospace',
            fontSize: '16px',
          },
          invalid: {
            color: '#9e2146',
          },
        },
        className: classes.stripeElement,
        disabled: inFlight,
        required: true,
      },
      payButtonProps = {
        variant: 'contained',
        color: 'primary',
        className: classes.payButton,
        disabled: inFlight,
        onClick: () => onPay( stripe ),
      }

    //
    // Return

    return (
      <form onSubmit={ this.handleSubmit } className={ classes.form }>
        <FormControl { ...formControlProps }>
          <FormLabel>{ cardNumberFieldLabel }</FormLabel>
          <CardNumberElement { ...stripeElementProps } />
        </FormControl>
        <FormControl { ...formControlProps }>
          <FormLabel>{ expirationDateFieldLabel }</FormLabel>
          <CardExpiryElement { ...stripeElementProps } />
        </FormControl>
        <FormControl { ...formControlProps }>
          <FormLabel>{ cvcFieldLabel }</FormLabel>
          <CardCVCElement { ...stripeElementProps } />
        </FormControl>
        <FormControl { ...formControlProps }>
          <FormLabel>{ postalCodeFieldLabel }</FormLabel>
          <PostalCodeElement { ...stripeElementProps } />
        </FormControl>

        <Button { ...payButtonProps }>
          { payButtonLabel }
          { inFlight ? <CircularProgress
            classes={ { root: classes.inFlight } }
            size={ 20 }
          /> : null }
        </Button>
      </form>
    )
  }
}

export default FusionReactor( withStyles( styles )( injectStripe( Component ) ) )
