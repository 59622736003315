import * as Sentry from '@sentry/browser'
import createSagaMiddleware from 'redux-saga'
import createSentryMiddleware from 'redux-sentry-middleware'


const ravenMiddleware = createSentryMiddleware( Sentry, {
  stateTransformer: function ( state ) {
    // For now, don't include the state in the error logs (there's a 100k limit to each error log)
    return {}
  },
  actionTransformer: function ( action ) {
    // Include everything about the action
    return { ...action }
  },
  filterBreadcrumbActions: function ( action ) {
    // Returning true means the action will get logged (we don't want to log CHANGE_FORM_FIELD actions
    return !action.type.match(/CHANGE_FORM_FIELD/)
  },
} )

export const sagaMiddleware = createSagaMiddleware( {
  onError: ( e ) => {
    Sentry.captureException( e )
  },
} )

export default [ sagaMiddleware, ravenMiddleware ]
