export default ( theme ) => ( {
  header: {
    display: 'flex',
    flex: '1',
    flexGrow: '1',
    flexBasis: '0',
    backgroundImage: 'url(\'/login/login_header.png\')',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',

    [ theme.breakpoints.up( 'sm' ) ]: {
      display: 'none',
    },
  },
  main: {
    display: 'flex',
    flex: '1',
    flexGrow: '1',
    flexBasis: '0',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '3rem',
  },
} )