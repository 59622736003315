import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ErrorIcon from '@material-ui/icons/Error'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import FusionReactor from '../../../lib/FusionReactor'
import styles from '../styles'


class Component extends React.Component {
  render() {
    const {
      classes,
      t,
    } = this.props

    return (
      <div className={ classes.fullScreen }>
        <ErrorIcon className={ classes.failureIcon }/>

        <Typography className={ classes.fullScreenText }>
          <FormattedMessage
            id="OrderScreen.FailureScreen.Text"
            values={ { br: <br/> } }
          />
        </Typography>

        <Button
          variant="raised"
          color="primary"
          component={ Link }
          to="/">
          { t( 'OrderScreen.FailureScreen.GoToDashboardButton' ) }
        </Button>
      </div>
    )
  }
}

export default FusionReactor( withStyles( styles )( Component ) )
