import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import React from 'react'
import FusionReactor from '../../lib/FusionReactor'


class Component extends React.Component {
  render() {
    const {
      labelCancel,
      labelConfirm,
      onCancel,
      onClose,
      onConfirm,
      open,
      text,
      title,
    } = this.props

    return (
      <div className="Thing ConfirmThing">
        <Dialog
          open={ open }
          onClose={ onClose }
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{ title }</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              { text }
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={ onCancel } color="primary" autoFocus>
              { labelCancel }
            </Button>
            <Button onClick={ onConfirm } color="primary">
              { labelConfirm }
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default FusionReactor( Component )
