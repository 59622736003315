import Button from '@material-ui/core/Button/Button'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { Link } from 'react-router-dom'
import FusionReactor from '../../lib/FusionReactor'
import Dashboard from '../../rigging/dashboard/Dashboard'
import Hero from '../../rigging/hero/Hero'
import * as reactions from './reactions'
import styles from './styles'


class Component extends React.Component {

  //
  // Lifecycle

  componentDidMount() {
    const {
      reactWith,
      params,
    } = this.props

    reactWith( reactions.REINITIALIZE_STATE )
    reactWith( reactions.CONFIRM_EMAIL_ADDRESS, { params } )

    window.analytics.page( 'Email Confirm' )
  }

  //
  // Dashboard

  dashboardInFlight = () => {
    const {
      state: {
        emailConfirmScreen: {
          confirmEmailAddressRequest: {
            inFlight: confirmEmailAddressInFlight,
          },
        },
      },
    } = this.props

    return confirmEmailAddressInFlight
  }

  //
  // Render

  render() {
    const {
      classes,
      t,
      state: {
        emailConfirmScreen: {
          confirmEmailAddressRequest: {
            hasSucceeded: confirmEmailAddressHasSucceeded,
            hasFailed: confirmEmailAddressHasFailed,
          },
        },
      },
    } = this.props

    let Screen

    if ( confirmEmailAddressHasSucceeded ) {
      Screen = [
        <Typography key="success-message" variant="subheading" className={ classes.blurb }>
          { t( 'EmailConfirmScreen.Success' ) }
        </Typography>,

        <Button
          className={ classes.goToDashboardButton }
          key="go-to-dashboard-button"
          variant="raised"
          color="primary"
          component={ Link }
          to="/">
          { t( 'EmailConfirmScreen.GoToDashboardButton' ) }
        </Button>,
      ]
    } else if ( confirmEmailAddressHasFailed ) {
      Screen = [
        <Typography key="failure-message" variant="subheading" className={ classes.blurb }>
          { t( 'EmailConfirmScreen.Failure' ) }
        </Typography>,
      ]
    }

    //
    // Return

    return (
      <Hero>
        <Dashboard
          title={ t( 'EmailConfirmScreen.Title' ) }
          inFlight={ this.dashboardInFlight() }
        >
          <div className="Screen EmailRequiredScreen">
            { Screen }
          </div>
        </Dashboard>
      </Hero>
    )
  }
}

export default FusionReactor( withStyles( styles )( Component ) )
