const locale = {
  CurrencyFormat: '{value} kr',

  Subject: {
    order: 'beställning',
    orders: 'beställningar',
    Order: 'Beställning',
    Orders: 'Beställningar',
    recommendation: 'rekommendation',
    recommendations: 'rekommendationer',
    Recommendation: 'Rekommendation',
    Recommendations: 'Rekommendationer',
  },

  ErrorBoundary: {
    Text: 'Ooops!{br}Något gick snett.',
    ReportIssueButton: 'Rapportera felet',
  },

  Dashboard: {
    Drawer: {
      Home: 'Översikt',
      MyAccount: 'Mitt konto',
      MyDocuments: 'Mina dokument',
      Preferences: 'Inställningar',
      Support: 'Hjälp',
      Logout: 'Logga ut',
    },
  },

  LoginScreen: {
    Alert: {
      Title: 'Logga in',
      Text: {
        UserNotFoundException: 'Du försöker logga in med ett konto som inte existerar. Kontakta oss på <a href="#" id="intercom-launcher">supporten</a> för mer information.',
      },
      OkButton: 'OK',
    },
    YesNoScreen: {
      Title: 'Logga in',
      FirstParagraph: 'Du behöver använda BankID för att logga in på Brightsave.',
      SecondParagraph: 'Har du BankID på denna enhet??',
      NoButton: 'Nej',
      YesButton: 'Ja',
    },
    SSNScreen: {
      Title: 'Ange personnummer',
      Paragraph: 'För att logga in behöver du ange ditt personnummer i formatet YYYYMMDDXXXX t ex 197511034456.',
      Form: {
        SSNFieldPlaceholder: 'Ditt personnummer (YYYYMMDDXXXX)',
        CancelButton: 'Avbryt',
        YesButton: 'OK',
      },
    },
    BankIDScreen: {
      StartBankIDApp: 'Starta din BankID app.',
      AuthenticateButton: 'Fortsätt',
    },
  },

  HomeScreen: {
    Title: 'Översikt',
    AvailableHeading: 'Försäkringar tillgängliga för optimering',
    UnavailableHeading: 'Andra försäkringer ej tillgängliga för optimering',
    InsuranceCard: {
      InsuranceDetails: {
        PremiumRecurrence: {
          OneTime: 'Premie: {value}',
          Monthly: 'Premie/mån: {value}',
        },
        PremiumStatus: 'Premiestatus: {value}',
        PremiumStatusValue: {
          J: 'Aktiva',
          N: 'Inaktiv',
          K: 'Okänd',
        },
        PremiumLastPayedAt: 'Betalad till: {value}',
        Holding: 'Innehav: {value}',
        Status: 'Status: {value}',
        StatusValue: {
          G: 'Gällande',
          F: 'Fribrev',
          N: 'Nyteckning',
          U: 'Utbetalning',
          S: 'Slutbetald',
        },
      },
      OptimizeButton: 'Starta optimering',
    },
  },

  EmailRequiredScreen: {
    FailureAlert: {
      Title: 'Fel',
      OkButton: 'OK',
      AlreadyInUseMessage: 'Emailadressen finns redan registrerad. Kontakta oss på Supporten.',
      SendMessage: 'Det uppstod ett problem när vi skulle skicka bekräftelsemail. Kontakta oss på Supporten.',
      GenericMessage: 'Det uppstod ett problem när vi skulle uppdatera din email. Kontakta oss på Supporten.',
    },
    EmailScreen: {
      Next: 'Nästa',
      Title: 'Email krävs',
      Blurb: 'Vi behöver din emailadress för att hålla dig uppdaterad med viktig information om ditt konto och sparande. Vänligen ange din emailadress:',
      Form: {
        EmailField: {
          Label: 'Din emailadress',
          Placeholder: 'exempelvis123@abc.se',
          Errors: {
            Invalid: 'Emailadressen verkar ogitlig',
          },
        },
      },
    },
    ConfirmScreen: {
      Title: 'Bekräfta emailadress',
      Blurb: 'Vi har skickat ett bekräftelsemail till "{email}". Vänligen leta upp det i din Inbox eller eventuellt skräppost folder och klicka på länken i det mailet för att bekräfta din emailadress.',
      ResendButton: 'Skicka bekräftelsemail igen',
    },
    ConfirmationEmail: {
      Subject: 'Bekräfta din emailadress för Brightsave',
      HtmlBody: '<p>Hej {first_name}</p>' +
        '<p>Klicka på länken för att bekräfta din emailadress för Brightsave:</p>' +
        '<p><a href="{link}">{link}</a></p>' +
        '<br>' +
        '<p>Med vänlig hälsning,<br>' +
        'dina vänner på Brightsave</p>',
      TextBody: 'Hej {first_name}\n' +
        'Klicka på länken för att bekräfta din emailadress för Brightsave:\n' +
        '{link}\n' +
        '\n' +
        'Med vänlig hälsning,\n' +
        'dina vänner på Brightsave',
    },
  },

  EmailConfirmScreen: {
    Title: 'Bekräftelsemail',
    Success: 'Din emailadress är bekräftad',
    Failure: 'Din emailadress kunde inte bekräftas. Kontakta oss på Supporten.',
    GoToDashboardButton: 'Fortsätt',
  },

  AgreementsScreen: {
    AboutScreen: {
      Title: 'Om dig',
      Next: 'Nästa',
      HelpPopups: {
        OkButton: 'OK, förstår',
      },
      CitizenshipHelpPopup: {
        Title: 'Medborgarskap',
        Text: 'Vi behöver enligt lag säkerställa att användaren är svensk medborgare.',
      },
      PaysForeignTaxHelpPopup: {
        Title: 'Skatterättslig hemvist',
        Text: 'Lagen om automatiskt utbyte av upplysningar om finansiella konton är svenska lagar som innebär att finansiella företag ska lämna uppgifter till svenska Skatteverket om kontohavare som är skyldiga att betala skatt i annat land än Sverige. Svenska Skatteverket kommer i sin tur rapportera vidare till skattemyndigheten i det land där kontohavaren är skatteskyldig.',
      },
      PaysUSTaxHelpPopup: {
        Title: 'Skattskyldig i USA',
        Text: 'Enligt den amerikanska lagen FATCA måste finansiella institut över hela världen lämna uppgifter om amerikaners konton till USA. Många länder, däribland Sverige, har valt att teckna ett särskilt avtal med USA om FATCA. Det innebär att svenska finansiella institut ska lämna uppgifterna till Skatteverket, som i sin tur ska lämna uppgifterna vidare till det amerikanska skatteverket IRS (Internal Revenue Service). Avtalet har blivit svensk lag.',
      },
      IsPEPHelpPopup: {
        Title: 'Politiskt utsatt person',
        Text: 'En person i politiskt utsatt ställning (PEP), är en person som har eller har haft viktiga offentliga funktioner i en stat eller i en internationell organisation. Begreppet PEP inkluderar även familjemedlemmar eller kända medarbetare till en person i politiskt utsatt ställning.        En PEP anses genom sin position och sitt inflytande inneha en ställning som kan utsättas för till exempel korruption och mutbrott. De yrken och positioner det gäller är bland annat riksdagsledamöter, domare i högsta instans, ambassadörer och höga officerare.',
      },
      Alerts: {
        CallButton: 'Ring',
        ChatButton: 'Chatta',
        OkButton: 'OK, förstår',
      },
      PaysForeignTaxAlert: {
        Title: 'Verifikation',
        Text: 'För att komma vidare i anslutning till tjänsten, måste vi göra en manuell avstämning. Vänligen kontakta oss.',
      },
      PaysUSTaxAlert: {
        Title: 'Verifikation',
        Text: 'För att komma vidare i anslutning till tjänsten, måste vi göra en manuell avstämning. Vänligen kontakta oss.',
      },
      IsPEPAlert: {
        Title: 'Verifikation',
        Text: 'För att komma vidare i anslutning till tjänsten, måste vi göra en manuell avstämning. Vänligen kontakta oss.',
      },
      Form: {
        CitizenshipField: {
          Label: 'Ditt medborgarskap',
          None: 'Inget',
          Swedish: 'Sverige',
        },
        Questions: 'Frågor',
        PaysForeignTaxField: {
          Legend: 'Betalar du skatt i andra länder?',
          LabelYes: 'Ja',
          LabelNo: 'Nej',
        },
        PaysUSTaxField: {
          Legend: 'Betalar du skatt i USA?',
          LabelYes: 'Ja',
          LabelNo: 'Nej',
        },
        IsPEPField: {
          Legend: 'Du du eller någon nära anhörig, en politiskt utsatt person (PEP)?',
          LabelYes: 'Ja',
          LabelNo: 'Nej',
        },
      },
    },
    ConditionsScreen: {
      Title: 'Villkor',
      Next: 'Klar',
      Form: {
        PersonalInformation: 'Personlig information',
        AgreesToDataProcessingField: {
          Legend: 'För att kunna ge dig råd och hålla dig informerad om händelser rörande dina tillgånga behöver vi använda och bearbeta dina personuppgifter',
          Label: 'Brightsave har min tillåtelse att använda och bearbeta min personliga information för att optimera mina tillgångar.',
        },
        AgreesToContactField: {
          Label: 'Brightsave får kontakta mig via email, SMS eller telefon för ärenden som rör mina tillgångar.',
        },
        Agreement: 'Avtal',
        AgreesToCompanyTermsField: {
          Legend: 'Avtalet mellan Brightsave och {companyName} reglerar det mesta, men vi vill vara säkra på att du är insatt i villkoren för tjänsten. Läs mer på {here}.',
          LegendHere: 'https://brightsave.se/juridiska-dokument/',
          Label: 'Jag förstår villkoren',
        },
        AgreesToIndividualTermsField: {
          Legend: 'Vi vill gärna att att du läser vilka villkor som finns i avtalet mellan dig och Brightsave. Läs mer på {here}.',
          LegendHere: 'https://brightsave.se/juridiska-dokument/',
          Label: 'Jag förstår villkoren',
        },
      },
    },
  },

  IntroScreen: {
    BackButton: 'Tillbaka',
    NextButton: 'Nästa',
    FirstSlide: {
      Title: 'Optimera',
      Text: 'Välj en av dina tillgängliga försäkringar att optimera. Se simulerad utveckling i förväntad, maximal och minimal avkastning. Jämför med en schablonportfölj och ta del av din nya optimerade fondlista.',
    },
    SecondSlide: {
      Title: 'Skicka beställning',
      Text: 'Vår optimering sker där du redan har dina försäkringar, vi väljer fonder och viktar om din portfölj så du får bästa möjliga avkastning till ägsta kostnad i förhållande till vald pensionsålder och risknivå.',
    },
    ThirdSlide: {
      Title: 'Få avisering',
      Text: 'Vår robot övervakar din optimering löpande och om något behöver åtgärdas så skickar vi dig en avisering för lämplig åtgärd.',
      StartButton: 'Kom igång',
    },
  },

  SubscriptionScreen: {
    Title: 'Prenumerationer',
    BasicPlan: {
      Title: 'Basic',
      Blurb: 'Digital rådgivning',
      Benefits: '4',
      Benefit1: 'Tillgång till vår rådgivningsrobot',
      Benefit2: '1 försäkring',
      Benefit3: 'Obegränsat antal optimeringar',
      Benefit4: 'Automatisk rebalansering',
    },
    PremiumPlan: {
      Title: 'Premium',
      Blurb: 'Digital + personlig rådgivning',
      Benefits: '4',
      Benefit1: 'Allt i Basic',
      Benefit2: 'Personlig certifierad rådgivare',
      Benefit3: 'Obegränsat antal försäkringar',
      Benefit4: 'Genomgång övrigt försäkringsskydd',
    },
    Subscribe: 'Prenumerera nu för {value}/månad',
    Form: {
      CardNumberField: {
        Label: 'Kortnummer',
      },
      ExpirationDateField: {
        Label: 'Utgångsdatum',
      },
      CVCField: {
        Label: 'CVC / CVV',
      },
      PostalCodeField: {
        Label: 'Postnummer',
      },
      Subscribe: 'Prenumerera nu för {value}/månad',
    },
    TrialAlert: {
      Title: 'Din pröva-på period är nu över',
      Message: 'Vänligen välj en av våra prenumerationer för att fortsätta',
      OkButton: 'OK',
    },
    SuccessAlert: {
      Title: 'Prenumeration aktiverad',
      Message: 'Tack!',
      OkButton: 'OK',
    },
    FailureAlert: {
      Title: 'Fel vid aktivering',
      GenericMessage: 'Ett fel uppstod vid aktivering av din prenmumeration, vänligen <a href="#" id="intercom-launcher">kontakta oss på support</a> så hjälper vi dig.',
      StripeCardMessage: 'Det uppstod ett problem med dina kortuppgifter, vänligen <a href="#" id="intercom-launcher">kontakta oss på support</a> så hjälper vi dig.',
      OkButton: 'OK',
    },
  },

  AccountScreen: {
    Title: 'Mitt konto',
    SaveButton: 'Spara',
    Form: {
      FirstNameField: {
        Label: 'Förnamn',
        Placeholder: 'Ditt förnamn',
        Errors: {
          Empty: 'Du måste fylla i ditt förnamn',
        },
      },
      LastNameField: {
        Label: 'Efternamn',
        Placeholder: 'Ditt efternamn',
        Errors: {
          Empty: 'Du måste fylla i ditt efternamn',
        },
      },
      EmailField: {
        Label: 'Email',
        Placeholder: 'namn@abc.se',
        Errors: {
          Empty: 'Du måste fylla i din emailadress',
          Invalid: 'Din emailadress verkar felaktig',
        },
      },
      PhoneNumberField: {
        Label: 'Mobilnummer',
        Placeholder: '070xxxxxx',
        Errors: {
          Invalid: 'Ditt mobilnummer verkar felaktigt',
        },
      },
    },
    Alert: {
      OkButton: 'OK',
    },
    UpdateSuccessAlert: {
      Title: 'Konto uppdaterat',
      Text: 'Dina kontouppgifter har uppdaterats',
    },
    ConfirmPhoneNumberSuccessAlert: {
      Title: 'Mobilnummer bekräftat',
      Text: 'Ditt mobilnummer har bekräftats',
    },
    ConfirmationSMS: {
      Message: 'Din bekräftelsekod från Brightsave: {code}',
    },
    ConfirmPhoneNumber: {
      Blurb: 'Vi har skickat ett SMS med bekräftelsekod till "{phone}". Vänligen bekräfta ditt mobilnummer genom att ange bekräftelsekoden nedan:',
      FieldPlaceholder: 'ABCD',
      ResendButton: 'Skicka nytt SMS med bekräftelsekod',
    },
    Plans: {
      Basic: 'Basic',
      Premium: 'Premium',
    },
    Subscription: {
      WillBeCanceled: 'Din {plan} prenumeration kommer att upphöra per {date} och kommer inte att förlängas',
      WillBeRenewed: 'Din {plan} prenumeration kommer automatiskt förnyas per {date}',
      UnsubscribeButton: 'Avbryt min prenumeration',
      UnsubscribeConfirm: {
        Title: 'Avbryt prenumeration?',
        Message: 'Är du säker på att du vill avsluta din prenumeration?',
        CancelButton: 'Nej',
        ConfirmButton: 'Ja',
      },
    },
    Trial: 'Din pröva-på period avslutas om {trialRemaining} dagar',
  },

  PreferencesScreen: {
    Title: 'Inställningar',
    SaveButton: 'Spara',
    Channels: 'Kanaler',
    Frequency: 'Frekvens',
    Form: {
      EmailField: {
        Label: 'Email',
      },
      SmsField: {
        Label: 'SMS',
      },
      NotificationsField: {
        Label: 'App-aviseringar',
      },
      FrequencyField: {
        Label: {
          Anytime: 'Så fort något händer',
          Monthly: 'Månadsvis',
          Quarterly: 'Kvartalsvis',
          Yearly: 'Årsvis',
        },
      },
    },
  },

  DocumentsScreen: {
    Title: 'Mina dokument',
    None: 'Det finns inga dokumenten att visa',
    SubjectsTitle: '{Subjects}',
    Subject: {
      Primary: '{Subject} {number}',
    },
    NewRecommendation: {
      Primary: 'Ny Rekommendation',
      Secondary: 'Ny Rekommendation',
    },
  },

  NotificationsScreen: {
    Title: 'Aviseringar',
    NoNotifications: 'Du har inga aviseringar',
    Today: 'Idag',
    Order: 'Beställning {number}',
    OrderSuccess: 'Din beställning skapades!',
    SeeOrderButton: 'Se beställning',
  },

  OptimizeScreen: {
    Next: 'Nästa',
    NetAmount: 'Nettoavkastning',
    ReturnsCard: {
      Title: 'Avkastningsprognos',
      Maximum: 'Max',
      Expected: 'Förväntad',
      Minimum: 'Min',
    },
    PortfolioCard: {
      Title: 'Portfölj',
      OptimizedPortfolio: 'Optimerad portfölj',
      CurrentPortfolio: 'Schablonportfölj',
      FundFees: 'Fondavgifter',
      SubscriptionFees: 'Prenumerationsavgifter',
    },
    FundlistCard: {
      Title: 'Fondlista',
      Table: {
        Header: {
          Name: 'Namn',
          Fee: 'Avgift',
          Share: 'Andel',
        },
      },
    },
    HelpPopups: {
      OkButton: 'Ok, förstår',
    },
    WhenToRetirePopup: {
      Title: 'Pensionsålder',
      Text: 'Ange din önskade pensionsålder med hjälp av dragrelaget. Min och max ålder är reglerat i avtalet med din arbetsgivare.',
    },
    RiskLevelPopup: {
      Title: 'Risknivå',
      Text: 'Risknivåreglaget anger vilken risk du är villig att ta. Det finns många sätt att förklara risk, men vi tror att det enklast visas i riktiga pengar och sannolikhet vilket vårt diagram visar.',
    },
    FundSelectionPopup: {
      Title: 'Fondalternativ',
      Text: '<strong>Hållbara:</strong> Vi väljer hållbara fonder i möjligaste mån, men saknas alternativ för att skapa en bra portfölj, väljer vi en som inte klassas som hållbar.<br>' +
        '<strong>Index:</strong> Vi väljer indexfonder i möjligaste mån, men saknas alternativ för att skapa en bra portfölj, väljer vi en som inte klassas som index.<br>' +
        '<strong>Alla:</strong> Vi väljer bland alla fonder som finns tillgängliga oavsett klassificering.',
    },
    OptimizePanel: {
      WhenToRetire: 'Önskad pensionsålder ({age})',
      RiskLevel: 'Önskad risknivå ({risk})',
      FundSelection: 'Välj fondalternativ',
      OnlySustainable: 'Enbart hållbara fondval',
      OnlyIndexFunds: 'Enbart indexfonder',
      GenericFunds: 'Alla fonder',
    },
  },

  OrderScreen: {
    Title: '{Subject}',
    Heading: 'Portfölj',
    Text: 'Det här är din optimerade portfölj för din försäkring. Den speglar de val du gjort och nu är det dags att antingen manuellt lägga in förändringarna eller skicka din beställning till ditt försäkringsbolag så de kan genomföra den.',
    Table: {
      Header: {
        Name: 'Namn',
        Fee: 'Avgift',
        Share: 'Andel',
      },
    },
    PreparingScreen: {
      Text: 'Vänligen vänta.{br}Förbereder din rådgivningsdokumentation',
    },
    FailureScreen: {
      Text: 'Ooops!{br}Något tokigt hände.',
      GoToDashboardButton: 'Gå till översikten',
    },
    StatusScreen: {
      PleaseWait: 'Vänligen vänta.',
      Success: 'Tack!{br}Din rådgivningsdokumentation skapades utan problem.',
      Canceled: 'Din rådgivningsdokumentation avbröts.',
      GoToDashboardButton: 'Gå till översikten',
    },
    SigningPanel: {
      Text: 'Jag ser att rådgivningsdokumentation enligt ovan är korrekt och genom att signera med BankID, godkänner jag tagit del av den. Tyvärr stöder inte Skandia digitalt signerade dokument ännu. Läs mer på {read_more}',
      ReadMoreLink: 'http://support.brightsave.se/bank-och-forsakringsbolag/digitala-signaturer-och-fondorder',
      SignButton: 'Signera med BankID',
    },
  },

  OrderInstructionsScreen: {
    Title: 'Fondorderinstruktioner',
    Heading: 'Portfölj',
    Text: 'Det här är din optimerade portfölj för din försäkring. Den speglar de val du gjort.',
    Skandia: {
      Heading: 'Tyvärr stöder inte Skandia digitalt signerade dokument ännu så för att genomföra din fondorder, gör följande:',
      Text1: '1. Gå till https://secure.skandia.se/home/ui/pages/pension/overview.aspx och logga in som vanligt.',
      Text2: '2. Välj den försäkring du precis optimerat i Brightsave och klicka sen på fliken Byta fonder på sidan du får upp.',
      Text3: '3. Nu kan du ange dels fördelning av ditt befintiga innehav och även dina framtida insättningar. Ange vilka fonder och den fördelning i procent du fått i appen.',
    },
    Table: {
      Header: {
        Name: 'Namn',
        Fee: 'Avgift',
        Share: 'Andel',
      },
    },
  },

  OrderRecommendationsScreen: {
    Title: 'Rekommendation',
    Heading: 'Din uppdaterade portfölj',
    Text: 'Baserat på de förändringar som hänt så rekommenderar vi att du skapar en uppdaterad portfölj för att bibehålla din önskade risknivå ({riskLevel}) och för din valda spartid ({saveTime} år).',
    Skandia: {
      Heading: 'Tyvärr stöder inte Skandia digitalt signerade dokument ännu så för att genomföra din rekommendation, så gör följande efter att du skapat din nya order:',
      Text1: '1. Gå till https://secure.skandia.se/home/ui/pages/pension/overview.aspx och logga in som vanligt.',
      Text2: '2. Välj den försäkring du precis optimerat i Brightsave och klicka sen på fliken Byta fonder på sidan du får upp.',
      Text3: '3. Nu kan du ange dels fördelning av ditt befintiga innehav och även dina framtida insättningar. Ange vilka fonder och den fördelning i procent du fått i appen.',
    },
    OptimizeButton: 'Starta optimering',
    Table: {
      Header: {
        Name: 'Namn',
        Fee: 'Avgift',
        Share: 'Andel',
      },
      New: 'Ny',
    },
  },
}

export default locale
