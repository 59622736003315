import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CheckIcon from '@material-ui/icons/CheckCircle'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import FusionReactor from '../../../lib/FusionReactor'
import styles from '../styles'


class Component extends React.Component {
  render() {
    const {
      classes,
      t,
      inFlight,
      signing_status,
    } = this.props

    let StatusIndicator, statusMessageId, GoToDashboardButton

    if ( inFlight ) {
      StatusIndicator = <CircularProgress className={ classes.statusIndicator } size={ 50 }/>
      statusMessageId = 'PleaseWait'
    } else {
      StatusIndicator = <CheckIcon className={ classes.successIcon }/>
      if ( 'COMPLETE' === signing_status ) {
        statusMessageId = 'Success'
      } else {
        statusMessageId = 'Canceled'
      }

      GoToDashboardButton = <Button
        variant="raised"
        color="primary"
        component={ Link }
        to="/">
        { t( 'OrderScreen.StatusScreen.GoToDashboardButton' ) }
      </Button>
    }

    return (
      <div className={ classes.fullScreen }>
        { StatusIndicator }

        <Typography className={ classes.fullScreenText }>
          <FormattedMessage
            id={ `OrderScreen.StatusScreen.${statusMessageId}` }
            values={ { br: <br/> } }
          />
        </Typography>

        { GoToDashboardButton }
      </div>
    )
  }
}

export default FusionReactor( withStyles( styles )( Component ) )
